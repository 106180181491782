export const camelToSpace = (camel: string) => {
  return camel.replace(/([A-Z])/g, " $1").toLowerCase();
};

export const randomColor = () => {
  return `#${Math.random().toString(16).slice(2, 8)}`;
};

export const camelToSentence = (camel: string) => {
  const c = camelToSpace(camel);
  return `${c.charAt(0).toUpperCase()}${c.slice(1)}`;
};
