import React from "react";
import { Grid, Header } from "semantic-ui-react"
import { ToastOptions } from "react-toastify";

interface IOwnProps {
  makeToast: (text: string, options?: ToastOptions) => void;
}

export const LandingPage = ({ makeToast }: IOwnProps) => {
  return <Grid>
    <Grid.Row>
      <Grid.Column>
        <Header as="h2">{`Welcome to Grapefruit`}</Header>
      </Grid.Column>
    </Grid.Row>
  </Grid>
}