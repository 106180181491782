import React from "react";
import { ToastOptions } from "react-toastify";
import { Button, Icon } from "semantic-ui-react";
import styled, { css } from "styled-components";
import { IPostable } from "tonittypes";
import { imageGroupConfig, ImageSource, modalType } from "../../constants/constants";
import PostItem from "./posts/PostItem";

interface IProps {
  reviewablePosts: Array<object>;
  makeToast: (text: string, options?: ToastOptions) => void;
  toggleModal: (type: modalType, post: IPostable<any>) => void;
}

interface StatusHeaderProps {
  danger?: boolean;
  success?: boolean;
}

// @ts-ignore
const StatusHeader = styled.div`
  position: absolute;
  color: white;
  width: 100%;
  top: 0;
  left: 0;
  padding: 2px;
  padding-left: 4px;
  ${(props: StatusHeaderProps) => props.danger && css`
    background-color: red;
  `}
  ${(props: StatusHeaderProps) => props.success && css`
    background-color: green;
  `}
`;

const Status = ({ post }: { post: IPostable<any> }) => {
  if (post.isDeleted) {
    return (
      <StatusHeader danger>
        <Icon name="trash" />
        <span>Deleted</span>
      </StatusHeader>
    );
  }

  if (!post.inReview || !post.inReview.status) {
    return (
      <StatusHeader success>
        <Icon name="check" />
        <span>Restored</span>
      </StatusHeader>
    );
  }

  return null;
};

const SegmentWrapper = styled.div`
  position: relative;
  padding-top: 10px;
`;

const ReviewableTabContent = (props: IProps) => (
  <>
    {props.reviewablePosts.map((reviewable: any) => {
      const inReview = reviewable.inReview && reviewable.inReview.status;

      return (
        <SegmentWrapper key={reviewable.id}>
          {/* <StyledSegment compact style={{ opacity: inReview ? 1 : 0.4 }}> */}
          {!inReview ? <Status post={reviewable} /> : null}
          <PostItem
            maxWidth="512px"
            key={reviewable.id}
            post={reviewable}
            imageConfig={imageGroupConfig.reviewables}
            imageSource={ImageSource.reviewables}
            makeToast={props.makeToast}
            extraRows={[
              inReview
                ? <>
                  <Button positive compact content={"Restore"} onClick={() => { props.toggleModal(modalType.APPROVE, reviewable) }} />
                  <Button negative compact content={"Delete"} onClick={() => { props.toggleModal(modalType.DECLINE, reviewable) }} />
                </>
                : null,
            ]}
          />
          {/* </StyledSegment> */}
        </SegmentWrapper>
      );
    }
    )}
  </>
);

export default ReviewableTabContent;
