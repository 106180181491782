import React, { Component } from 'react';
import "semantic-ui-css/semantic.min.css";
import { Modal } from 'semantic-ui-react';
import { IUser } from '../../constants';
import { UsersLanding } from '../UsersLanding';
import { ToastOptions } from 'react-toastify';

interface ISearchModalProps {
	setProfileToView: (user: IUser) => void;
	toggleIsModalOpen: () => void;
	isOpen: boolean;
	filterProfileId: string;
	makeToast: (text: string, options?: ToastOptions) => void;
}


export class SearchModal extends Component <ISearchModalProps, any> {

	public render () {
		const {setProfileToView, toggleIsModalOpen, isOpen, filterProfileId, makeToast} = this.props;

		return (
			<Modal 
				open={isOpen}
				closeIcon
				onClose={toggleIsModalOpen}
			>
				<Modal.Header style={{textAlign: "center", color: "red"}}>{"Choose the profile you want to link to"}</Modal.Header>
				<UsersLanding 
					makeToast={makeToast}
					filterProfileId={filterProfileId}
					setProfileToView={(user) => {
						setProfileToView(user)
						toggleIsModalOpen();
					}}
				/>
			</Modal>
		);
	}
}
