import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import TimeAgo from "react-timeago";
import "semantic-ui-css/semantic.min.css";
import { Button, Card, Grid, Header, Icon, Image, List, Placeholder, Segment } from "semantic-ui-react";
import { AccountTypes } from "tonittypes";
import staticProfilePic from "../assets/generic-female-profile-picture.jpg"; // Tell Webpack this JS file uses this image
import { IUser } from '../constants';
import { ClientRoutes, getRouteToDetail } from '../constants/routes';

interface IProps {
  user: IUser;
  setProfileToView?: (user: any) => void;
  renderExtra?: () => JSX.Element;
};

const userHeaderStyle = {
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
  flexDirection: "row",
}

export class UserCard extends Component<IProps> {

  public onClickTarget: string = "profile";

  public constructor(props: any) {
    super(props);
  }

  public handleOptions(user: any) {
    console.log("clicked on user :", user.profile._id);
  }

  public render() {
    const { user, setProfileToView } = this.props;
    const orderedBadges = AccountTypes.populate(user?.profile?.accountTypes)
      .sort((a, b) => b.priority - a.priority);

    if (!user) {
      return null;
    }

    if (!user.profile || !user.profile?._id || user.isDeleted === true) {
      return (
        <Card key={user._id} style={{ margin: 10 }}>
          <Image
            fluid
            style={{ width: 290, height: 290 }}
            src={staticProfilePic}
          />
          <Card.Content>
            <Segment style={userHeaderStyle}>
              {
                setProfileToView
                  ? <Card.Header
                    as='a'
                    style={{ color: "darkgrey" }}
                    onClick={() => setProfileToView(user)}
                  >
                    <h4>{"unknown"}</h4>
                  </Card.Header>
                  : <Link to={getRouteToDetail(ClientRoutes.Users, user._id)}>
                    <Card.Header style={{ color: "darkgrey" }}>
                      <h4>{"unknown"}</h4>
                    </Card.Header>
                  </Link>
              }

            </Segment>
            <Card.Description>{"Profile doesn't exist or is in a different deleted state"}</Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button>{user.email}</Button>
          </Card.Content>
        </Card>
      );
    }

    return (
      <>
        <Card key={user.profile._id} style={{ margin: 10 }}>
          <Image
            fluid
            style={{ width: 290, height: 290 }}
            src={user.profile.avatarUrl || staticProfilePic}
            onError={(e: any) => e.target.src = staticProfilePic}
          />
          <Card.Content>
            <Segment style={userHeaderStyle}>
              {
                setProfileToView
                  ? <Card.Header as='a' onClick={() => setProfileToView(user)}><h4>{user.profile.username}</h4></Card.Header>
                  : <Link to={getRouteToDetail(ClientRoutes.Users, user._id)}>
                    <Card.Header><h4>{user.profile.username}</h4></Card.Header>
                  </Link>
              }
            </Segment>
            {orderedBadges.length ?
              <Segment>
                {
                  orderedBadges.map(b => {
                    return <Grid.Row>
                      <Grid.Column>
                        <Icon
                          name="check circle"
                          size="small"
                          style={{
                            width: 15,
                            height: 15,
                            marginLeft: 5,
                            color: b.badge.color,
                          }} />
                      </Grid.Column>
                      <Grid.Column>
                        <Header as="h5">{b.title}</Header>
                      </Grid.Column>
                    </Grid.Row>
                  })
                }
              </Segment>
              : null}

            <Card.Description>{user.email}</Card.Description>
            <Card.Meta>
              <span className='date'>{new Date(user.profile.createdAt).toISOString().split("T")[0]}</span>
              <TimeAgo date={user.profile.createdAt} />
            </Card.Meta>
          </Card.Content>
          <Card.Content extra>
            <Button>
              <Icon name='id badge' />
              {`${user.profile._id || "No id"}`}
            </Button>
          </Card.Content>
          <Card.Content extra>
            <Button>
              <Icon name='pin' />
              {`${user.profile?.location?.humanized || "Not set"}`}
            </Button>
          </Card.Content>
          <Card.Content extra>
            <Card.Meta>
              {user.firstPost
                ? <>
                  <span>
                    {`First post`}
                  </span>
                  <span className='date'>{new Date(user.firstPost).toISOString().split("T")[0]}</span>
                  <TimeAgo date={user.firstPost} />
                </>
                : <span>
                  {"No posts"}
                </span>}
            </Card.Meta>
          </Card.Content>
          {this.props.renderExtra?.()}
        </Card>
      </>
    );
  }

  /**
   * @deprecated
   * @param user User
   */
  private buildInfo(user: any) {
    const options = [
      `idp: ${user.idp}`,
      `admin: ${Boolean(user.profile.isAdmin)}`,
      `deleted: ${Boolean(user.profile.isDeleted)}`,
      `metric: ${Boolean(user.profile.settings && user.profile.settings.isMetric)}`,
    ].filter(e => e);
    return <Grid columns={2} padded="vertically">
      {options.map(i => <List.Item as="p">{i}</List.Item>)}
    </Grid>
  }
}

export const UserCardPlaceholder = () =>
  <Card >
    <Placeholder>
      <Placeholder.Image square />
    </Placeholder>
    <Card.Content>
      <Segment>
        <Placeholder>
          <Placeholder.Line length="medium" />
        </Placeholder>
      </Segment>
      <Card.Description>
        <Placeholder>
          <Placeholder.Line length="long" />
        </Placeholder>
      </Card.Description>
      <Card.Meta>
        <Placeholder>
          <Placeholder.Line length="short" />
        </Placeholder>
      </Card.Meta>
      <Card.Description>
        <Placeholder>
          <Placeholder.Line length="very long" />
        </Placeholder>
      </Card.Description>
    </Card.Content>
    <Card.Content extra>
      <Icon name='id badge' />
    </Card.Content>
    <Card.Content extra>
      <Icon name='pin' />
    </Card.Content>
    <Card.Content extra>
      <Grid columns={2} padded="vertically">
        <Icon name='user' />
        <Icon name='user' />
      </Grid>
    </Card.Content>
    <Card.Content extra>
      <Placeholder>
        <Placeholder.Line length="full" />
        <Placeholder.Line length="full" />
      </Placeholder>
    </Card.Content>
  </Card>;
