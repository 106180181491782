import React, { Component } from "react";
import 'semantic-ui-css/semantic.min.css';
import firebase from "firebase";
import { Header, Dropdown, Divider } from "semantic-ui-react";
import { DetailsSegment } from "../profilePage/DetailsSegment";


interface IProps { }

interface IState {
  docs: firebase.firestore.DocumentData[];
  sortBy: string;
}

const sortByOptions = [
  {
    id: "createdAt",
    text: "newest",
    value: "newest",
  },
  {
    id: "humanClickCount",
    text: "most clicked",
    value: "most clicked",
  },
];

export class ShareList extends Component<IProps, IState> {
  private linkQuery: firebase.firestore.Query | undefined;
  private unsubscribe: (() => void) | undefined;

  state = {
    docs: [] as firebase.firestore.DocumentData[],
    sortBy: "most clicked",
  }

  public componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  public constructor(props: any) {
    super(props);
    this.setListener("humanClickCount");
  }

  public setListener(sortBy: string) {
    if (this.unsubscribe) this.unsubscribe();

    this.linkQuery = firebase.firestore().collection("shortLinks").orderBy(sortBy, "desc").limit(10);

    this.unsubscribe = this.linkQuery.onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
      this.setState({
        docs: snapshot.docs.map((d: firebase.firestore.QueryDocumentSnapshot) => d.data())
      });
    });
  }

  public render() {
    return <div>
      <Header>
        <Header>Shared Links</Header>
        <Divider horizontal />
        <p>Work in Progress</p>
      </Header>
      <Dropdown
        compact
        selection
        options={sortByOptions}
        defaultValue={"most clicked"}
        onChange={async (event) => {
          this.setSortBy(event.currentTarget.id);
        }}
      />

      {this.state.docs.map(link => {
        if (!link) {
          return null;
        }

        return <DetailsSegment
          key={link.createdAt}
          title={"Share Link"}
          accentColor={`#${Math.floor(link.createdAt % 1000).toPrecision(3)}`}
          data={[
            { label: "sharer", value: link.creator },
            { label: "human clicks", value: link.humanClickCount || 0 },
            { label: "robot clicks", value: link.robotClickCount || 0 },
            { label: "short url", value: link.shortUrl, link: true },
            { label: "long url", value: link.longUrl, link: true },
            { label: "created at", value: new Date(link.createdAt).toDateString() },
          ]}
        />
      })}
    </div>
  }

  private setSortBy = (mode: any) => {
    this.setState({ sortBy: mode });
    this.setListener(mode);
  }
}
