import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { ClientRoutes } from "../constants/routes";
import { EventsList } from "./events/eventsList";
import { EventSearch } from "./events/EventSearch";
import { ApiFeed } from "./feeds/Feed";
import { ReviewablesFeed } from "./feeds/ReviewablesFeed";
import { Metrics } from "./metrics/MetricsPage";
import { NoMatch } from "./NoMatch";
import { BlocksPage } from "./pages/Blocks";
import { ClubPage } from "./pages/club/ClubPage";
import { ConciergeChat } from "./pages/ConciergeChat";
import { LandingPage } from "./pages/Landing";
import { Concierge } from "./pages/newUsers/NewUsersLanding";
import { Post } from "./pages/Post";
import { Welcome } from "./pages/Welcome";
import { Landing as StylePilotLanding } from "./pages/style-pilot/Landing";
import { PostsSearch } from "./PostsSearch";
import { UserPage } from "./profilePage/UserPage";
import { ReportReasonsList } from "./reports/Reports";
import { SearchWrapper } from "./SearchLanding";
import { RouteWrap } from "./shared";
import { ShareList } from "./shares/Shares";
import { makeToast } from "./Toaster";
import { UserReportsHome } from "./userReports/UserReportsHome";
import { SuspendedAccountsPage } from "./pages/suspendedAccounts/SuspendedAccountsPage";

interface IRouter {
  fullscreenFeed: boolean;
}

// ordering of these routes is important, router will find first match and return
export const Router: FC<IRouter> = ({ fullscreenFeed }) => (
  <Switch>
    <Route path="/" exact>
      <RouteWrap>
        <Welcome />
      </RouteWrap>
    </Route>
    <Route path={ClientRoutes.Moderation}>
      <RouteWrap>
        <ReviewablesFeed makeToast={makeToast} />
      </RouteWrap>
    </Route>
    <Route path={ClientRoutes.Blocks}>
      <RouteWrap>
        <BlocksPage makeToast={makeToast} />
      </RouteWrap>
    </Route>
    <Route path={ClientRoutes.SuspendedAccounts}>
      <RouteWrap>
        <SuspendedAccountsPage makeToast={makeToast} />
      </RouteWrap>
    </Route>
    <Route path={ClientRoutes.Shares}>
      <RouteWrap>
        <ShareList />
      </RouteWrap>
    </Route>
    <Route path={ClientRoutes.Events}>
      <RouteWrap>
        <EventsList />
      </RouteWrap>
    </Route>
    <Route path={ClientRoutes.EventSearch}>
      <RouteWrap>
        <EventSearch />
      </RouteWrap>
    </Route>
    <Route path={`${ClientRoutes.Users}/:id`}>
      <RouteWrap>
        <UserPage makeToast={makeToast} />
      </RouteWrap>
    </Route>
    <Route path={ClientRoutes.Search}>
      <RouteWrap>
        <SearchWrapper makeToast={makeToast} />
      </RouteWrap>
    </Route>
    <Route path={`${ClientRoutes.Clubs}/:id`}>
      <RouteWrap>
        <ClubPage makeToast={makeToast} />
      </RouteWrap>
    </Route>
    <Route path={ClientRoutes.Reports}>
      <RouteWrap>
        <ReportReasonsList makeToast={makeToast} />
      </RouteWrap>
    </Route>
    <Route path={ClientRoutes.UserReports}>
      <RouteWrap>
        <UserReportsHome makeToast={makeToast} />
      </RouteWrap>
    </Route>
    <Route path={`${ClientRoutes.Posts}/:id`}>
      <RouteWrap>
        <Post />
      </RouteWrap>
    </Route>
    <Route path={`${ClientRoutes.Feed}/:id`}>
      <RouteWrap>
        <ApiFeed />
      </RouteWrap>
    </Route>
    <Route path={ClientRoutes.Feed} exact>
      <RouteWrap>
        <ApiFeed fullscreen={fullscreenFeed} makeToast={makeToast} />
      </RouteWrap>
    </Route>
    <Route path={ClientRoutes.Home} exact>
      <RouteWrap>
        <LandingPage makeToast={makeToast} />
      </RouteWrap>
    </Route>
    <Route path={ClientRoutes.PostSearch}>
      <RouteWrap>
        <PostsSearch />
      </RouteWrap>
    </Route>
    <Route path={ClientRoutes.Metrics}>
      <RouteWrap>
        <Metrics />
      </RouteWrap>
    </Route>
    <Route path={ClientRoutes.Concierge}>
      <RouteWrap>
        <Concierge />
      </RouteWrap>
    </Route>
    <Route path={ClientRoutes.ConciergeChat}>
      <RouteWrap>
        <ConciergeChat />
      </RouteWrap>
    </Route>
    <Route path={ClientRoutes.StylePilot}>
      <RouteWrap>
        <StylePilotLanding />
      </RouteWrap>
    </Route>
    <Route path={"/"}>
      <RouteWrap>
        <NoMatch />
      </RouteWrap>
    </Route>
  </Switch>
);
