import React, { useContext } from "react";
import { AdminContext } from "../..";
import { Label, Image } from "semantic-ui-react";

export const Welcome = () => {
  const AdminProfile = useContext(AdminContext);
  return <>
    <Image circular src={AdminProfile.avatarUrl}>
    </Image>
    <Label>{`Welcome ${AdminProfile.username}`}</Label>
  </>
};
