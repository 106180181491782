import React, { Component } from "react";
import { Search as SemanticSearch, SearchProps } from "semantic-ui-react";

interface IState {
  results: string[];
}

interface IProps {
  onSearch: (value: string) => void;
  isLoading: boolean;
  disabled?: boolean;
  value?: string;
  icon?: string;
  defaultValue?: string;
}

export default class Search extends Component<IProps, IState> {

  state = {
    results: [],
  };

  public constructor(props: IProps) {
    super(props);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  render() {
    const { results } = this.state;
    const { defaultValue, value } = this.props;

    return (
      <SemanticSearch
        loading={this.props.isLoading}
        fluid={true}
        onSearchChange={this.handleSearchChange}
        results={results}
        value={value}
        defaultValue={defaultValue}
        showNoResults={false}
        minCharacters={3}
        {...this.props}
      />
    )
  }

  private debounceOnSearch: (value: string | undefined) => void = debounce(this.props.onSearch, 350, false);

  private handleSearchChange(e: React.MouseEvent, data: SearchProps) {
    this.debounceOnSearch(data.value);
    this.setState({
      results: [],
    });
  }

}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func: Function, wait: number, immediate: boolean) {
  var timeout: number | null;
  return function () {
    // @ts-ignore
    var context = this, args = arguments;
    var later = function () {
      timeout = null;
      // @ts-ignore
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout!);
    timeout = setTimeout(later, wait);
    // @ts-ignore
    if (callNow) func.apply(context, args);
  };
};
