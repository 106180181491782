import firebase from "firebase";
import React, { useEffect, useState } from "react";
import { Graph } from "react-d3-graph";
import { ActionTypes } from "tonittypes";
import { postAction } from "../../api/api";

export const Metrics = () => {
  return <Graphy />;
  // return <MapBox />;
}

// the graph configuration, just override the ones you need
const confg = {
  "automaticRearrangeAfterDropNode": true,
  "collapsible": true,
  "directed": true,
  "focusAnimationDuration": 0.75,
  "focusZoom": 1,
  "freezeAllDragEvents": false,
  "height": 400,
  "highlightDegree": 2,
  "highlightOpacity": 0.2,
  "linkHighlightBehavior": true,
  "maxZoom": 12,
  "minZoom": 0.05,
  "nodeHighlightBehavior": true,
  "panAndZoom": false,
  "staticGraph": false,
  "staticGraphWithDragAndDrop": false,
  "width": 800,
  "d3": {
    "alphaTarget": 0.05,
    "gravity": -250,
    "linkLength": 120,
    "linkStrength": 2,
    "disableLinkForce": false
  },
  "node": {
    "color": "#d3d3d3",
    "fontColor": "black",
    "fontSize": 10,
    "fontWeight": "normal",
    "highlightColor": "red",
    "highlightFontSize": 14,
    "highlightFontWeight": "bold",
    "highlightStrokeColor": "red",
    "highlightStrokeWidth": 1.5,
    "mouseCursor": "crosshair",
    "opacity": 0.9,
    "renderLabel": true,
    "size": 300,
    "strokeColor": "none",
    "strokeWidth": 1.5,
    "svg": "",
    "symbolType": "circle"
  },
  "link": {
    "color": "lightgray",
    "fontColor": "black",
    "fontSize": 8,
    "fontWeight": "normal",
    "highlightColor": "red",
    "highlightFontSize": 8,
    "highlightFontWeight": "normal",
    "labelProperty": "label" as "target",
    "mouseCursor": "pointer",
    "opacity": 1,
    "renderLabel": false,
    "semanticStrokeWidth": true,
    "strokeWidth": 3,
    "markerHeight": 6,
    "markerWidth": 6,
    "strokeDasharray": 0,
    "strokeDashoffset": 0,
    "strokeLinecap": "round"
  }
};

interface ILink {
  createdAt: number;
  deletedAt: number;
  eventCount: number;
  isDeleted: false;
  link: boolean;
  sink: {
    avatarUrl: string;
    username: string;
    _id: string;
  };
  source: {
    avatarUrl: string;
    username: string;
    _id: string;
  };
  updatedAt: number;
  weight: number;
  whichSink: "Profile"
  whichSource: "Profile"
  _id: string;
}

const Graphy = () => {
  const [links, setLinks] = useState<{ edges: ILink[], nodes: any[] }>({ edges: [], nodes: [] });

  useEffect(() => {
    // ActionTypes.NETWORK_GRAPH_REQUEST is not yet implemented
    // postAction({
    //   createdAt: Date.now(),
    //   userId: firebase.auth().currentUser,
    //   type: ActionTypes.NETWORK_GRAPH_REQUEST,
    //   data: { source: "5aaffc1f56565c43f4c81744" },
    // }).then(res => setLinks(res.data));
  }, []);

  console.log("LINKS:", links);

  if (!links.nodes) return null;

  const graphData = {
    links: links.edges.map(e => ({ target: e.sink._id, source: e.source._id })),
    nodes: links.nodes.map(n => ({
      id: n._id,
      svg: n.avatarUrl,
      size: 500,
      fontSize: 18,
    }))
    // links: links.map(l => ({ source: l.source._id?.toString(), target: l.sink._id?.toString() })),
    // nodes: Array.from(new Set([...links.map(l => l.source._id?.toString()), ...links.map(l => l.sink._id?.toString())]))
    //   .map(id => ({ id })),
  };

  return <div style={{ height: "100%", width: "100%", backgroundColor: "#eee" }}>
    <Graph
      id="circle"
      data={graphData}
      config={confg}
    />
  </div>;
};
