import { useEffect, useState } from "react";
import { Button, Confirm, Icon, Popup } from "semantic-ui-react";

export const ButtonWithConfirm = ({ onConfirm, promptMessage, ...rest }: any) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button {...rest} onClick={() => setOpen(true)} />
      <Confirm
        open={open}
        content={promptMessage}
        onCancel={() => setOpen(false)}
        onConfirm={() => {
          setOpen(false);
          onConfirm();
        }}
      />
    </>
  );
};

interface IHiddenProps {
  closeHint?: string;
  onHide?: () => void;
  renderOpen: (closeButton: any, toggle: () => void) => JSX.Element;
  renderClose: (closeButton: any) => JSX.Element;
  label: string | (() => any);
  positive?: boolean;
  controlledOpen?: boolean;
  initialOpen?: boolean;
}

export const Hidden = ({
  renderOpen,
  label,
  onHide,
  positive,
  closeHint,
  renderClose,
  controlledOpen,
  initialOpen,
}: IHiddenProps) => {
  const [open, setOpen] = useState(initialOpen === true);

  useEffect(() => {
    if (controlledOpen === true) setOpen(true);
  }, [controlledOpen]);

  const toggle = () => setOpen(!open);

  const ButtonComp = (
    <Button
      positive={positive && !open}
      circular={open}
      basic
      onClick={() => {
        toggle();
        if (open) onHide?.();
      }}
      size="tiny"
    >
      {open ? (
        <Popup content={closeHint} trigger={<Icon fitted name="close" />} />
      ) : typeof label === "string" ? (
        label
      ) : (
        label()
      )}
    </Button>
  );

  return open ? renderOpen(() => ButtonComp, toggle) : renderClose(() => ButtonComp);
};