import { AxiosError, AxiosResponse } from "axios";
import Carousel from "nuka-carousel";
import React, {
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactHlsPlayer from "react-hls-player";
import ReactMapboxGl, {
  Layer,
  Feature,
  Popup as PopupGL,
} from "react-mapbox-gl";
import ReactPlayer from "react-player";
import { Link, useHistory } from "react-router-dom";
import { ToastOptions } from "react-toastify";
import { Button, Feed, Icon, Image, Modal, Popup } from "semantic-ui-react";
import styled from "styled-components";
import { IActivity, IComment, IPost, IPostable, PostType } from "tonittypes";
import {
  fetchActivity,
  fetchComments,
  fetchCoords,
  fetchLikes,
  getVideoDownloadUrl,
  headerAdapter,
  requestSearchUsers,
} from "../../../api/api";
import { getNextUrl } from "../../../api/helpers/helpers";
import * as config from "../../../config/config.json";
import {
  IImageTypeConfig,
  imageGroupConfig,
  ImageSource,
  IPostImageData,
  ISingleImageData,
} from "../../../constants/constants";
import { IUser } from "../../../constants/index.js";
import { ClientRoutes, getRouteToDetail } from "../../../constants/routes";
import { DetailsSegment } from "../../profilePage/DetailsSegment";
import { UserBadge } from "../../shared";
import {
  colors,
  Column,
  DeContent,
  ExtraDeContent,
  PlainContent,
  RichContent,
  Row,
  SpecialContent,
  Time,
  Title,
  Username,
} from "../styles";
import { Chart } from "react-google-charts";
import { format } from "date-fns";
import { Configure } from "react-instantsearch-dom";
import { Attachment } from "./Attachment";

export const Card: FC = (props: any) => {
  const componentRef = useRef<HTMLDivElement>(null);

  const cardWidth2 = 450;
  // const marginWidth2 = Math.round((width - cardWidth2) / 2);

  return (
    <div
      ref={componentRef}
      style={{
        // marginRight: `${marginWidth}px`,
        // marginLeft: `${marginWidth}px`,
        marginRight: `${20}%`,
        marginLeft: `${20}%`,
        minWidth: `${cardWidth2}pt`,
        marginBottom: "4pt",
        paddingTop: "8pt",
        paddingBottom: "8pt",
        paddingRight: "8pt",
        paddingLeft: "8pt",
        border: "1px solid #f7fafb",
        borderRadius: "3pt",
        boxShadow: "2px 2px 3px 0px #e7eaeb",
        backgroundColor: "#fff",
        display: "flex",
      }}
    >
      {props.children(cardWidth2)}
    </div>
  );
};

const StyledPopup = styled.div`
  background-color: white;
  padding: 5px;
  white-space: pre-wrap;
`;

interface IProps {
  post: IPostable<IPost | IActivity>;
  imageSource: ImageSource;
  index?: number;
  imageConfig: IImageTypeConfig;
  makeToast: (text: string, options?: ToastOptions) => void;
  maxWidth: string;
  onImageLoad?: () => void;
  extraRows?: ReactNode[];
  key: string;
  renderContext?: () => ReactNode;
}

const initImageData = (
  post: IPostable<IPost | IActivity>,
  propConfig: IImageTypeConfig
): IPostImageData => {
  let postUrls;
  if (post?.mediaGallery?.media?.length) {
    // The media url won"t actually exist, but we can make it again
    postUrls = post.mediaGallery.media.map(
      (m) => `${config.apiUrl}/api/v1/${m.type}s/${m.key}`
    );
  } else {
    postUrls =
      post.imageUrls && post.imageUrls[0]
        ? post.imageUrls
        : [propConfig.fallback];
  }
  const postSrcLocation = propConfig.whitelist[0];
  const avatarUrl = post.profilePhotoUrl || imageGroupConfig.avatar.fallback;
  const avatarLocation = imageGroupConfig.avatar.whitelist[0];

  return {
    post: postUrls.map((url) => ({
      url,
      location: postSrcLocation,
    })),
    avatar: {
      url: avatarUrl,
      location: avatarLocation,
    },
  };
};

const mapboxToken = config.mapbox.accessToken;

const Map = ReactMapboxGl({
  accessToken: mapboxToken,
});

const msToTimeString = (time?: number) => {
  if (!time) return "00:00:00";

  var pad = (n: number, z = 2) => `00${n}`.slice(-z);
  return `${pad((time / 3.6e6) | 0)}:${pad(((time % 3.6e6) / 6e4) | 0)}:${pad(
    ((time % 6e4) / 1000) | 0
  )}`;
};

const staticMapImage = (snapshot: string) => {
  const coords = snapshot.split("/").slice(-3)[0];

  const styleId = "ckaya4h800hxw1jpfdmsagvy1";

  const uri = `https://d1grdo8x07z2e4.cloudfront.net/styles/v1/graemefunk/${styleId}/static/${coords}/auto/500x300?access_token=${mapboxToken}&attribution=false&logo=false`;

  return uri;
};

const meterSecondsToKilometerHours = (n?: number) => (n ?? 0) * 3.6;

const StatColumn = (props: { label: string; stat: string }) => {
  return (
    <Column style={{ alignItems: "center", padding: "12pt" }}>
      <PlainContent>{props.label.toUpperCase()}</PlainContent>
      <SpecialContent>{props.stat}</SpecialContent>
    </Column>
  );
};

const PostItem = (props: IProps) => {
  const { push } = useHistory();
  const { post, renderContext } = props;
  const [imageData, setImageData] = useState(
    initImageData(props.post, props.imageConfig)
  );
  const [alternateImageLocation, setAlternateImageLocation] = useState("");

  const activity = useActivity(
    post.data.type === PostType.Activity
      ? (post.data as any).activityId
      : undefined
  );


  const handleViewProfileClick = async () => {
    const user: IUser | null | undefined = await getPostOwnerAccount(
      props.post
    );

    if (user) {
      push(getRouteToDetail(ClientRoutes.Users, user._id));
    }
  };

  const getPostOwnerAccount = async (post: any) => {
    if (post && post.ownerId) {
      try {
        const response: AxiosResponse = await requestSearchUsers(false, "", [
          post.ownerId,
        ]);
        let user: IUser | null = null;

        if (response.data && response.data.users && response.data.users[0]) {
          user = response.data.users[0];
        }

        return user;
      } catch (error) {
        if (error.isAxiosError) {
          const apiError = error as AxiosError<{ error: Error }>;

          // No need to send notifications on every debounce with no users found
          if (apiError.response && apiError.response.status !== 404) {
            props.makeToast(
              apiError.response.data.error.message || "Unknown error occured",
              { type: "error" }
            );
          }
        }
      }
    }
  };

  const renderImage = (m: ISingleImageData) => {
    // Since this is a total hack...let's just keep hacking it
    // Proper thing to do would be to retool the concept of
    // m.location to include videos
    const isVideo = m.url.match(/videos/);

    return isVideo ? (
      <>
        <ReactHlsPlayer
          loop
          url={m.url}
          autoplay={false}
          controls={true}
          width="100%"
          height="auto"
        />
        <DownloadButton url={m.url} />
      </>
    ) : (
      <img
        alt={m.location}
        src={m.url}
        style={{
          height: "auto",
          width: "auto",
          maxWidth: props.maxWidth,
          maxHeight: "100%",
        }}
        onLoad={() => props.onImageLoad && props.onImageLoad()}
        onError={() => {
          getNextUrl(m, props.imageConfig, (url, location) => {
            console.log("Setting alertanate", alternateImageLocation);
            setImageData({
              ...imageData,
              post: [
                ...imageData.post.filter((p) => p.url !== m.url),
                { url, location },
              ],
            });
            setAlternateImageLocation(location);
          });
        }}
      />
    );
  };

  const getMediaContent = (post: any, maxWidth?: string) => {
    // Look for gallery first
    if (post?.mediaGallery?.media?.length) {
      return (
        <Carousel
          width={maxWidth || props.maxWidth || "100%"}
          initialSlideHeight={400}
          // withoutControls
          renderCenterLeftControls={({
            previousSlide,
            slideCount,
            currentSlide,
          }) =>
            currentSlide !== 0 ? (
              <Icon
                onClick={previousSlide}
                name="chevron left"
                circular
                style={{ backgroundColor: "#fffa" }}
              ></Icon>
            ) : null
          }
          renderCenterRightControls={({
            nextSlide,
            slideCount,
            currentSlide,
          }) =>
            currentSlide + 1 !== slideCount ? (
              <Icon
                onClick={nextSlide}
                name="chevron right"
                circular
                style={{ backgroundColor: "#fffa" }}
              ></Icon>
            ) : null
          }
          style={{
            border: `1px solid ${colors.tertiary2}`,
            padding: "8pt",
            borderRadius: "8pt",
          }}
          defaultControlsConfig={{
            pagingDotsStyle: {
              fill: colors.primary,
              stroke: colors.tertiary2,
              strokeWidth: 0,
            },
          }}
        >
          {imageData.post.map((m) => (
            <SliderItem key={m.url}>
              {renderImage(m)}
              {alternateImageLocation && (
                <Feed.Extra>{`From ${alternateImageLocation}`}</Feed.Extra>
              )}
            </SliderItem>
          ))}
        </Carousel>
      );
    }

    // Do legacy stuff
    // Only if there isn't a mediaGallery
    if (post.media) {
      switch (post.media.type) {
        case "image": {
          return imageData.post.map((m) => (
            <Feed.Extra images key={m.url}>
              {renderImage(m)}
            </Feed.Extra>
          ));
        }
        case "video": {
          let src = getVideoSrc(post);

          return (
            <>
              <ReactPlayer
                width={props.maxWidth}
                key={src}
                url={src}
                controls
                onReady={() => props.onImageLoad && props.onImageLoad()}
                config={{
                  file: {
                    hlsOptions: {
                      xhrSetup: function (xhr: any) {
                        xhr.withCredentials = false;
                        headerAdapter
                          .getAllHeaders()
                          .filter((t) => t.length === 2)
                          .forEach((tuple) => {
                            xhr.setRequestHeader(tuple[0], tuple[1]);
                          });
                      },
                    },
                  },
                }}
              />
              <DownloadButton url={src} />
            </>
          );
        }
      }
    }
    return null;
  };

  return (
    <Card key={props.key}>
      {(cardWidth: number) => (
        <div style={{ position: "relative", width: "100%"}}>
        {
          renderContext
           ? 
            <ContextWrap>
              {renderContext()}
            </ContextWrap>
           : null
        }
          <Column>
            <Image
              style={{
                borderRadius: "21pt",
                width: "42pt",
                height: "42pt",
                minHeight: "42pt",
                minWidth: "42pt",
              }}
              src={imageData.avatar.url}
              onError={() =>
                getNextUrl(
                  imageData.avatar,
                  imageGroupConfig.avatar,
                  (url, location) => {
                    setImageData({ ...imageData, avatar: { url, location } });
                  }
                )
              }
            />
          </Column>

          <Column>
            <Row>
              <Column>
                <Username onClick={handleViewProfileClick}>
                  <UserBadge accountTypes={post.accountTypes} />
                  {post.authorName}
                </Username>
              </Column>

              <Column>
                <Time date={post.createdAt || "unkown"} />
              </Column>

              <Column></Column>
            </Row>

            <Row>
              <Column>
                <ExtraDeContent>{post.location?.humanized}</ExtraDeContent>
              </Column>
            </Row>

            <Row>
              <Column></Column>
            </Row>

            {post.data.type === PostType.Post ||
            post.data.type === PostType.ClubPost ? (
              <Row>
                <Column>
                  <RichContent>{post.data.text}</RichContent>
                </Column>
              </Row>
            ) : null}

            <Row>
              {props.index !== undefined && props.index + 1}
            </Row>

            {post.feedKeys
             ? <Row>
                {post.feedKeys.map(fk => <DeContent>{fk}</DeContent>)}
             </Row>
             : null}

            {post.data.type === PostType.Activity ? (
              <>
                <Row>
                  <Column>
                    <Title>{post.data.userContent?.title}</Title>
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <RichContent>{post.data.userContent?.text}</RichContent>
                  </Column>
                </Row>
                <Row style={{ flexWrap: "wrap" }}>
                  <StatColumn
                    label="AVERAGE SPEED"
                    stat={`${meterSecondsToKilometerHours(
                      post.data.measurements?.avgSpeed
                    ).toFixed(1)} km/h`}
                  />
                  <StatColumn
                    label="MAX SPEED"
                    stat={`${meterSecondsToKilometerHours(
                      post.data.measurements?.maxSpeed
                    ).toFixed(1)} km/h`}
                  />
                  <StatColumn
                    label="distance"
                    stat={`${(
                      (post.data.measurements?.distance || 0) / 1000
                    )?.toFixed(1)} km`}
                  />
                  <StatColumn
                    label="elevation gain"
                    stat={`${post.data.measurements?.elevationGain?.toFixed(
                      0
                    )}m`}
                  />
                  <StatColumn
                    label="max elevation"
                    stat={`${post.data.measurements?.maxElevation?.toFixed(
                      0
                    )}m`}
                  />
                  <StatColumn
                    label="riding duration"
                    stat={`${msToTimeString(
                      post.data.measurements?.ridingDuration
                    )}`}
                  />
                  <StatColumn
                    label="total duration"
                    stat={`${msToTimeString(
                      post.data.measurements?.totalDuration
                    )}`}
                  />
                  {Object.entries(activity?.metadata || {}).map(
                    ([key, value]) => {
                      return <StatColumn label={key} stat={value as string} />;
                    }
                  )}
                  <StatColumn
                    label="total duration"
                    stat={`${activity?.metadata?.platformOs}`}
                  />
                </Row>
                <Modal
                  trigger={
                    <Image
                      style={{
                        borderRadius: "21pt",
                        width: "300pt",
                        height: "180pt",
                        minWidth: "300pt",
                        minHeight: "180pt",
                      }}
                      src={staticMapImage(post.data.snapshot)}
                    />
                  }
                >
                  <Modal.Content>
                    <CoordsMap activity={activity} post={post} />
                  </Modal.Content>
                </Modal>

                <Row>
                  <Column>
                    <PlainContent>
                      {`started at ${new Date(
                        post.data.startTime
                      ).toLocaleString()}`}
                    </PlainContent>
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <PlainContent>
                      {`ended at ${new Date(
                        post.data.startTime
                      ).toLocaleString()}`}
                    </PlainContent>
                  </Column>
                </Row>
              </>
            ) : null}

            <Row>
              <Column>
                {getMediaContent(post, `${cardWidth - 42 - 42}pt`)}
              </Column>
            </Row>

            {post.attachments?.length
              ? post.attachments.map((attachment, index) => (
                  <Attachment
                    attachment={attachment}
                    key={`attachment_${attachment.contentType}_${index}`}
                  />
                ))
              : null}

            <Row style={{}}>
              <Column>
                <DeContent>
                  <Popup
                    trigger={
                      <Icon
                        name="thumbs up"
                        style={{ color: colors.tertiary, marginRight: "8pt" }}
                      />
                    }
                  >
                    <Popup.Content>
                      <ThreadPreview
                        postId={post.id!}
                        maxItems={5}
                        fetchThread={fetchLikes}
                      />
                    </Popup.Content>
                  </Popup>
                  {`${post.likeCount || 0}`}
                </DeContent>
              </Column>

              <Column>
                <DeContent>
                  <Popup
                    trigger={
                      <Icon
                        name="comment"
                        style={{ color: colors.tertiary, marginRight: "8pt" }}
                      />
                    }
                  >
                    <Popup.Content>
                      <ThreadPreview
                        postId={post.id!}
                        maxItems={3}
                        fetchThread={fetchComments}
                      />
                    </Popup.Content>
                  </Popup>
                  {`${post.commentCount || 0}`}
                </DeContent>
              </Column>

              <Column style={{}}>
                <DeContent>
                  <Link
                    to={getRouteToDetail(
                      ClientRoutes.PostSearch,
                      post.id || post._id!
                    )}
                  >
                    <Popup
                      content="go to this post"
                      trigger={
                        <Icon
                          name="chevron right"
                          style={{ color: colors.tertiary }}
                        />
                      }
                    />
                  </Link>
                </DeContent>
              </Column>

              <Column style={{}}>
                <Modal
                  trigger={
                    <span>
                      <DeContent>
                        <Popup
                          content="view details"
                          trigger={
                            <Icon
                              name="code"
                              style={{
                                color: colors.tertiary,
                                cursor: "pointer",
                              }}
                            />
                          }
                        />
                      </DeContent>
                    </span>
                  }
                >
                  <Modal.Content>
                    <DetailsSegment
                      title={"Raw Data"}
                      accentColor="#919"
                      data={toDetails(post)}
                    />
                  </Modal.Content>
                </Modal>
              </Column>
            </Row>

            {props.extraRows?.length
              ? props.extraRows.map((extra, i) => (
                  <Row key={i} style={{ marginTop: "8pt" }}>
                    {extra}
                  </Row>
                ))
              : null}
          </Column>
          <Column style={{ width: "42pt" }}>
            <Row></Row>
          </Column>
        </div>
      )}
    </Card>
  );
};

const useActivity = (activityId: string | undefined) => {
  const [activity, setActivity] = useState<any>(null);

  useEffect(() => {
    if (!activityId) return;

    fetchActivity(activityId).then((res) => setActivity(res.data));
  }, [activityId]);

  return activity;
};

const useCoords = (activity: any) => {
  const [coordDocs, setCoordDoc] = useState<any>([]);

  useEffect(() => {
    fetchCoords(activity.id).then((res) => setCoordDoc(res.data.data));
  }, [activity]);

  const flatDocs = useMemo(
    () =>
      coordDocs
        .reduce((a: any, c: any) => [...a, ...c.coordinates], [])
        .sort((a: any, b: any) => a.timestamp - b.timestamp),
    [coordDocs]
  );

  return flatDocs;
};

const clusterLayer = {
  id: "clusters",
  type: "circle",
  source: "earthquakes",
  filter: ["has", "point_count"],
  paint: {
    "circle-color": [
      "step",
      ["get", "point_count"],
      "#51bbd6",
      100,
      "#f1f075",
      750,
      "#f28cb1",
    ],
    "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
  },
};

const CoordsMap = ({ activity, post }: { activity: any; post: any }) => {
  const [visiblePopup, setVisiblePopup] = useState(-1);
  const [zoom] = useState<[number]>([13]);

  const coords = useCoords(activity);

  const focusedCoord = coords && visiblePopup && coords[visiblePopup];

  // @ts-ignore
  const coordinates = useMemo(
    () => coords.map((c: any) => [c.coords.longitude, c.coords.latitude]),
    [coords]
  );

  const simplify = (doc: any) => {
    return `${format(doc.timestamp, "ppp")}\nspeed=${
      doc.coords.speed
    }\naltitude=${doc.coords.altitude}\nlongitude=${
      doc.coords.longitude
    } latitude=${doc.coords.latitude}`;
  };

  const markers = (appState: string | undefined) =>
    coords
      .filter((c: any) => c.appState === appState)
      .map((doc: any, index: number) => (
        <Feature
          key={`${appState}${index}`}
          coordinates={[doc.coords.longitude, doc.coords.latitude]}
          // onMouseEnter={() => {
          //   setVisiblePopup(index);
          // }}
          // onMouseLeave={() => {
          //   setVisiblePopup(-1);
          // }}
        />
      ));

  const startTimings = activity?.timings.map((doc: any, index: number) => {
    const startingMarker = coords.find((fd: any) => {
      return fd.timestamp >= doc.start;
    });

    if (!startingMarker) {
      return null;
    }

    return (
      <Feature
        key={`starting-${index}`}
        coordinates={[
          startingMarker.coords.longitude,
          startingMarker.coords.latitude,
        ]}
      />
    );
  }, []);

  const endTimings = activity?.timings.map((doc: any, index: number) => {
    const endingMarker =
      doc.end &&
      [...coords].reverse().find((fd: any) => {
        return fd.timestamp <= doc.end;
      });

    if (!endingMarker) {
      return null;
    }

    return (
      <Feature
        key={`ending-${index}`}
        coordinates={[
          endingMarker.coords.longitude,
          endingMarker.coords.latitude,
        ]}
      />
    );
  }, []);

  // Let's wait for coords before rendering stuff...
  if (!coords.length) return <Icon name={"map"} loading />;

  const widthHeight = { width: "800px", height: "400px" };

  const stateColors = {
    notDefined: {
      color: "#000000",
      state: undefined,
    },
    inactive: {
      color: "#de2114",
      state: "inactive",
    },
    background: {
      color: "#00ff12",
      state: "background",
    },
    active: {
      color: "#00ffff",
      state: "active",
    },
  };

  return (
    <div>
      <Row>
        <Map
          // eslint-disable-next-line react/style-prop-object
          style="mapbox://styles/graemefunk/ckaya4h800hxw1jpfdmsagvy1"
          center={coordinates[0]}
          zoom={zoom}
          containerStyle={{
            height: "85vh",
            width: "100%",
          }}
          onStyleLoad={(map) => {
            map.addLayer({
              id: "route",
              type: "line",
              source: {
                type: "geojson",
                data: {
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "LineString",
                    coordinates,
                  },
                },
              },
              layout: {
                "line-join": "round",
                "line-cap": "round",
              },
              paint: {
                "line-color": "#0000ff",
                "line-width": 4,
                "line-opacity": 0.5,
              },
            });
          }}
        >
          <Layer {...clusterLayer} />

          {Object.values(stateColors).map((config) => (
            <Layer
              type="circle"
              id={new String(config.state).toString()}
              paint={{
                "circle-opacity": 1,
                "circle-color": config.color,
              }}
            >
              {markers(config.state)}
            </Layer>
          ))}

          <Layer
            type="circle"
            id="inactive"
            paint={{
              "circle-opacity": 0.75,
              "circle-color": "#de2114",
            }}
          >
            {markers("inactive")}
          </Layer>

          <Layer
            type="circle"
            id="start-timings"
            paint={{
              "circle-color": "#00ff00",
              "circle-radius": 10,
            }}
          >
            {startTimings}
          </Layer>
          <Layer
            type="circle"
            id="end-timings"
            paint={{
              "circle-color": "#ff0000",
              "circle-radius": 10,
            }}
          >
            {endTimings}
          </Layer>
          {focusedCoord && (
            <PopupGL
              key={visiblePopup}
              coordinates={[
                focusedCoord.coords.longitude,
                focusedCoord.coords.latitude,
              ]}
              anchor="bottom"
            >
              <StyledPopup>{simplify(focusedCoord)}</StyledPopup>
            </PopupGL>
          )}
        </Map>
      </Row>
      <Row>
        {`${coords.length} location points`}
        {Object.values(stateColors).map((config) => (
          <ColorBox label={config.state} color={config.color} />
        ))}
      </Row>
      <Row>
        <Chart
          {...widthHeight}
          chartType="ScatterChart"
          loader={<div>Loading Chart</div>}
          data={[
            ["time", "appstate"],
            ...coords.map((fd: any) => [
              new Date(fd.timestamp),
              Object.values(stateColors).findIndex(
                (c) => c.state === fd.appState
              ),
            ]),
          ]}
          options={{
            hAxis: {
              title: "Time",
            },
            vAxis: {
              title: "App State",
            },
          }}
        />
      </Row>
      <Row>
        <Chart
          {...widthHeight}
          chartType="LineChart"
          loader={<div>Loading Chart</div>}
          data={[
            ["x", "m/s"],
            ...coords.map((fd: any) => [
              new Date(fd.timestamp),
              fd.coords.speed,
            ]),
          ]}
          options={{
            hAxis: {
              title: "Time",
            },
            vAxis: {
              title: "Speed",
            },
          }}
        />
      </Row>
      <Row>
        <Chart
          {...widthHeight}
          chartType="LineChart"
          loader={<div>Loading Chart</div>}
          data={[
            ["x", "m"],
            ...coords.map((fd: any) => [
              new Date(fd.timestamp),
              fd.coords.altitude,
            ]),
          ]}
          options={{
            hAxis: {
              title: "Time",
            },
            vAxis: {
              title: "Altitude",
            },
          }}
        />
      </Row>
      <Row>
        <Chart
          {...widthHeight}
          chartType="Timeline"
          loader={<div>Loading Chart</div>}
          data={[
            [
              { type: "string", id: "Reason" },
              { type: "date", id: "Start" },
              { type: "date", id: "End" },
            ],
            ...activity.timings.map((fd: any) => [
              fd.end_reason,
              fd.start,
              fd.end,
            ]),
          ]}
          options={{
            hAxis: {
              title: "Time",
            },
            vAxis: {
              title: "Timing",
            },
          }}
        />
      </Row>
      <DetailsSegment
        title={"Activity Details"}
        accentColor="#113399"
        data={[{ label: "Activity", value: activity }]}
      />
      {coords.length < 200 ? (
        <DetailsSegment
          title={"Activity Details"}
          accentColor="#113399"
          data={coords.map((value: object, label: number) => ({
            label: label?.toString(),
            value,
          }))}
        />
      ) : null}
    </div>
  );
};

const ColorBox = ({ color, label }: { color: string; label?: string }) => {
  return (
    <>
      <div
        style={{
          backgroundColor: color,
          width: "10px",
          height: "10px",
          marginLeft: "10px",
        }}
      />
      {new String(label)}
    </>
  );
};

/**
 * Transforms any object into a list of details objects
 * where keys are the flattened keys of all children
 * and the values are either scalar or arrays.
 *
 * Eg, {a: 5, b: {ba: [0, 1], bb: "hi"}}
 * -> [
 * {label: "a", value: 5 },
 * {label: "b.ba", value: [0, 1] },
 * {label: "b.bb", value: "hi" },
 * ]
 */
const toDetails = (obj: any) => {
  const flattenObject = (ob: any) => {
    const toReturn: any = {};

    for (const i in ob) {
      if (!ob.hasOwnProperty(i)) continue;

      if (Array.isArray(ob[i])) {
        toReturn[i] = ob[i];
      } else if (typeof ob[i] === "object") {
        const flatObject = flattenObject(ob[i]);
        for (const x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;
          toReturn[`${i}.${x}`] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
    }
    return toReturn;
  };
  const { likes, ...safe } = obj;
  const flat = flattenObject(safe);
  return Object.keys(flat).map((k) => ({ label: k, value: flat[k] }));
};

const ContextWrap = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

const SliderItem = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * This is another instance of, shouldn't be here but is...
 * getVideoSrc simply finds the video object on a post
 * then returns the src url for the video
 * If neither exist it breaks the player purposefully to create an empty video player state
 * @param post
 */
const getVideoSrc = (post: any) => {
  // Find the video object since it could be in two places either location on the post
  if (post.videos && post.videos[0]) {
    return `${config.videoApiUrl}${post.videos[0].key}`;
  } else if (post.media.videos && post.media.videos[0]) {
    return `${config.videoApiUrl}${post.media.videos[0].key}`;
  }
  return imageGroupConfig.posts.fallback;
};

export default PostItem;

export const ThreadPreview: FC<{
  postId: string;
  maxItems: number;
  fetchThread: typeof fetchComments;
}> = ({ postId, maxItems, fetchThread }) => {
  const [comments, setComments] = useState<IComment[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // @ts-ignore
    setIsLoading(true);
    fetchThread(postId)
      .then((res) => res?.data && setComments(res?.data?.data))
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, [fetchThread, postId]);

  if (isLoading) {
    return <Icon loading name="circle notched" />;
  }

  if (!comments.length) {
    return <EmptyMessage />;
  }

  return (
    <Column style={{ width: "100%" }}>
      {comments.length - maxItems > 0 ? (
        <PlusMoreRow n={comments.length - maxItems} />
      ) : null}
      {comments
        .sort((a: any, b: any) => a?.createdAt - b?.createdAt)
        .slice(Math.max(0, comments.length - maxItems))
        .map((comment) => (
          <CompactComment key={comment._id} {...comment} />
        ))}
    </Column>
  );
};

const PlusMoreRow: FC<{ n: number }> = ({ n }) => {
  return (
    <Row style={{ justifyContent: "center" }}>
      <Column>
        <DeContent>{`plus ${n} more`}</DeContent>
      </Column>
    </Row>
  );
};

const EmptyMessage: FC<{}> = () => {
  return (
    <Row style={{ justifyContent: "center" }}>
      <DeContent>{`nothing here`}</DeContent>
    </Row>
  );
};

export const CompactComment = ({ authorName, text, createdAt }: IComment) => {
  return (
    <>
      <Row>
        <Column>
          <Username>{authorName}</Username>
        </Column>
      </Row>
      <Row>
        <Column>
          <Time date={createdAt} />
        </Column>
      </Row>
      <Row>
        <Column>
          <RichContent>{text}</RichContent>
        </Column>
      </Row>
    </>
  );
};

const DownloadButton = ({ url }: { url: string }) => {
  const [label, setLabel] = useState("");
  return (
    <Button
      style={
        {
          // position: "relative",
          // top: 0,
          // let: 0,
        }
      }
      onClick={() => {
        // WARNING: This is extremely fragile and will break
        // when anything about the video urls changes.
        const origKey = url.split("videos/")[1];
        const parts = origKey.split("/");
        const filename = parts[parts.length - 1].split(".")[0];
        const keyBody = parts.slice(0, parts.length - 3).join("/");
        const sourceKey = `${keyBody}/${filename}.mp4`;
        getVideoDownloadUrl(sourceKey).then((res) => setLabel(res.data.url));
      }}
    >
      {label ? <a href={label}>{"download"}</a> : "Get Download Link"}
    </Button>
  );
};
