import React, { Component } from "react";
import { Modal, Button, Icon } from "semantic-ui-react";
import { ToastOptions } from "react-toastify";

interface IProps {
  isOpen: boolean;
  toggleModal: () => void;
  link: string;
  makeToast: (text: string, options?: ToastOptions) => void;
}

const copy = require("clipboard-copy");

export class ResetPasswordLinkModal extends Component<IProps> {

  render() {
    const { isOpen, toggleModal, link, makeToast } = this.props;
    return (
      <Modal open={isOpen}>
        <Modal.Header>{"Get User Password Reset Link Success!"}</Modal.Header>
        <Modal.Content>{`Link: ${link}`}</Modal.Content>
        <Modal.Actions>
          <Button 
            color="blue"
            onClick={() => {
              copy(link);
              makeToast("Link copied!", {type: "success"})
              toggleModal();
            }}
          >
            <Icon name="clipboard"/>
            {"Copy To Clipboard"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}