import grapefruitImage from "../assets/grapefruit.jpg";
import staticProfilePic from "../assets/generic-female-profile-picture.jpg"
import { AccountTypeKeys } from "tonittypes";

export enum ImageSource {
  feed = "FEED",
  reviewables = "REVIEWABLES",
  avatar = "PROFILE_IMAGE",
  video = "VIDEO",
}

// Content Moderation Constants
export enum modalType { APPROVE, DECLINE }
export interface ITab {
  id: string;
  menuItem: string;
  render: any;
}
export enum sortType {
  NEWEST = "Newest",
  OLDEST = "Oldest",
  REPORTS = "Reported",
}

export enum SearchApiMode {
  NONE = "none",
  ID = "id",
  TEXT = "text",
}

export enum UserSupportedSearchField {
  EMAIL = "email",
  USERNAME = "username",
}

export interface IImageTypeConfig {
  whitelist: Array<string>,
  fallback: string,
}
export interface IImageGroupConfig {
  posts: IImageTypeConfig,
  reviewables: IImageTypeConfig,
  avatar: IImageTypeConfig,
}

export interface ISingleImageData {
  url: string,
  location: string
};

export interface IPostImageData {
  post: ISingleImageData[],
  avatar: ISingleImageData
};

export const imageGroupConfig: IImageGroupConfig = {
  posts: {
    whitelist: ["images"],
    fallback: grapefruitImage
  },
  reviewables: {
    whitelist: ["images", "quarantine"],
    fallback: grapefruitImage
  },
  avatar: {
    whitelist: ["images"],
    fallback: staticProfilePic
  },
};

export interface IAccountTypeData {
  title: string;
  badgeColor: string;
  priority: number;
}

export const AccountTypes: { [k: string]: IAccountTypeData } = {
  [AccountTypeKeys.ADMIN]: {
    title: "Admin",
    badgeColor: "#808080",
    priority: 10,
  },
  [AccountTypeKeys.COMMUNITY_CHAMPION]: {
    title: "Champion",
    badgeColor: "#9BB33E",
    priority: 8,
  },
  [AccountTypeKeys.BUSINESS]: {
    title: "Business",
    badgeColor: "#5CA3CB",
    priority: 6,
  },
  [AccountTypeKeys.PREMIUM]: {
    title: "Premium",
    badgeColor: "#E6B839",
    priority: 4,
  },
};
