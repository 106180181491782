import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Link, useHistory, useParams } from 'react-router-dom';
import { ToastOptions } from "react-toastify";
import { Button, Image, Input, Label, Modal, Segment } from "semantic-ui-react";
import { IClub } from "tonittypes";
import { indexSearch, transferClubOwnership } from "../../../api/api";
import * as config from "../../../config/config.json";
import { imageGroupConfig, SearchApiMode } from '../../../constants/constants';
import { ClientRoutes, getRouteToDetail } from "../../../constants/routes";
import { ClubDetails } from "./ClubDetails";
import { ClubReportDetails } from "./ClubReportDetails";

interface IOwnProps {
  makeToast: (text: string, options?: ToastOptions) => void;
}

export const ClubPage = ({ makeToast }: IOwnProps) => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [club, setClub] = useState<IClub>();
  const [transferInput, setTransferInput] = useState("");

  const fetchClub = useCallback(async () => {
    const fetchedClubs = await indexSearch(config.algolia.clubIndex, id as string, SearchApiMode.ID);
    if (fetchedClubs && fetchedClubs.length) {
      return setClub(fetchedClubs[0]);
    }
  }, [id])

  useEffect(() => {
    fetchClub();
  }, [fetchClub]);

  const goBack = () => {
    history.goBack();
  }

  const transfer = () => {
    transferClubOwnership(id, transferInput)
      .then(() => makeToast(`${transferInput} is now the owner of this club`, { type: "success" }))
      .catch(error => makeToast(error.response.data.error.message, { type: "error" }));
  };

  if (!club) return null;

  return (<>
    <Segment style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <Button icon='arrow left' onClick={goBack} />
      <Image style={{ marginLeft: 10, maxHeight: 200 }} src={club?.clubBanner
        ? club.clubBanner
        : imageGroupConfig.avatar.fallback} />
      <h1 style={{ paddingLeft: 20, paddingRight: 20 }}>{club?.name}</h1>
    </Segment>
    <Segment style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center" }}>
      <Link to={getRouteToDetail(ClientRoutes.Feed, club._id, { select: "club", input: club._id })}>
        <Button>{`View club feed`}</Button>
      </Link>
    </Segment>
    <ClubDetails club={club as IClub} />
    <ClubReportDetails club={club as IClub} makeToast={makeToast} />

    <Modal trigger={
      <Button
        compact
        content="Transfer"
      />
    }>
      <Modal.Content>
        <Segment style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Label
            pointing="right"
            style={{ paddingLeft: 20, paddingRight: 20 }}>
            {"Transfer ownership to:"}
          </Label>
          <Input onChange={(event: ChangeEvent, data: any) => {
            setTransferInput(data.value);
          }} />
          <Button
            style={{ marginLeft: 16 }}
            content="Transfer"
            secondary
            onClick={transfer}
          />
        </Segment>
      </Modal.Content>
    </Modal>
  </>
  )
};
