import { AxiosError } from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import { Icon, Label, Popup } from "semantic-ui-react";
import { getQualifiedEvent } from "../../api/api";
import { debounce } from "../Search";
import { makeToast } from "../Toaster";
import { EventItem } from "./eventsList"

const rowStyle = {
  marginBottom: "32px",
  width: "100%",
  display: "flex",
  flexDirection: "row" as "row",
  flexWrap: "nowrap" as "nowrap",
  justifyContent: "flex-start",
  alignItems: "center"
};

export const useSearchApi = (searchFn: (searchStr: string) => any) => {
  const [isLoading, setIsLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const search = async (searchStr: string) => {
    try {
      if (!searchStr) return;

      setIsLoading(true);

      await searchFn(searchStr);

    } catch (error) {

      if (error.isAxiosError) {
        const apiError = error as AxiosError<{ error: Error }>;
        if (apiError?.response?.data?.error?.message) {
          return makeToast(apiError.response.data.error.message, { type: "error" });
        }
      }

      // Non axios error -- something else went wrong?
      makeToast(`Unknown error occured: ${error.toString()}`, { type: "error" });
    } finally {
      setIsLoading(false);
    }
  }

  const debounceOnSearch: (...value: (string | undefined)[]) => any = useMemo(() => debounce(search, 350, false), []);

  return { search: debounceOnSearch, isLoading };
};

/**
 * View a bunch of details for a single post.
 *
 * Including all or its comments, likes, reports, and reports on each comment
 */
export const EventSearch = withRouter(({ location, history }: RouteComponentProps) => {

  const [search, setSearch] = useState<string | undefined>(() => new URLSearchParams(location.search).get("search") || "");
  const [searchResults, setSearchResults] = useState<any[]>([]);

  const { isLoading, search: searchApi } = useSearchApi(async (searchStr) => {
    const res = await getQualifiedEvent(searchStr);
    if (res?.data) {
      setSearchResults(Array.isArray(res.data.event) ? res.data.event : [res.data.event]);
    } else {
      setSearchResults([]);
    }
  });

  useEffect(() => {
    history.push(
      {
        pathname: location.pathname,
        search: `?search=${search}`
      }
    );
  }, [history, location.pathname, search]);

  useEffect(() => {
    searchApi(search);
  }, [search]);

  return <div>
    <div style={rowStyle}>
      <Popup inverted
        content="You can search with a post id or comment id"
        trigger={<Label horizontal size="large" pointing="right" style={{ zIndex: 1 }} content="Search" />}
      />
      <input
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search..."
        value={search}
        disabled={isLoading}
        onKeyUp={e => e.key === "Enter" ? console.log(search) : null}
        style={{
          width: "50%",
          padding: "8px 32px",
          borderRadius: "16px",
          borderWidth: "1",
          borderColor: "#ccd",
          color: "#999",
          marginRight: "16px",
        }}
      />
      {isLoading ? <Icon loading name="spinner" size="large" /> : null}
    </div>
    <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column", justifyContent: "space-around" }}>
      {searchResults?.map(event => {
        return <>
          <EventItem
            event={event}
          />
        </>
      }
      )}
    </div>
  </div >;
});
