import { AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";

interface IConfig {
    pageSize: number;
    after?: any;
    selfManaged?: boolean;
    onError?: (error: Error) => void;
}

export const useApiList = <T,>(fn: (cursors: any) => Promise<AxiosResponse<{ data: T[], cursors: any }>>, {pageSize, after, selfManaged, onError}: IConfig) => {
    const [data, setData] = useState<T[]>([]);
    const [isLoading, setIsLoading] = useState(!selfManaged);
    const [cursors, setCursors] = useState<any>({ limit: pageSize, after });

    const fetchAndSet = useCallback((ccursors?: any) => {
        setIsLoading(true);
        fn(ccursors)
            .then(res => {
                setData(d => [...(ccursors?.after ? d : []), ...res.data?.data]);
                setCursors(res.data?.cursors);
            })
            .catch(res => {
                console.warn(res);
                onError?.(res);
            })
            .finally(() => setIsLoading(false));
    }, [fn, onError]);

    const refresh = useCallback(() => {
        setCursors((c: any) => ({ ...c, after }));
        fetchAndSet({ limit: pageSize });
    }, [after, fetchAndSet, pageSize]);

    useEffect(() => {
        // Load from the api on mount
        if (!selfManaged) fetchAndSet({ limit: pageSize });
    }, []); // eslint-disable-line

    return {
        data,
        isLoading,
        next: () => fetchAndSet(cursors),
        cursors,
        hasMore: !!data.length && data.length % pageSize === 0,
        refresh,
    };
};

export const useMutableApiList = <T extends { _id: string }>(fn: (cursors: any) => Promise<AxiosResponse<any>>, config: IConfig) => {
    const api = useApiList<T>(fn, config);
    const [local, setLocal] = useState<Array<T>>([]);
    const [filter, setFilter] = useState<string[]>([]);

    return {
        ...api,
        data: [...api.data, ...local].filter(d => !filter.some(f => f === d?._id)),
        push: (obj: T) => {
            setFilter(filter.filter(f => f !== obj?._id));
            setLocal([...local, obj]);
        },
        pull: (id: string) => {
            setFilter([...filter, id]);
        }
    };
};
