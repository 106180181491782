import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { Icon, Image, Loader, Popup, Segment } from "semantic-ui-react";
import { IEvent } from "tonittypes";
import { fetchComments, getEventsList } from "../../api/api";
import { ClientRoutes, getRouteToDetail } from "../../constants/routes";
import { Card, ThreadPreview } from "../feeds/posts/PostItem";
import { colors, Column, DeContent, PlainContent, RichContent, Row, SpecialContent, Time, Title, Username } from "../feeds/styles";

const useEventsList = (pageSize: number) => {
  const [events, setEvents] = useState<IEvent[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cursors, setCursors] = useState({ limit: pageSize });

  const fetchAndSet = () => {
    setIsLoading(true);
    getEventsList(cursors)
      .then(res => {
        console.log(res.data);
        setEvents([...events, ...res.data?.data]);
        setCursors(res.data?.cursors);
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    fetchAndSet();
  }, []); // eslint-disable-line

  return {
    events,
    isLoading,
    next: fetchAndSet,
    hasMore: events.length % pageSize === 0,
  };
};

const PAGE = 8;
export const EventsList = () => {
  const { events, next, hasMore, isLoading } = useEventsList(PAGE);

  return <InfiniteScroll
    dataLength={events.length} //This is important field to render the next data
    next={next}
    hasMore={hasMore}
    endMessage={
      isLoading
        ? null
        : <Row style={{ justifyContent: "center" }}>
          <Segment>
            <PlainContent>{events?.length ? "no more events" : "no events"}</PlainContent>
          </Segment>
        </Row>
    }
    loader={
      <Loader active>{"Finding Events..."}</Loader>
    }>
    {events.map((event: any) => {
      return <EventItem event={event} />;
    })}
  </InfiniteScroll>
};

const w = 256;
const h = w / 1.5;

const RSVPCount = ({ count = 0, label }: { count: number | undefined; label: string }) => {
  return (
    <Row>
      <Column>
        <DeContent>
          {label} : <PlainContent>{count}</PlainContent>
        </DeContent>
      </Column>
    </Row>
  );
};

export const EventItem = ({ event }: { event: IEvent }) => {
  const { push } = useHistory();

  const handleViewProfileClick = async () => {
    push(getRouteToDetail(ClientRoutes.Users, event.ownerId!));
  }

  if (!event) {
    return null;
  }

  return (
    <Card key={event.id}>
      {(cardWidth: number) => <>
        <Column>

          <Row>
            <Column>
              <Title>
                {event.title}
              </Title>
            </Column>
          </Row>

          <Row>


            <Column>
              <Image
                style={{
                  borderRadius: "0pt",
                  width: `${w}pt`,
                  height: `${h}pt`,
                  minHeight: `${h}pt`,
                  minWidth: `${w}pt`,
                }}
                src={event.mediaGallery?.media[0].url}
              />
            </Column>

            <Column>

              <Row>
                <Column>
                  <Username onClick={handleViewProfileClick}>
                    {event.authorName}
                  </Username>
                </Column>
              </Row>

              <Row>
                <Column>
                  <Time date={event.createdAt || "unkown"} />
                </Column>
              </Row>

              <Row>
                <Column>
                  <SpecialContent>
                    {event.location?.humanized}
                  </SpecialContent>
                </Column>
              </Row>

              <Row>
                <Column>
                </Column>
              </Row>

              <Row>
                <Column>
                  <RichContent>
                    {event.description}
                  </RichContent>
                </Column>
              </Row>

              <Row>
                <Column>
                  <DeContent>
                    status
                  </DeContent>
                </Column>
              </Row>

              <Row>
                <Column>
                  <RichContent>
                    {event.status}
                  </RichContent>
                </Column>
              </Row>

              <Row>
                <Column>
                  <DeContent>
                    start date
                  </DeContent>
                </Column>
              </Row>

              <Row>
                <Column>
                  <RichContent>
                    {format(event.startDate, "PPpp")}
                  </RichContent>
                </Column>
              </Row>

              <Row>
                <Column>
                  <DeContent>
                    end date
                  </DeContent>
                </Column>
              </Row>

              <Row>
                <Column>
                  <RichContent>
                    {format(event.endDate, "PPpp")}
                  </RichContent>
                </Column>
              </Row>

              <RSVPCount
                label="Accepted"
                count={event.rsvpCount?.accepted}
              />

              <RSVPCount
                label="Interested"
                count={event.rsvpCount?.interested}
              />

              <RSVPCount
                label="Declined"
                count={event.rsvpCount?.declined}
              />

              <Row style={{}}>
                <Column>
                  {/* <DeContent>
                <Popup
                  trigger={
                    <Icon name="thumbs up" style={{ color: colors.tertiary, marginRight: "8pt" }} />
                  } >
                  <Popup.Content>
                    <ThreadPreview postId={""} maxItems={5} fetchThread={fetchLikes} />
                  </Popup.Content>
                </Popup>
                {`${event.rsvpCount || 0}`}
              </DeContent> */}
                </Column>

                <Column>
                  <DeContent>
                    <Popup
                      trigger={
                        <Icon name="comment" style={{ color: colors.tertiary, marginRight: "8pt" }} />
                      } >
                      <Popup.Content>
                        <ThreadPreview postId={event.id!} maxItems={3} fetchThread={fetchComments} />
                      </Popup.Content>
                    </Popup>
                    {`${event.commentCount || 0}`}
                  </DeContent>
                </Column>

                <Column style={{}}>
                  {/* <DeContent>
                <Link to={getRouteToDetail(ClientRoutes.PostSearch, post.id || post._id!)}>
                  <Popup
                    content="go to this post"
                    trigger={<Icon name="chevron right" style={{ color: colors.tertiary }} />} />
                </Link>
              </DeContent> */}
                </Column>

                <Column style={{}}>
                  {/* <Modal trigger={
                <span>
                  <DeContent>
                    <Popup
                      content="view details"
                      trigger={<Icon name="code" style={{ color: colors.tertiary, cursor: "pointer" }} />} />
                  </DeContent>
                </span>
              }>
                <Modal.Content>
                  <DetailsSegment
                    title={"Raw Data"}
                    accentColor="#919"
                    data={toDetails(post)}
                  />
                </Modal.Content>
              </Modal> */}
                </Column>
              </Row>

            </Column>
            <Column style={{ width: "42pt" }}>

              <Row>
              </Row>

            </Column>
          </Row>
        </Column>
      </>}
    </Card>
  );
};
