import React from "react";
import { Icon } from "semantic-ui-react";
import { AccountTypes } from "tonittypes";

export const UserBadge = ({ accountTypes }: any) => {
  const topAccountType = AccountTypes.top(accountTypes);
  if (topAccountType) {
    return <Icon
      name="check circle"
      size="small"
      style={{
        width: 15,
        height: 15,
        marginLeft: 5,
        color: topAccountType.badge.color,
      }} />
  } else {
    return null;
  }
};
