import config from "../../config/config.json";
import React from "react";
import { Segment } from "semantic-ui-react";
import { ChatRooms } from "../chats/Chats";

export const ConciergeChat = () => {
    return <Segment>
        <ChatRooms userId={config.conciergeUserId} messagable onDismiss={() => null} />
    </Segment>;
};
