import React, { FC, useEffect, useState } from "react";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import TimeAgo from "react-timeago";
import { ToastOptions } from "react-toastify";
import "semantic-ui-css/semantic.min.css";
import { Button, Comment, Header, Icon, Label, Popup, Segment } from "semantic-ui-react";
import { ReportTypes, IImpression, EngagementTypes } from "tonittypes";
import { adminDeleteComment, getImpressions, getQualifiedPost } from "../api/api";
import { UserBadge } from "../components/shared";
import { imageGroupConfig, ImageSource } from "../constants/constants";
import { ClientRoutes, getRouteToDetail } from "../constants/routes";
import PostItem from "./feeds/posts/PostItem";
import { makeToast } from "./Toaster";
// @ts-ignore
import { LineChart, Legend, ChartContainer, Charts, YAxis, ChartRow, styler } from "react-timeseries-charts";
import { useSearchApi } from "./events/EventSearch";
// @ts-ignore
import { TimeSeries } from "pondjs";

const rowStyle = {
  marginBottom: "32px",
  width: "100%",
  display: "flex",
  flexDirection: "row" as "row",
  flexWrap: "nowrap" as "nowrap",
  justifyContent: "flex-start",
  alignItems: "center"
};

const linkStyle = {
  color: "teal",
};

interface IReportObject {
  isDeleted: boolean;
  _id: string;
  reportedId: string;
  userId: string;
  type: ReportTypes;
  reason: string;
}

interface IData {
  label: string;
  value: string | unknown;
}

/**
 * View a bunch of details for a single post.
 *
 * Including all or its comments, likes, reports, and reports on each comment
 */
export const PostsSearch = withRouter(({ location, history }: RouteComponentProps) => {

  const { push } = useHistory();
  const [search, setSearch] = useState<string | undefined>(() => new URLSearchParams(location.search).get("search") || "");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [commentResults, setCommentResults] = useState<any[]>([]);
  const [likeResults, setLikeResults] = useState<any[]>([]);
  const [postReportsResults, setPostReportsResults] = useState<IReportObject[]>([]);
  const [commentReportsResults, setCommentReportsResults] = useState<any[]>([]);
  const [reportCount, setReportCount] = useState<IData[]>([]);

  const fallbackId = location.pathname?.split("/").pop();

  useEffect(() => {
    history.push(
      {
        pathname: location.pathname,
        search: `?search=${search}`
      }
    );
  }, [history, location.pathname, search]);

  const { isLoading, search: searchApi } = useSearchApi(async (searchStr) => {
    try {
      const postsRes = await getQualifiedPost(searchStr);

      if (postsRes?.data) {
        setSearchResults(Array.isArray(postsRes.data.post) ? postsRes.data.post : [postsRes.data.post]);
        setCommentResults(postsRes.data.comments.data);
        setLikeResults(postsRes.data.likes.data);
        setCommentReportsResults(postsRes.data.commentReports.data);
        setPostReportsResults(postsRes.data.postReports.data);
      }
    } catch (e) {
      setSearchResults([]);
      setCommentResults([]);
      setLikeResults([]);
      setPostReportsResults([]);
      setCommentReportsResults([]);
    }
  });

  useEffect(() => {
    const reportCounts = postReportsResults.reduce((acc: any, curr: IReportObject) => {
      return [...acc.filter((d: IData) => d.label !== curr.reason),
      {
        label: curr.reason,
        value: (acc.find((d: IData) => d.label === curr.reason)?.value || 0) + 1,
      }
      ]
    }, [])
    return setReportCount(reportCounts);
  }, [postReportsResults]);

  useEffect(() => {
    searchApi(search || fallbackId);
  }, [search, fallbackId]);

  return <div>
    <div style={rowStyle}>
      <Popup inverted
        content="You can search with a post id or comment id"
        trigger={<Label horizontal size="large" pointing="right" style={{ zIndex: 1 }} content="Search" />}
      />
      <input
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search..."
        value={search}
        disabled={isLoading}
        onKeyUp={e => e.key === "Enter" ? console.log(search) : null}
        style={{
          width: "50%",
          padding: "8px 32px",
          borderRadius: "16px",
          borderWidth: "1",
          borderColor: "#ccd",
          color: "#999",
          marginRight: "16px",
        }}
      />
      {isLoading ? <Icon loading name="spinner" size="large" /> : null}
    </div>
    <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column", justifyContent: "space-around" }}>
      {searchResults?.map(post => {
        return <>
          <PostItem
            post={post}
            makeToast={makeToast}
            imageConfig={imageGroupConfig.posts}
            maxWidth="512px"
            key={post._id}
            imageSource={ImageSource.feed} />
        </>
      }
      )}
      {postReportsResults?.length
        ? <ReportGroup reports={postReportsResults} push={push} count={reportCount.length} />
        : null}
      <CommentGroup
        comments={commentResults}
        commentReports={commentReportsResults}
        makeToast={makeToast}
        push={push} />
      <LikeGroup likes={likeResults} push={push} />
      {searchResults?.map(post => <PostImpressiosn postId={post.id} />)}
    </div>
  </div >;
});

interface ICommentGroupProps {
  push: (path: string) => void;
  comments: any[];
  commentReports: any[];
  makeToast: (text: string, options?: ToastOptions) => void;
}

interface ILikeGroupProps {
  push: (path: string) => void;
  likes: any[];
}

interface IReportsGroupProps {
  push: (path: string) => void;
  reports: any[];
  count?: number;
}

const lineStyles = styler([
  { key: "impressions", color: "#ff1277", width: 1 },
  { key: "engagements", color: "#007932", width: 2 },
]);

const impressionsTimeseries = (impressions: IImpression[], granulairty: number): [TimeSeries, TimeSeries, number] => {
  const toSeries = (name: string, list: any[]) => {
    return new TimeSeries({
      name,
      columns: ["time", name],
      points: list.map((i: any) => [i.time, i.inWindow]),
    });
  };

  if (!impressions.length) return [toSeries("impressions", [{ time: Date.now() }]), toSeries("engagements", [{ time: Date.now() }]), 0];

  const floor = Math.floor(impressions[0].createdAt / (granulairty)) * granulairty;
  const ceil = Math.ceil(impressions[impressions.length - 1].createdAt / (granulairty)) * granulairty;

  const collapse = (name: string, filter = (e: any) => true): [TimeSeries, number] => {
    const list = [];
    for (let i = floor - 5 * granulairty; i < (ceil + 10 * granulairty) && i < Date.now(); i += granulairty) {
      const inWindow = impressions
        .filter(filter)
        .filter(a => a.createdAt > i && a.createdAt < (i + granulairty)).length;
      list.push({ time: i, inWindow });
    }

    const series = toSeries(name, list);

    return [series, Math.max(...list.map(i => i.inWindow))];
  };

  const [impressionsSeries, max1] = collapse("impressions", e => e.engagementType === EngagementTypes.IMPRESSION);
  const [engagementsSeries, max2] = collapse("engagements", e => e.engagementType !== EngagementTypes.IMPRESSION);

  return [impressionsSeries, engagementsSeries, Math.max(max1, max2)];
}

const useRefresh = (refresh: () => void) => {
  const [loading, setLoading] = useState(false);

  const load = async () => {
    try {
      setLoading(true);
      await refresh();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => { load() }, []);

  return [loading, load];
};

const PostImpressiosn: FC<{ postId: string }> = ({ postId }) => {
  const [impressions, setImpressions] = useState<IImpression[]>([]);
  const [granulairty, setGranularity] = useState(1000 * 60 * 5);
  const [loading, setLoading] = useState(false);

  const refresh = async () => {
    try {
      setLoading(true);
      await getImpressions(postId).then(res => setImpressions(res.data.data.sort((a: any, b: any) => a.createdAt - b.createdAt)));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const [impressionsSeries, engagementsSeries, max] = impressionsTimeseries(impressions, granulairty);

  return <Comment.Group>
    <Segment style={{ minWidth: 840 }}>
      <Header as='h3' dividing>
        {"Impressions"}
        <Button circular compact floated="right" size="small" icon="refresh" onClick={() => refresh()} loading={loading} />
      </Header>

      <Legend
        stack
        type="line"
        align="right"
        style={lineStyles}
        categories={[
          { key: "impressions", label: "impressions" },
          { key: "engagements", label: "engagements" },
        ]} />
      <ChartContainer timeRange={impressionsSeries.timerange()} width={800}>
        <ChartRow height="200">
          <YAxis id="axis1" label="impressions" min={0} max={max} width="60" />
          <Charts>
            <LineChart interpolation="curveBasis" axis="axis1" series={impressionsSeries} columns={["impressions"]} style={lineStyles} />
            <LineChart interpolation="curveBasis" axis="axis1" series={engagementsSeries} columns={["engagements"]} style={lineStyles} />
          </Charts>
        </ChartRow>

      </ChartContainer>

    </Segment>
  </Comment.Group>
    ;
};

export const CommentGroup = (props: ICommentGroupProps) => {
  const [removed, setRemoved] = useState<string[]>([]);

  return <Comment.Group>
    <Segment style={{ minWidth: 840 }}>
      <Header as='h3' dividing>
        {"Comments"}
      </Header>

      {props.comments
        ?.filter(comment => !removed.some(r => r === comment.id))
        ?.map(comment => ({ comment, reports: props.commentReports.filter(cr => cr.reportedId === comment.id) }))
        ?.map(({ comment, reports }) => <Comment key={comment.createdAt}>
          <Comment.Avatar src={comment.profilePhotoUrl} />
          <Comment.Content>
            <Comment.Author as='a'>
              <div style={linkStyle} onClick={() => {
                props.push(getRouteToDetail(ClientRoutes.Users, comment.userId));
              }}>
                {comment.authorName}
                <UserBadge accountTypes={comment.accountTypes} />
              </div>
            </Comment.Author>
            <Comment.Metadata>
              <TimeAgo date={comment.createdAt} />
            </Comment.Metadata>
            <Comment.Metadata>
              {`id:${comment.id}`}
            </Comment.Metadata>
            <Comment.Text>{comment.text}</Comment.Text>
            {reports?.length ? <ReportGroup push={props.push} reports={reports} count={reports.length} /> : null}
          </Comment.Content>
          <Comment.Actions>
            <Comment.Action>
              <span onClick={async () => {
                try {
                  await adminDeleteComment(comment.userId, comment.threadId, comment.id);
                  setRemoved([...removed, comment.id]);
                } catch (error) {
                  console.log(error);
                  makeToast(error.message, { type: "error" });
                }
              }}>
                {"delete"}
              </span>
            </Comment.Action>
          </Comment.Actions>
        </Comment>)}
    </Segment>
  </Comment.Group>;
};

export const LikeGroup = (props: ILikeGroupProps) => {
  return <Comment.Group>
    <Segment style={{ minWidth: 840 }}>
      <Header as='h3' dividing>
        Likes
      </Header>

      {props.likes?.map(like => <Comment key={like._id}>
        <Comment.Content>
          <Comment.Author as='a'>
            <div style={linkStyle} onClick={() => {
              props.push(getRouteToDetail(ClientRoutes.Users, like.profileId));
            }}>
              {like.authorName}
              <UserBadge accountTypes={like.accountTypes} />
            </div>
          </Comment.Author>
        </Comment.Content>
      </Comment>)}
    </Segment>
  </Comment.Group>;
};

export const ReportGroup = (props: IReportsGroupProps) => {
  return <Comment.Group>
    <Segment>
      <Header as='h3' dividing>
        {`Reports (${props.count})`}
      </Header>
      {props.reports?.map(report => <Comment key={report._id}>
        <Comment.Content>
          <Comment.Text>Reported by user id:</Comment.Text><Comment.Author as='a'>
            <div style={linkStyle} onClick={() => {
              props.push(getRouteToDetail(ClientRoutes.Users, report.userId));
            }}>
              {report.userId}
            </div>
          </Comment.Author>
          <Comment.Text><strong>Reason:</strong> {report.reason || "No reason specified"}</Comment.Text>
          <Comment.Text><strong>User Message:</strong> {report.userMessage || "No message"}</Comment.Text>
          <Comment.Metadata>
            <TimeAgo date={report.createdAt} />
          </Comment.Metadata>
        </Comment.Content>
      </Comment>)}
    </Segment>
  </Comment.Group>;
};
