import React, { useEffect, useState } from "react";
import { Segment, Modal, Button } from "semantic-ui-react";
import {
  getRidingStyles,
  getRidingStylesEnteredAffinities,
} from "../../../api/api";
import { IRidingStyle, RidingStyles } from "./RidingStyles";
import { makeToast } from "../../Toaster";
import { PlainContent, Title2 } from "../../feeds/styles";
import { RelevanceComparison } from "./RelevanceComparison";

export const Landing = () => {
  const [ridingStyles, setRidingStyles] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        const [{ data: enteredAffinities }, { data: styles }] =
          await Promise.all([
            getRidingStylesEnteredAffinities(),
            getRidingStyles(),
          ]);

        const combinedStyles = (enteredAffinities || []).map(
          (s: IRidingStyle) => {
            // we need name of the style name from styles endpoint
            const style = (styles || []).find(
              (rS: IRidingStyle) => rS._id === s._id
            );

            return {
              ...(style || {}),
              ...s,
            };
          }
        );

        setRidingStyles(combinedStyles);
      } catch (e) {
        makeToast(
          "There was a problem fetching data, try refreshing the page",
          { type: "error" }
        );
      }
    };

    fetch();
  }, []);

  return (
    <Segment>
      <Title2>Style Pilot</Title2>
      <div
        style={{
          margin: "0 10px 20px",
          display: "flex",
          flex: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <PlainContent>
            {"Docs on style pilot can be found "}
            <a
              href="https://tonit.atlassian.net/wiki/spaces/TA/pages/1487634433/Style+Pilot"
              target="_blank"
              rel="noreferrer"
            >
              here.
            </a>
          </PlainContent>
        </div>
        <div>
          <Button onClick={() => setModalOpen(true)} secondary>
            View Relevance Comparison
          </Button>
        </div>
      </div>

      <Modal
        onClose={() => setModalOpen(false)}
        onOpen={() => setModalOpen(true)}
        open={modalOpen}
        size={"fullscreen"}
        closeIcon
      >
        <Modal.Header>Relevance Comparison</Modal.Header>
        <Modal.Content scrolling>
          <RelevanceComparison />
        </Modal.Content>
      </Modal>

      <RidingStyles ridingStyles={ridingStyles} />
    </Segment>
  );
};
