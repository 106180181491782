import React, { FC, useState } from "react";
// import 'semantic-ui-css/semantic.min.css';
import "./index.css";
import { Button, Grid, Header, Modal } from "semantic-ui-react";
import styled, { keyframes } from "styled-components";
import { Router } from "./components/Router";
import { SideBarColumn } from "./components/SideBarColumn";
import config from "./config/config.json";
import { UserCountProvider } from "./contexts/suspendedUsers.context";

interface IProps {
  idToken: string;
  email: string;
  emailVerified: boolean;
  displayName: string;
  redirectSignInError: boolean;
}

const App: FC<IProps> = (props) => {
  const { email, emailVerified, redirectSignInError } = props;

  const [fullscreenFeed, setFullscreenFeed] = useState(false);
  const [gradientMode, setGradientMode] = useState(false);

  if (redirectSignInError
    || !email
    || !emailVerified
    || !config.authorizedDomains.some(domain => email.endsWith(`@${domain}`))
  ) {
    return <div className="App">
      <Modal
        defaultOpen={true}
        basic
        size='small'
      >
        <Header icon="lock" size="huge" content='Could not authenticate...' />
        <Modal.Content>
          <Header inverted size="small" icon="google" content={`Are you using the correct account ${email}?`} />
        </Modal.Content>
      </Modal>
    </div>;
  }

  return (
    <Trippy enabled={gradientMode}>
      <UserCountProvider>
      <Grid style={{ width: "100vw" }}>
        {!fullscreenFeed
          ? <SideBarColumn
          handleGrapefruitClick={() => setGradientMode(!gradientMode)}
          onTVClick={() => setFullscreenFeed(!fullscreenFeed)} />
          : <Grid.Column width={1} >
            <Button icon="close" onClick={() => setFullscreenFeed(!fullscreenFeed)} />
          </Grid.Column>}
        <Grid.Column stretched style={{ paddingRight: 0 }} width={fullscreenFeed ? 16 : 13} >
          <Router fullscreenFeed={fullscreenFeed} />
        </Grid.Column>
      </Grid>
      </UserCountProvider>
    </Trippy>
  );
}

export default App;

const spin = keyframes`
  0% {
    background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;

const Gradient = styled.div`
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
  background-repeat: round;
	animation: ${spin} 15s ease infinite;
  height: 100%;
  width: 100%;
`;

// Testing
export const bgColors = {
  a: "#e6ecf7",
  b: "#407088",
  c: "#f7fafb",
  d: "#132743",
  e: "#fffff4",
};

const Trippy: FC<{ enabled: boolean }> = ({ enabled, children }) => {
  if (enabled) {
    return <Gradient className="App">{children}</Gradient>;
  } else {
    return <div style={{ backgroundColor: bgColors.e, minHeight: "100vh" }} className="App">{children}</div>;
  }
}
