import { useState, useEffect, useMemo } from "react";
import { ISearchObject } from "../components/SearchLanding";
import { debounce } from "../components/Search";

export const useSearch = (
  searchString: string,
  activeSearchModeObject: ISearchObject,
  isDeleted: boolean,
) => {
  const [resultHits, setHits] = useState<any[]>([]);
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchData = async (newSearchString: string, searchModeObject: ISearchObject, isDeleted: boolean) => {
    setIsLoading(true);
    setHits([]);
    try {
      const handleData = await searchModeObject?.handler(newSearchString, isDeleted);
      return setHits(handleData);
    } catch (e) {
      setError(e);
      return setHits([]);
    } finally {
      return setIsLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOnSearch: (value: string, searchMode: ISearchObject, deleted: boolean) => void = useMemo(() => debounce(fetchData, 350, false), []);

  useEffect(() => {
    debounceOnSearch(searchString, activeSearchModeObject, isDeleted);
  }, [activeSearchModeObject, debounceOnSearch, isDeleted, searchString]);

  return { resultHits, loading, error };
}