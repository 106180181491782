import React, { ChangeEvent, Component } from "react";
import { Input, Modal, ModalActions, Button } from "semantic-ui-react";

interface IProps {
  isChangeEmailModalOpen: boolean;
  changeUserEmail: (text: string) => void;
  toggleChangeEmailModal: () => void;
}

interface IState {
  inputText: string,
}

export class ChangeEmailModal extends Component<IProps, IState> {

  state = {
    inputText: "",
  }

  public constructor(props: any) {
    super(props);
    this.setInputText = this.setInputText.bind(this);
  }

  public setInputText(text: string) {
    this.setState({ inputText: text });
  }

  render() {
    const { isChangeEmailModalOpen, changeUserEmail, toggleChangeEmailModal } = this.props;
    return (
      <Modal open={isChangeEmailModalOpen}>
        <Modal.Header content="Change User Email" />
        <div style={{ display: "flex", flex: "1", justifyContent: "center", margin: 10 }}>
          <h3 style={{ margin: 10 }}>"Enter the new email"</h3>
          <Input onChange={(event: ChangeEvent, data: any) => {
            this.setInputText(data.value);
          }} />
        </div>
        <ModalActions>
          <Button
            onClick={() => {
              changeUserEmail(this.state.inputText);
              toggleChangeEmailModal()
            }}
            color="green"
            content="Accept"
          />
          <Button
            onClick={() => {
              toggleChangeEmailModal()
            }}
            color="red"
            content="Decline"
          />
        </ModalActions>
      </Modal>
    )
  }
}