import React, { useState, useEffect } from "react";
import { ToastOptions } from 'react-toastify';
import { Search as SemanticSearch, Label, Checkbox, Dropdown, LabelDetail, Segment } from "semantic-ui-react";
import { useSearch } from "../hooks/useSearch";
import { UserCard } from "./SearchCards/UserCard";
import { clubSearch } from "../searchHandlers/createSearchApi";
import { userSearch } from "../searchHandlers/userSearch";
import { hashtagSearch } from "../searchHandlers/createSearchApi";
import { ClubCard } from "../components/SearchCards/ClubCard";
import { Hashtag } from "../components/SearchCards/Hashtag";
import { UserSupportedSearchField, SearchApiMode } from "../constants/constants";

const centeredRowStyle = {
  marginBottom: "10px",
  display: "flex",
  flexDirection: "row" as "row",
  flexWrap: "nowrap" as "nowrap",
  justifyContent: "center",
  alignItems: "center"
};

const filterStyle = {
  ...centeredRowStyle,
  margin: "0 10px 10px",
};

interface IWrapperProps {
  makeToast: (text: string, options?: ToastOptions) => void;
}

export interface ISearchData {
  isDeleted: boolean;
  searchMode: string;
}

export interface ISearchObject {
  handler: (searchString: string, isDeleted: boolean) => Promise<any>;
  renderHit: (hit?: any) => JSX.Element;
}

export interface ISearchMode {
  [key: string]: ISearchObject;
};

export const SearchWrapper = ({ makeToast }: IWrapperProps) => {

  const searchConfig = {
    user: {
      renderHit: (hit: any) => <UserCard hit={hit} />,
      searches: {
        name: {
          handler: userSearch(SearchApiMode.TEXT, UserSupportedSearchField.USERNAME),
        },
        email: {
          handler: userSearch(SearchApiMode.NONE, UserSupportedSearchField.EMAIL)
        },
      },
    },
    club: {
      renderHit: (hit: any) => <ClubCard hit={hit} />,
      searches: {
        name: {
          handler: clubSearch(SearchApiMode.TEXT),
        },
        id: {
          handler: clubSearch(SearchApiMode.ID),
        },
      },
    },
    hashtag: {
      renderHit: (hit: any) => <Hashtag hit={hit} />,
      searches: {
        name: {
          handler: hashtagSearch(SearchApiMode.TEXT),
        },
        id: {
          handler: hashtagSearch(SearchApiMode.ID),
        },
      },
    },

  }

  return (<SearchLanding searchModes={searchConfig} makeToast={makeToast} />)
}

export const SearchLanding = ({ searchModes, makeToast }: any) => {
  const [activeSearchModeObject, setActiveSearchModeObject] = useState<any>(searchModes?.user);
  const [activeSearchMode, setActiveSearchMode] = useState<any>(searchModes?.user.searches.name);
  const [searchString, setSearchString] = useState("");
  const [isDeleted, setIsDeleted] = useState(false);
  const { resultHits, loading, error } = useSearch(searchString, activeSearchMode, isDeleted);
  const searchObjects = Object.keys(searchModes).map((m) => {
    return ({ text: m, id: m, value: m });
  })
  const searchOptions = Object.keys(activeSearchModeObject.searches).map((n) => {
    return ({ text: n, id: n, value: n });
  })

  const handleSearchModeChange = (data: any) => {
    setActiveSearchModeObject(searchModes[data])
    setActiveSearchMode(searchModes[data].searches[searchOptions[0].text]);
  }

  const handleSearchOptionsChange = (data: any) => {
    setActiveSearchMode(activeSearchModeObject.searches[data]);
  }
  const handleSearchStringChange = (data: any) => {
    setSearchString(data.value);
  }

  useEffect(() => {
    if (error) {
      makeToast(`${error}`, { type: "error" });
    }
  }, [error, makeToast]);

  return (
    <div >
      <div style={centeredRowStyle}>
        <div style={filterStyle}>
          <Label horizontal pointing="right" size="large" >{"Deleted"}</Label>
          <Checkbox toggle onChange={e => setIsDeleted(!isDeleted)} />
        </div>
        <div style={filterStyle}>
          <Label horizontal size="large" style={{ zIndex: 1 }}>
            {"Search for:"}
            <LabelDetail >
              <Dropdown
                options={searchObjects}
                defaultValue={"user"}
                onChange={(e, data) => handleSearchModeChange(data.value)}
              />
            </LabelDetail>
          </Label>
          <Label horizontal size="large" style={{ zIndex: 1 }}>
            {"Search by:"}
            <LabelDetail >
              <Dropdown
                options={searchOptions}
                defaultValue={"name"}
                onChange={(e, data) => handleSearchOptionsChange(data.value)}
              />
            </LabelDetail>
          </Label>
          <SemanticSearch
            loading={loading}
            showNoResults={false}
            defaultValue={""}
            onSearchChange={(e, data) => handleSearchStringChange(data)}
          />
        </div>
      </div>
      <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "space-around" }}>
        {resultHits?.length ?
          resultHits.map((h: any) => activeSearchModeObject.renderHit(h))
          : <Segment><Label>What are you searching for?</Label></Segment>
        }
      </div>
    </div >
  );
}