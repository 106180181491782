import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import firebase from "firebase";
import * as serviceWorker from "./serviceWorker";
import { headerAdapter, requestSearchUsers } from "./api/api";
import config from "./config/config.json";
import { Modal, Header, Message, Icon } from "semantic-ui-react";
import Axios from "axios";
import { BrowserRouter } from "react-router-dom";

firebase.initializeApp(config); // Required

export const AdminContext = React.createContext<any>({});

export const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({
  login_hint: "user@tonit.com",
});

let appProps = {
  email: "",
  emailVerified: false,
  displayName: "",
  redirectSignInError: false,
  idToken: "",
};

const fetchAuthUser = async (email?: string | null) => {
  try {
    const requestedUser = await requestSearchUsers(
      false,
      email || undefined,
      [],
      "email",
      true
    );

    const adminProfile = requestedUser.data.users[0]?.profile;

    return [adminProfile, null];
  } catch (error) {
    return [null, error];
  }
};

renderComponent(
  <div className="App" style={{
    width: "100%",
    height: "100%",
  }}>
    <Modal defaultOpen={true} size="small">
      <Modal.Header>
        <Header size="huge" content="Welcome to grapefruit" />
      </Modal.Header>
      <Modal.Content>
        <Message icon>
          <Icon name="motorcycle" loading />
          <Message.Content>
            <Message.Header>{"Just one second"}</Message.Header>
            {"Signing you in."}
          </Message.Content>
        </Message>
      </Modal.Content>
    </Modal>
  </div>
);

firebase.auth().onAuthStateChanged(async (user) => {

  if (user) {
    const idToken = await user.getIdToken();

    headerAdapter.setHeaders("Content-Type", "application/json");
    headerAdapter.setHeaders("firebase-access-token", idToken);
    Axios.defaults.headers.common["Content-Type"] = "application/json";
    Axios.defaults.headers.common["firebase-access-token"] = idToken;

    const [adminProfile, fetchError] = await fetchAuthUser(user.email);

    console.log(adminProfile, fetchError);

    if (fetchError) {
      return renderComponent(
        <div>{`No response from Tonit server: ${fetchError.message}`}</div>
      );
    }

    if (!adminProfile) {
      return renderComponent(
        <div>{`No admin profile found. Must have an account in Tonit with admin status with the same email as this Google account.`}</div>
      );
    }

    renderComponent(
      <BrowserRouter>
        <AdminContext.Provider value={adminProfile}>
          <App
            idToken={appProps.idToken}
            email={user.email!}
            emailVerified={user.emailVerified}
            displayName={user.displayName!}
            redirectSignInError={false}
          />
        </AdminContext.Provider>
      </BrowserRouter>
    );
  } else {
    // User is signed out.
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        firebase
          .auth()
          .getRedirectResult()
          .then(async (result) => {
            if (result.credential) {
              // Get the current user and save their details in appProps
              const user = firebase.auth().currentUser;
              const { email, emailVerified, displayName } = result.user as any;
              appProps = { ...appProps, email, emailVerified, displayName };

              // Make Sure the current user exists, then get their authToken from firebase
              // and update the headerAdapter and axios default headers.
              if (user) {
                const idToken = await user.getIdToken();

                headerAdapter.setHeaders("Content-Type", "application/json");
                headerAdapter.setHeaders("firebase-access-token", idToken);
                Axios.defaults.headers.common["Content-Type"] =
                  "application/json";
                Axios.defaults.headers.common[
                  "firebase-access-token"
                ] = idToken;
                appProps.idToken = idToken;
              }

              renderComponent(
                <BrowserRouter>
                  <App
                    idToken={appProps.idToken}
                    email={appProps.email}
                    emailVerified={appProps.emailVerified}
                    displayName={appProps.displayName}
                    redirectSignInError={appProps.redirectSignInError}
                  />
                </BrowserRouter>
              );
            } else {
              firebase.auth().signInWithRedirect(provider);
            }
          })
          .catch((error) => {
            appProps.redirectSignInError = true;
            console.error(error);
          });
      })
      .catch(console.error);
  }
});

function renderComponent(component: any) {
  ReactDOM.render(component, document.getElementById("root"));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
