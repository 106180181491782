import React, { FC, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import 'semantic-ui-css/semantic.min.css';
import { Grid, Icon, Image, Menu, Label as UILabel } from "semantic-ui-react";
import { ClientRoutes } from "../constants/routes";
import { UserCountContext } from "../contexts/suspendedUsers.context";
import { Label } from "./feeds/styles";
import { Toaster } from "./Toaster";
import { UserCounter } from './UserCounter';

interface IProps {
  onTVClick: () => void;
  handleGrapefruitClick: () => void;
}

export const SideBarColumn: FC<IProps> = (props) => {
  const location = useLocation();
  return (
    <Grid.Column width={3}>
      <Image
        onClick={props.handleGrapefruitClick}
        className="borderRadius"
        src="./freshgrapefruit2.png"
        centered
      />
      <Menu fluid vertical>
        <Menu.Item as={Link} to={ClientRoutes.Feed} active={location?.pathname === ClientRoutes.Feed} >
          <Icon name="list alternate outline" />
          <Label>{"Feed"}</Label>
          <UILabel icon="tv" size="small" detail="fullscreen" onClick={props.onTVClick} />
        </Menu.Item>
        <Menu.Item as={Link} to={ClientRoutes.Moderation} active={location?.pathname === ClientRoutes.Moderation}>
          <Icon name="ban" />
          <Label><Label>{"Content Moderation"}</Label></Label>
        </Menu.Item>
        <Menu.Item as={Link} to={ClientRoutes.SuspendedAccounts} active={location?.pathname === ClientRoutes.SuspendedAccounts}>
          <Icon name="stop" >
          
          </Icon> 
          <Label>{"Suspended Accounts"}<Badge /></Label>
        </Menu.Item>
        <Menu.Item as={Link} to={ClientRoutes.Blocks} active={location?.pathname === ClientRoutes.Blocks}>
          <Icon name="user cancel" />
          <Label>{"Blocks"}</Label>
        </Menu.Item>
        <Menu.Item as={Link} to={ClientRoutes.PostSearch} active={location?.pathname === ClientRoutes.PostSearch}>
          <Icon name="address card outline" />
          <Label>{"Post Search"}</Label>
        </Menu.Item>
        <Menu.Item as={Link} to={ClientRoutes.Search} active={location?.pathname === ClientRoutes.Search}>
          <Icon name="search" />
          <Label>{"Search"}</Label>
        </Menu.Item>
        <Menu.Item as={Link} to={ClientRoutes.Shares} active={location?.pathname === ClientRoutes.Shares}>
          <Icon name="share square outline" />
          <Label>{"Shares"}</Label>
        </Menu.Item>
        <Menu.Item as={Link} to={ClientRoutes.Reports} active={location?.pathname === ClientRoutes.Reports}>
          <Icon name="sticky note outline" />
          <Label>{"Report Reasons"}</Label>
        </Menu.Item>
        <Menu.Item as={Link} to={ClientRoutes.UserReports} active={location?.pathname === ClientRoutes.UserReports}>
          <Icon name="sticky note outline" />
          <Label>{"User Reports"}</Label>
        </Menu.Item>
        <Menu.Item as={Link} to={ClientRoutes.Metrics} active={location?.pathname === ClientRoutes.Metrics}>
          <Icon name="chart line" />
          <Label>{"Metrics"}</Label>
        </Menu.Item>
        <Menu.Item as={Link} to={ClientRoutes.Events} active={location?.pathname === ClientRoutes.Events}>
          <Icon name="calendar outline" />
          <Label>{"Events"}</Label>
        </Menu.Item>
        <Menu.Item as={Link} to={ClientRoutes.EventSearch} active={location?.pathname === ClientRoutes.EventSearch}>
          <Icon name="calendar outline" />
          <Label>{"Event Search"}</Label>
        </Menu.Item>
        <Menu.Item as={Link} to={ClientRoutes.Concierge} active={location?.pathname === ClientRoutes.Concierge}>
          <Icon name="key" />
          <Label>{"New Users"}</Label>
        </Menu.Item>
        <Menu.Item as={Link} to={ClientRoutes.ConciergeChat} active={location?.pathname === ClientRoutes.ConciergeChat}>
          <Icon name="chat" />
          <Label>{"Concierge Chat"}</Label>
        </Menu.Item>
        <Menu.Item as={Link} to={ClientRoutes.StylePilot} active={location?.pathname === ClientRoutes.StylePilot}>
          <Icon name="plane" />
          <Label>
            <Label>{"Style Pilot"}</Label>
          </Label>
        </Menu.Item>
      </Menu>
      <Toaster />

      <UserCounter />

    </Grid.Column>
  )
};

const Badge = () => {
  // const [count, setCount] = useState(0);

  // useEffect(() => {
  //   getSuspendedUserCount().then(r => setCount(r.data.count));
  // }, []);

  const {suspendedCount} = useContext(UserCountContext);

  return <div
    style={{
      width: "16pt",
      height: "16pt",
      borderRadius: "8pt",
      backgroundColor: suspendedCount > 0 ? "red" : "grey",
      position: "absolute",
      color: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      right: 0,
      top: 0,
    }}
    >
      {suspendedCount}
      </div>;
};
