import { useState, useEffect, useCallback } from 'react';
import { IUser } from '../constants';
import { requestSearchUsers } from '../api/api';

/**
 * Helper to combine data from user and profile collections
 * @param userId user id
 */
export const useUser = (userId: string) => {
  const [user, setUser] = useState<IUser>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [refresh, setRefresh] = useState(false);

  const fetch = useCallback(async () => {
    try {
      const { data } = await requestSearchUsers(false, undefined, [userId]);
      if (data?.users?.length) {
        setUser(data?.users[0]);
        return;
      }

      setError(`Could not find user data for ${userId}`);

    } catch (e) {
      setError(`There was an error fetching user data for ${userId}`);
    } finally {
      setLoading(false);
    }
  }, [userId]);

  const refreshUser = () => setRefresh(true);

  useEffect(() => {
    fetch();
  }, [fetch, userId])

  useEffect(() => {
    if (refresh) {
      fetch();
      setRefresh(false);
    }
  }, [fetch, refresh]);


  return { user, loading, error, refreshUser };
}
