import { DomainObjects, PostAttachmentTypes } from "tonittypes";
import { colors, RichContent } from "../styles";

const attachmentColors = {
    [DomainObjects.Event]: colors.primary,
  };
  
export const Attachment = ({ attachment }:{attachment: any})  => {
  // override the accent color if needed
  // fallback to accent color provided
  // @ts-ignore
  const accentColor = attachmentColors[attachment.domainRef?.type] || attachment.accentColor;

  const updatedAttachment = {
    ...attachment,
    accentColor,
  };

  switch (attachment.contentType) {
    case PostAttachmentTypes.image_with_overlay_card: {
      return <ImageWithOverlay {...updatedAttachment} />;
    }
  //   case PostAttachmentTypes.text_segment: {
  //     return <TextSegment {...updatedAttachment} />;
  //   }
  //   case PostAttachmentTypes.actions: {
  //     return <Actions {...updatedAttachment} />;
  //   }
  //   case PostAttachmentTypes.status_bar: {
  //     return <StatusBar {...updatedAttachment} />;
  //   }
    default: {
      return null;
    }
  }
};

export const ImageWithOverlay = ({
  overlay, imageUrl
}: any) => {
  return (
    <>
      <img
        src={imageUrl || ""}
      />
      <RichContent>
        {formatTextElement(overlay.supertitle)}
      </RichContent>
      <RichContent>
        {formatTextElement(overlay.title)}
      </RichContent>
    </>
  );
};

const formatTextElement = ({ as, value }: any) => {
  if (as === "time") return new Date(value).toISOString();
  return value;
};
