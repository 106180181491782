import React, { Component } from "react";
import { IUser } from "../../constants";
import { Button, Segment, Grid, GridColumn, Container, Icon, Modal } from 'semantic-ui-react';
import "semantic-ui-css/semantic.min.css";
import { requestLinkProfiles } from "../../api/api";
import { AxiosResponse, AxiosError } from "axios";
import TimeAgo from "react-timeago";
import { ToastOptions } from "react-toastify";

interface IProps {
  userA: IUser | null,
  userB: IUser | null,
  setProfileToCompare: (user: IUser | null) => void;
  makeToast: (text: string, options?: ToastOptions) => void;
  onLinkSuccess: (user: IUser) => void;
}

const topBarStyles = {
  display: "flex",
  flex: "0 1 auto",
  flexDirection: "row",
  alignItems: "center",
  height: "auto",
  margin: 10,
}

const textStyles = {
  margin: 0,
  paddingLeft: 20,
}

const dividedSegmentStyles = {
  display: "flex",
  flexDirection: "row",
  margin: 10,
}

const columnContainer = {
  display: "flex",
  flex: 0,
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}

const iconColumnContainer = {
  display: "flex",
  flex: 0,
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
}
export class CompareProfile extends Component<IProps, any> {

  state = {
    isModalOpen: false,
  }

  public goBack() {
    this.props.setProfileToCompare(null);
  }

  public constructor(props: any) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.toggleConfirmationModal = this.toggleConfirmationModal.bind(this);
    this.linkProfiles = this.linkProfiles.bind(this);
  }

  public toggleConfirmationModal() {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  }

  public async linkProfiles() {
    const { userA, userB, setProfileToCompare, makeToast, onLinkSuccess } = this.props;
    try {
      if (userA && userB) {
        const response: AxiosResponse<any> = await requestLinkProfiles(userA._id, userB._id);
        const newUser: IUser = { email: userA.email, idp: userA.idp, profile: userB.profile, _id: userA._id };
        setProfileToCompare(null);
        onLinkSuccess(newUser);
        makeToast(`Successfully completed account link on ${response.data.user.email}`, {type:"success"})
      }
    } catch (error) {
      if (error.isAxiosError) {
        const apiError = error as AxiosError<{error: Error}>;
        if(apiError.response) return makeToast(apiError.response.data.error.message, {type: "error"});
        
        return makeToast("Unknown error occured", {type: "error"});
      } else {
        console.error(error);
        return makeToast(`Unknown error occured: ${error}`, {type: "error"});
      }
    }
  }

  public render() {
    const { userA, userB } = this.props;

    if (!userA || !userB) {
      return null
    }
    return (
      <>
        <Segment raised style={topBarStyles}>
          <Button icon='arrow left' onClick={this.goBack} />
          <h1 style={textStyles}>{"Link Profiles"}</h1>
        </Segment>
        <Segment compact raised style={dividedSegmentStyles}>
          <Container style={columnContainer}>
            <Segment color={"green"} compact>
              <GridColumn>
                <Grid.Row><h2>UserA</h2></Grid.Row>
                <Grid.Row><h3>{`Id:  ${userA._id}`}</h3></Grid.Row>
                <Grid.Row><h3>{`Email:  ${userA.email}`}</h3></Grid.Row>
                <Grid.Row><h3>{`IDP:  ${userA.idp}`}</h3></Grid.Row>
              </GridColumn>
            </Segment>
            <Segment color={"red"} compact>
              <GridColumn>
                <Grid.Row><h2>UserB</h2></Grid.Row>
                <Grid.Row><h3>{`Id:  ${userB._id}`}</h3></Grid.Row>
                <Grid.Row><h3>{`Email:  ${userB.email}`}</h3></Grid.Row>
                <Grid.Row><h3>{`IDP:  ${userB.idp}`}</h3></Grid.Row>
              </GridColumn>
            </Segment>
          </Container>
          <Container style={iconColumnContainer}>
            <Icon color={"green"} size={"huge"} name={"long arrow alternate right"} />
            <Icon color={"red"} size={"huge"} name={"user delete"} />
          </Container>
          <Container style={columnContainer}>
            <Segment color={"green"} compact>
              <GridColumn>
                <Grid.Row><h2>ProfileB</h2></Grid.Row>
                <Grid.Row><h3>{`Id:  ${userB.profile._id}`}</h3></Grid.Row>
                <Grid.Row><h3>{`UserName:  ${userB.profile.username}`}</h3></Grid.Row>
                <Grid.Row><h3>{`Joined:  ${new Date(userB.profile.createdAt).toISOString().split("T")[0]}`}</h3></Grid.Row>
                <Grid.Row><h3>{`Deleted:  ${userB.profile.isDeleted}`}</h3></Grid.Row>
              </GridColumn>
            </Segment>
            <Segment color={"red"} compact>
              <GridColumn>
                <Grid.Row><h2>ProfileA</h2></Grid.Row>
                <Grid.Row><h3>{`Id:  ${userA.profile._id}`}</h3></Grid.Row>
                <Grid.Row><h3>{`UserName:  ${userA.profile.username}`}</h3></Grid.Row>
                <Grid.Row><h3>{`Joined:  ${new Date(userA.profile.createdAt).toISOString().split("T")[0]}`}</h3></Grid.Row>
                <Grid.Row><h3>{`Deleted:  ${userA.profile.isDeleted}`}</h3></Grid.Row>
              </GridColumn>
            </Segment>
          </Container>
        </Segment>
        <Segment compact raised style={dividedSegmentStyles}>
          <Button onClick={this.toggleConfirmationModal}>{"Link these Accounts"}</Button>
        </Segment>
        <Modal
          open={this.state.isModalOpen}
          onClose={this.toggleConfirmationModal}
          closeIcon
        >
          <Modal.Content>{"This will link"}</Modal.Content>
          <Modal.Content>{`User ${userA.email}: idp=${userA.idp} -> profile ${userA.profile._id}, created `} <TimeAgo date={userA.profile.createdAt} /></Modal.Content>
          <Modal.Content>{"and"}</Modal.Content>
          <Modal.Content>{`User ${userB.email}: idp=${userB.idp} -> profile ${userB.profile._id}, created `} <TimeAgo date={userB.profile.createdAt} /></Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                this.linkProfiles();
                this.toggleConfirmationModal();
              }}
              content={"Accept"}
            />
            <Button
              onClick={this.toggleConfirmationModal}
              content={"Decline"}
            />
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}