import React, { FC, useCallback, useEffect, useState } from "react";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import ReactTimeago from "react-timeago";
import { ToastOptions } from "react-toastify";
import { Grid, Label, Segment } from "semantic-ui-react";
import { fetchBlocks } from "../../api/api";
import { ClientRoutes, getRouteToDetail } from "../../constants/routes";
import { DeContent, PlainContent, Row, Text, Title, Username } from "../feeds/styles";
import { DetailsSegment } from "../profilePage/DetailsSegment";
import Search from "../Search";

interface IProps extends RouteComponentProps {
  makeToast: (text: string, options?: ToastOptions) => void;
};

export const Blocks = ({ makeToast, location, history }: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [blocks, setBlocks] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);

  const { push } = useHistory();

  const getQueryParam = useCallback((key: string): string => {
    if (!location?.search) return "";

    const params = new URLSearchParams(location?.search);
    return params.get(key) || "";
  }, [location]);

  const onSearch = useCallback(async (searchString: string) => {
    try {
      setIsLoading(true);
      history.push(
        {
          pathname: location.pathname,
          search: `?search=${searchString}`
        }
      );

      setUserId(searchString);
      const params = searchString
        ? { source: searchString, sink: searchString }
        : {}
      const docs = await fetchBlocks(params);

      setBlocks((docs?.data?.data?.blocks || []).filter(b => b?.sink?._id && b?.source?._id));
      setCount(docs?.data?.data?.count);
    } catch (e) {
      makeToast(`Invalid user id "${e.response?.data?.error?.message?.toString()}`, { type: "error" });
    } finally {
      setIsLoading(false);
    }
  }, [history, location.pathname, makeToast]);

  const handleViewProfileClick = async (id: string) => {
    push(getRouteToDetail(ClientRoutes.Users, id));
  }

  useEffect(() => {
    onSearch(getQueryParam("search"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div style={{
    backgroundColor: "transparent",
  }}>

    <Row style={{
      width: "100%",
      marginBottom: "16pt",
      flexDirection: "column" as const,
      flexWrap: "nowrap" as const,
      justifyContent: "center",
    }}>

      <Row>
        <Label horizontal size="large" pointing="right">
          {"filter by user"}
        </Label>

        <Search
          onSearch={onSearch}
          isLoading={isLoading}
          defaultValue={getQueryParam("search")}
        />

      </Row>
      <Row>
        <Segment style={{ marginTop: "16pt" }}>
          <Title>
            {!isLoading
              ? (userId
                ? (blocks?.length && blocks[0]?.source?.username)
                || (blocks?.length && blocks[0]?.sink?.username)
                || ""
                : count ? `all blocks [${count}]` : "")
              : "checking..."}
          </Title>
        </Segment>
      </Row>
    </Row>

    <DetailsSegment accentColor="#ee4">
      {blocks.map(block => {
        const mutual = blocks.some(bb => bb.sink._id.toString() === block.source._id.toString()
          && bb.source._id.toString() === block.sink._id.toString());

        return <Grid.Row key={block?._id} columns={16} verticalAlign="middle">
          <Grid.Column verticalAlign="middle" width={1} textAlign="left">
            <Text>
              {block?.whichSource}
            </Text>
          </Grid.Column>
          <Grid.Column verticalAlign="middle" width={4} textAlign="left">
            <Username onClick={() => handleViewProfileClick(block.source?._id)}>
              {block.source?.username}
            </Username>
          </Grid.Column>
          <Grid.Column verticalAlign="middle" width={2} textAlign="left">
            <PlainContent>
              {"blocked"}
            </PlainContent>
          </Grid.Column>
          <Grid.Column verticalAlign="middle" width={1} textAlign="left">
            <Text>
              {block?.whichSink}
            </Text>
          </Grid.Column>
          <Grid.Column verticalAlign="middle" width={4} textAlign="left">
            <Username onClick={() => handleViewProfileClick(block.sink?._id)}>
              {block.sink?.username}
            </Username>
          </Grid.Column>
          <Grid.Column verticalAlign="middle" width={2} textAlign="left">
            <ReactTimeago date={block?.createdAt} />
          </Grid.Column>
          <Grid.Column verticalAlign="middle" width={1} textAlign="left">
            {mutual
              ? <PlainContent>
                {"Mutual"}
              </PlainContent>
              : null}
          </Grid.Column>
        </Grid.Row >
      })}
    </DetailsSegment>

    {!userId && (count - blocks?.length)
      ? <DeContent>
        {`${count - blocks?.length} not shown`}
      </DeContent>
      : null}
  </div>;
};

export const BlocksPage = withRouter<IProps, FC<IProps>>(Blocks);
