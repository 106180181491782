import { remove } from "lodash";
import { FC, useEffect, useMemo, useState } from "react";
import {
  Accordion,
  AccordionTitleProps,
  Button,
  Dropdown,
  Icon,
  Input,
  InputProps,
  Label,
  Modal,
  Popup,
  Segment,
  TextArea,
  TextAreaProps,
} from "semantic-ui-react";
import {
  ICheckpoint,
  CheckpointCondition,
  RelativeTimingType,
  CheckpointKeys,
} from "tonittypes";
import {
  addLaunchRailsConfig,
  removeLaunchRailsConfig,
} from "../../../api/api";
import { randomColor, camelToSentence } from "../../../utils";
import uuid from "../../../utils/uuid";
import { colors, Column, Row, SpecialContent } from "../../feeds/styles";
import { Hidden } from "../../shared";
import { makeToast } from "../../Toaster";
import { IConfigDoc } from "./NewUsersLanding";

export const LaunchRailsConfig = (props: {
  configs: IConfigDoc[];
  refresh: () => void;
  hasMore: boolean;
  next: () => void;
}) => {
  const [initialFormData, setInitialFormData] = useState();

  return (
    <div>
      <Hidden
        positive
        // Open the form if there's initial data to add
        controlledOpen={!!initialFormData}
        closeHint="close form"
        label="add rails config"
        renderClose={(button) => (
          <TopRight>{button({ positive: true })}</TopRight>
        )}
        renderOpen={(button, toggle) => {
          return (
            <div style={{ padding: "16pt" }}>
              <Row>
                <Segment style={{ marginTop: "16pt" }} color="green">
                  {button()}
                  <VariantSet
                    initialFormData={initialFormData}
                    onComplete={() => {
                      toggle();
                      props.refresh();
                    }}
                  />
                </Segment>
              </Row>
            </div>
          );
        }}
      />

      <DisplayVariantSet
        data={props.configs}
        setInitialFormData={setInitialFormData}
        hasMore={props.hasMore}
        more={props.next}
      />
    </div>
  );
};

const TopRight: FC = ({ children }) => (
  <div style={{ position: "absolute", top: 5, right: 25, zIndex: 100 }}>
    {children}
  </div>
);

const DisplayVariantSet = (props: {
  data: any[];
  hasMore: boolean;
  setInitialFormData: (data: any) => void;
  more: () => void;
}) => {
  //   const [noFilter, setNoFilter] = useState(true);

  const [filterOut, setFilterOut] = useState<string[]>([]);

  const [activeForm, setActiveForm] = useState<number>();

  if (!props.data.length) return null;

  const sets = props.data
    .filter((s) => !filterOut.some((n) => n === s.name))
    // .sort((a, b) => (a.variant < b.variant ? -1 : 1))
    .sort((a, b) => b.createdAt - a.createdAt);
  // .sort((a, b) => b.revision - a.revision);

  //   const topRevision = sets[0].revision;

  //   const topSet = sets.filter((s) => s.revision === topRevision);

  //   const configsGroupedByRevision = sets.reduce((acc, cur) => {
  //     const latestRevision = acc[acc.length - 1]?.[0]?.revision || topRevision;
  //     if (latestRevision === cur.revision) {
  //       return [
  //         ...acc.slice(0, acc.length - 1),
  //         [...(acc[acc.length - 1] || []), cur],
  //       ];
  //     } else {
  //       return [...acc, [cur]];
  //     }
  //   }, []) as IConfigDoc[][];

  //   const displaySets = noFilter ? configsGroupedByRevision : [topSet];

  const handleClick = (e: any, titleProps: AccordionTitleProps) => {
    const { index } = titleProps;
    const newIndex = activeForm === index ? -1 : (index as number);

    setActiveForm(newIndex);
  };

  const removeConfig = async (name: string) => {
    await removeLaunchRailsConfig(name);
    setFilterOut((s) => [...s, name]);
  };

  return (
    <>
      <Accordion>
        {sets.map((set, i) => {
          return (
            <>
              <Accordion.Title
                active={activeForm === i}
                index={i}
                onClick={handleClick}
              >
                <Icon name="dropdown" />
                <Title2>{`${set?.name}`}</Title2>
              </Accordion.Title>
              <Accordion.Content active={activeForm === i}>
                <Segment
                  secondary
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <TopRight>
                    <Dropdown
                      icon={<Icon name="ellipsis horizontal" color="grey" />}
                      direction="left"
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item
                          text="edit"
                          onClick={() => {
                            setActiveForm(undefined);
                            props.setInitialFormData(set);
                          }}
                        />
                        <Dropdown.Item
                          text="remove"
                          onClick={() => {
                            removeConfig(set.name);
                          }}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </TopRight>
                  {/* <Title2>{`Revision ${set[0]?.revision}`}</Title2> */}
                  {/* {set.map((c: any) => {
                    return <DisplayRevision {...c} />;
                  })} */}
                  <DisplayRevision {...set} />
                </Segment>
              </Accordion.Content>
            </>
          );
        })}
      </Accordion>
      <Button
        size="medium"
        secondary
        onClick={() => {
          //   setNoFilter(true);
          props.more();
        }}
        disabled={!props.hasMore}
      >
        {"see more configs"}
      </Button>
    </>
  );

  //   return (
  //     <>
  //       {displaySets.map((set) => {
  //         return (
  //           <Segment
  //             secondary
  //             style={{ display: "flex", flexDirection: "column" }}
  //           >
  //             <TopRight>
  //               <Dropdown
  //                 icon={<Icon name="ellipsis horizontal" color="grey" />}
  //                 direction="left"
  //               >
  //                 <Dropdown.Menu>
  //                   <Dropdown.Item
  //                     text="revise"
  //                     onClick={() => {
  //                       props.setInitialFormData(set);
  //                     }}
  //                   />
  //                 </Dropdown.Menu>
  //               </Dropdown>
  //             </TopRight>
  //             <Title2>{`Revision ${set[0]?.revision}`}</Title2>
  //             {set.map((c: any) => {
  //               return <DisplayRevision {...c} />;
  //             })}
  //           </Segment>
  //         );
  //       })}
  //       <Button
  //         size="medium"
  //         secondary
  //         onClick={() => setNoFilter(true)}
  //         disabled={noFilter}
  //       >
  //         {"see more revisions"}
  //       </Button>
  //     </>
  //   );
};

const Title2: FC<{}> = (props) => (
  <span
    style={{
      color: colors.primary,
      fontSize: "16pt",
      lineHeight: "1.2",
      paddingLeft: "8pt",
      paddingRight: "8pt",
      marginTop: "24pt",
      marginBottom: "16pt",
      alignSelf: "center",
    }}
  >
    {props.children}
  </span>
);

// const variantStringMap = {
//   a: "Baseline",
//   b: "Variant A",
//   c: "Variant B",
//   none: "none",
// };

const DisplayRevision = (props: IConfigDoc) => {
  const borderLeftColor = useMemo(randomColor, []);

  return (
    <Row style={{ paddingTop: "32pt", flexWrap: "wrap", width: "100%" }}>
      <Segment
        style={{
          display: "flex",
          flexDirection: "column",
          padding: 0,
          width: "100%",
        }}
        raised
        fluid
      >
        {/* <Title2>{variantStringMap[props.variant]}</Title2> */}
        <Title2>{props.name || `unnamed`}</Title2>

        <Row style={{ paddingTop: "8pt", marginLeft: "16pt" }}>
          <Popup
            content={"The name to identify this configuration"}
            trigger={<Icon name="info" size="small" color="grey" />}
          />
          <StepFormInput
            label="configuration name"
            disabled
            value={props.name || "unnamed"}
          />
        </Row>

        <Row style={{ paddingTop: "8pt", marginLeft: "16pt" }}>
          <Popup
            content={
              "Which profile should send the actions. Leave blank to use system default concierge profile"
            }
            trigger={<Icon name="info" size="small" color="grey" />}
          />
          <StepFormInput
            label="agent profile id"
            disabled
            value={props.agentProfileId || "system default concierge profile"}
          />
        </Row>

        {props.checkpoints.map((props: ICheckpoint, i: number) => {
          const [days, hours] = plusMsToHourDays(props.timing.plusMs);

          return (
            <Row style={{ paddingTop: "16pt" }}>
              <div
                style={{
                  paddingLeft: "8pt",
                  borderLeftColor,
                  borderLeftWidth: "2px",
                  borderLeftStyle: "solid",
                }}
              >
                <Row style={{ justifyContent: "space-between" }}>
                  <SpecialContent>
                    {`Step ${props.step}`.toUpperCase()}
                  </SpecialContent>
                </Row>

                <Row
                  style={{
                    paddingTop: "8pt",
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <StepFormInput
                    disabled
                    label="at"
                    // value={hours}
                    value={
                      fromDataToDisplay(hourOfTheDayOptions, hours) ||
                      `+${hours} hours`
                    }
                  />
                  <StepFormInput disabled reverse label="days" value={days} />

                  <StepFormInput
                    disabled
                    label="after"
                    value={fromDataToDisplay(
                      relativeToOptions,
                      props.timing.relativeTo
                    )}
                  />
                </Row>
                <Row style={{ paddingTop: "8pt" }}>
                  <StepFormInput
                    disabled
                    label="Tonit Concierge will"
                    value={fromDataToDisplay(keyOptions, props.key)}
                  />
                </Row>

                <CustomMessage
                  disabled
                  valueRoot={props?.custom}
                  actionKey={props.key}
                />

                {props.condition !== CheckpointCondition.ALWAYS ? (
                  <Row style={{ paddingTop: "8pt" }}>
                    <StepFormInput
                      disabled
                      label="only if"
                      value={props.condition}
                    />

                    <StepFormInput
                      disabled
                      label="else"
                      value={props.whenConditionFailed}
                    />
                  </Row>
                ) : null}
              </div>
            </Row>
          );
        })}
      </Segment>
    </Row>
  );
};

const formatFormData = (checkpoint: any): ICheckpoint => {
  return {
    ...checkpoint,
    timing: {
      plusHours: Number.parseFloat(checkpoint.plusHours),
      plusDays: Number.parseFloat(checkpoint.plusDays),
      plusMs:
        Number.parseFloat(checkpoint.plusHours) * 1000 * 60 * 60 +
        Number.parseFloat(checkpoint.plusDays) * 1000 * 60 * 60 * 24,
      relativeTo: checkpoint.relativeTo,
    },
    agentProfileId: checkpoint.agentProfileId,
    step: checkpoint.step,
    key: checkpoint.key,
    condition: checkpoint.condition,
    whenConditionFailed: checkpoint.whenConditionFailed,
  };
};

interface IFormState {
  id: string;
  key?: string;
  plusDays?: string;
  plusHours?: string;
  relativeTo?: string;
  step?: number;
  whenConditionFailed?: string;
  condition?: string;
}

const VariantSet = (props: {
  initialFormData: any;
  onComplete: () => void;
}) => {
  //   const getInitialFormData = (v: string, def?: any) =>
  //     props.initialFormData?.find?.((f: any) => f.variant === v)?.checkpoints ||
  //     def ||
  //     [];

  const [checkpointsA, setCheckpointsA] = useState<IFormState[]>(
    props.initialFormData?.checkpoints || [{ id: uuid() }]
  );
  //   const [checkpointsB, setCheckpointsB] = useState<IFormState[]>(
  //     getInitialFormData("b")
  //   );
  //   const [checkpointsC, setCheckpointsC] = useState<IFormState[]>(
  //     getInitialFormData("c")
  //   );

  const [agentProfileIdA, setAgentProfileIdA] = useState(
    props.initialFormData?.agentProfileId || ""
  );
  //   const [agentProfileIdB, setAgentProfileIdB] = useState("");
  //   const [agentProfileIdC, setAgentProfileIdC] = useState("");

  const [configurationName, setConfigurationName] = useState(
    props.initialFormData?.name || ""
  );

  const valid = useMemo(() => {
    const invalid = [...checkpointsA].some((c) => {
      return !(
        c.condition &&
        c.whenConditionFailed &&
        c.id &&
        c.key &&
        c.plusDays &&
        c.plusHours &&
        c.relativeTo &&
        c.step
      );
    });

    const agentProfilesCorrect = [
      agentProfileIdA,
      //   agentProfileIdB,
      //   agentProfileIdC,
    ].every((e) => {
      console.log(e);
      return e.length === 0 || e.length === 24;
    });

    return !invalid && agentProfilesCorrect && configurationName;
  }, [
    checkpointsA,
    // checkpointsB,
    // checkpointsC,
    agentProfileIdA,
    // agentProfileIdB,
    // agentProfileIdC,
    configurationName,
  ]);

  return (
    <div
      style={{
        paddingTop: "16pt",
      }}
    >
      <Segment
        style={{
          display: "flex",
          flexDirection: "column",
          padding: 0,
          width: "100%",
          paddingBottom: "16pt",
        }}
        raised
        fluid
      >
        <Title2>{configurationName}</Title2>

        <Row style={{ paddingTop: "8pt", marginLeft: "16pt" }}>
          <Popup
            content={"The name to identify this configuration"}
            trigger={<Icon name="info" size="small" color="grey" />}
          />
          <StepFormInput
            label="configuration name"
            placeholder={"required, unique"}
            callback={setConfigurationName}
            value={configurationName}
          />
        </Row>

        <Row style={{ paddingTop: "8pt", marginLeft: "16pt" }}>
          <Popup
            content={
              "Which profile should send the actions. Leave blank to use system default concierge profile"
            }
            trigger={<Icon name="info" size="small" color="grey" />}
          />
          <StepFormInput
            label="agent profile id"
            placeholder="default"
            callback={setAgentProfileIdA}
            value={agentProfileIdA}
          />
        </Row>

        <StepFormSet
          checkpoints={checkpointsA}
          setCheckpoints={setCheckpointsA}
          onDuplicate={() => {
            // setCheckpointsB(checkpointsA);
            // setCheckpointsC(checkpointsA);
          }}
        />
      </Segment>

      <Row style={{ paddingTop: "24pt" }}>
        <Button
          secondary
          disabled={!valid}
          positive
          onClick={() => {
            addLaunchRailsConfig({
              checkpoints: checkpointsA.map(formatFormData),
              agentProfileId: agentProfileIdA,
              name: configurationName,
              ...(props?.initialFormData?.id
                ? { id: props.initialFormData.id }
                : {}),
              // { variant: "b", checkpoints: checkpointsB.map(formatFormData) },
              // { variant: "c", checkpoints: checkpointsC.map(formatFormData) },
            })
              .then(() => {
                //   setCheckpointsA([{ id: uuid() }]);
                makeToast("✅", { type: "success" });
                props.onComplete();
              })
              .catch((e) => {
                makeToast(e.toString(), { type: "error" });
              });
          }}
        >
          {"save"}
        </Button>
      </Row>
    </div>
  );
};

const StepFormSet = ({
  checkpoints,
  setCheckpoints,
  onDuplicate,
}: {
  onDuplicate: () => void;
  checkpoints: IFormState[];
  setCheckpoints: React.Dispatch<React.SetStateAction<IFormState[]>>;
}) => {
  return (
    <div>
      <TopRight>
        <Dropdown
          icon={<Icon name="ellipsis horizontal" color="grey" />}
          direction="left"
        >
          <Dropdown.Menu>
            <Modal
              trigger={<Dropdown.Item text="duplicate" />}
              header="Heads Up"
              content="Duplicate this config to the other variants. This will overwrite any data in those variants."
              actions={[
                "cancel",
                {
                  key: "done",
                  content: "duplicate",
                  positive: true,
                  onClick: onDuplicate,
                },
              ]}
            />
          </Dropdown.Menu>
        </Dropdown>
      </TopRight>

      {checkpoints.map((step, i) => (
        <Row style={{ paddingTop: "36pt" }}>
          <StepForm
            key={step.id}
            canRemove={checkpoints?.length > 1}
            step={i + 1}
            onStateChange={(state: any) => {
              const thisIndex = checkpoints.findIndex((a) => a.id === step.id);
              setCheckpoints([
                ...checkpoints.slice(0, thisIndex),
                {
                  id: step.id,
                  step: thisIndex + 1,
                  ...checkpoints.find((a) => a.id === step.id),
                  ...state,
                },
                ...checkpoints.slice(thisIndex + 1),
              ]);
            }}
            onX={() =>
              setCheckpoints(checkpoints.filter((s) => s.id !== step.id))
            }
            state={checkpoints.find((s) => s.id === step.id)}
          />
        </Row>
      ))}

      <Row style={{ paddingTop: "16pt", marginLeft: "16pt" }}>
        <Button
          onClick={() => setCheckpoints([...checkpoints, { id: uuid() }])}
          size="small"
          primary
        >
          <Icon name="add circle" />
          {checkpoints.length > 0 ? "add another step" : "add first step"}
        </Button>
      </Row>
    </div>
  );
};

const fromDataToDisplay = (toDoOptions: typeof keyOptions, key: string) =>
  toDoOptions.find((t) => t.id === key)?.text;

const plusMsToHourDays = (plusMs: number) => {
  const overDay = plusMs % (1000 * 60 * 60 * 24);
  const days = ((plusMs - overDay) / 1000) * 60 * 60 * 24;
  const hours = overDay / (1000 * 60 * 60);

  return [days.toFixed(0), hours.toFixed(2)];
};

const toDoOptions = (arr: string[][]) =>
  arr.map((a) => ({ id: a[0], text: a[1], value: a[0] }));

const hourOfTheDayOptions = toDoOptions(
  Array.from(new Array(48)).map((_e, i) => {
    return [
      `${Math.floor(i / 2)}${i % 2 === 0 ? "" : ".5"}`,
      `${Math.floor(i / 2) % 12 || 12}:${i % 2 === 0 ? "00" : "30"} ${
        i > 23 ? "pm" : "am"
      } pst`,
    ];
  })
);

const relativeToOptions = toDoOptions([
  [RelativeTimingType.CREATED_AT, "account creation"],
  [RelativeTimingType.FIRST_MIDNIGHT, "first day of account"],
]);

const actionKeyMap = [
  [
    CheckpointKeys.CUSTOM_MESSAGE,
    "send a direct message",
    "messageTemplate",
    "Use {{username}} to put their username in the message",
  ],
  [CheckpointKeys.FOLLOW, "follow them"],
  [CheckpointKeys.LIKE_A_POST, "like one of their posts"],
  [
    CheckpointKeys.RECOMMEND_USER,
    "recommend a user to follow",
    "messageTemplate",
    "Use {{username}} to put their username in the message, and {{recommendationLink}} to add the link to the recommendation",
  ],
  [
    CheckpointKeys.RECOMMEND_CLUB,
    "recommend a club",
    "messageTemplate",
    "Use {{username}} to put their username in the message, and {{recommendationLink}} to add the link to the recommendation",
  ],
  [
    CheckpointKeys.RECOMMEND_EVENT,
    "recommend an event",
    "messageTemplate",
    "Use {{username}} to put their username in the message, and {{recommendationLink}} to add the link to the recommendation",
  ],
];

const keyOptions = toDoOptions(actionKeyMap);

const conditionOptions = toDoOptions([
  // ["always", "always"],
  [CheckpointCondition.NO_BIKE, "not yet added a bike"],
  [CheckpointCondition.NO_ACTIVITIES, "not yet tracked a ride"],
  [CheckpointCondition.NO_AVATAR, "not yet added a profile pic"],
  [CheckpointCondition.NO_CLUB_MEMBERSHIP, "not yet joined a club"],
  [CheckpointCondition.NO_EVENT_RSVPS, "not yet RSVPed for an event"],
  [CheckpointCondition.NO_LOCATION, "not yet added a location"],
  [CheckpointCondition.NO_POST, "not yet created a post"],

  [CheckpointCondition.HAS_BIKE, "already added a bike"],
  [CheckpointCondition.HAS_ACTIVITIES, "already tracked a ride"],
  [CheckpointCondition.HAS_AVATAR, "already added a profile pic"],
  [CheckpointCondition.HAS_CLUB_MEMBERSHIP, "already joined a club"],
  [CheckpointCondition.HAS_EVENT_RSVPS, "already RSVPed for an event"],
  [CheckpointCondition.HAS_LOCATION, "already added a location"],
  [CheckpointCondition.HAS_POST, "already created a post"],
]);

const whenConditionFailedOptions = toDoOptions([
  ["progress", "go to next step"],
  ["wait", "wait for next step"],
]);

const StepForm = (props: {
  step: number;
  onX: () => void;
  onStateChange: (state: any) => void;
  state: any;
  canRemove?: boolean;
}) => {
  // Set defaults for the form elements hidden by default
  useEffect(() => {
    props.onStateChange({
      condition: "always",
      whenConditionFailed: "wait",
    });
  }, []); // eslint-disable-line

  const borderLeftColor = useMemo(randomColor, []);

  return (
    <div
      style={{
        paddingLeft: "8pt",
        borderLeftColor,
        borderLeftWidth: "2px",
        borderLeftStyle: "solid",
      }}
    >
      <Row style={{ justifyContent: "space-between" }}>
        <SpecialContent>{`Step ${props.step}`.toUpperCase()}</SpecialContent>
        <Button
          disabled={!props.canRemove}
          onClick={props.onX}
          size="mini"
          color="orange"
          basic
        >
          <Icon name="close" />
          {"remove step"}
        </Button>
      </Row>

      <Row style={{ paddingTop: "8pt", marginLeft: "16pt" }}>
        <Popup
          content={"Overrides the agent id for this step only."}
          trigger={<Icon name="info" size="small" color="grey" />}
        />
        <StepFormInput
          label="override agent profile id"
          placeholder={"default"}
          callback={(pid: string) =>
            props.onStateChange({ agentProfileId: pid })
          }
          value={props.state.agentProfileId}
        />
      </Row>

      <Row style={{ paddingTop: "8pt", display: "flex", flexWrap: "wrap" }}>
        {props.state.relativeTo === RelativeTimingType.CREATED_AT ? (
          <StepFormInput
            reverse
            label="hours and"
            callback={(value: string) =>
              props.onStateChange({ plusHours: value })
            }
            value={props.state.plusHours}
          />
        ) : null}

        {props.state.relativeTo === RelativeTimingType.FIRST_MIDNIGHT ? (
          <StepFormDropdown
            label="at"
            options={hourOfTheDayOptions}
            callback={(value: string) =>
              props.onStateChange({ plusHours: value })
            }
            value={props.state.plusHours}
          />
        ) : null}

        <StepFormInput
          reverse
          label="days"
          callback={(value?: string | number) =>
            props.onStateChange({ plusDays: value })
          }
          value={props.state.plusDays}
        />

        <StepFormDropdown
          label="after"
          options={relativeToOptions}
          callback={(value: string) =>
            props.onStateChange({ relativeTo: value })
          }
          value={props.state.relativeTo}
        />
      </Row>
      <Row style={{ paddingTop: "8pt" }}>
        <StepFormDropdown
          label="Tonit Concierge will"
          options={keyOptions}
          callback={(value: string) => props.onStateChange({ key: value })}
          value={props.state.key}
        />
      </Row>

      <CustomMessage
        onStateChange={(o: object) => props.onStateChange({ custom: o })}
        valueRoot={props.state?.custom}
        actionKey={props.state.key}
      />

      {/* {actionKeyMap.find(k => props.state.key === k[0])?.[2]
      ? <Row style={{ paddingTop: "8pt" }}>
        <StepFormInput
          label={camelToSpace("custom message")}
          callback={(value: string) => props.onStateChange({ [actionKeyMap.find(k => props.state.key === k[0])?.[2] as string]: value })}
          value={props.state[actionKeyMap.find(k => props.state.key === k[0])?.[2] as string]}
        />
      </Row>
      : null} */}

      <Row style={{ paddingTop: "8pt" }}>
        <Hidden
          closeHint="remove condition"
          initialOpen={!!props.state.condition}
          label="add condition"
          positive={false}
          onHide={() => props.onStateChange({ condition: "always" })}
          renderClose={(button) => button()}
          renderOpen={(renderButton: any) => (
            <>
              <StepFormDropdown
                label="only if they have"
                options={conditionOptions}
                callback={(value: string) =>
                  props.onStateChange({ condition: value })
                }
                value={props.state.condition}
              />

              <StepFormDropdown
                label="else"
                options={whenConditionFailedOptions}
                callback={(value: string) =>
                  props.onStateChange({ whenConditionFailed: value })
                }
                value={props.state.whenConditionFailed}
              />
              <Column>{renderButton()}</Column>
            </>
          )}
        />
      </Row>
    </div>
  );
};

const CustomMessage = (props: {
  actionKey: string;
  onStateChange?: any;
  valueRoot: any;
  disabled?: boolean;
}) => {
  const thisCustomKey = actionKeyMap.find((k) => props.actionKey === k[0])?.[2];

  if (!thisCustomKey) return null;

  const tip = actionKeyMap.find((k) => props.actionKey === k[0])?.[3];

  return (
    <Row style={{ paddingTop: "8pt" }}>
      {tip ? (
        <Popup
          content={tip}
          trigger={<Icon name="info" size="small" color="grey" />}
        />
      ) : null}
      <StepFormTextArea
        disabled={props.disabled === true}
        style={{ width: "100%" }}
        rows={4}
        placeholder="Custom message"
        label={camelToSentence(thisCustomKey)}
        callback={(value?: string | number) =>
          props?.onStateChange?.({ [thisCustomKey]: value })
        }
        value={props.valueRoot?.[thisCustomKey]}
      />
    </Row>
  );
};

const FormElementWrap = (props: {
  render: () => JSX.Element;
  label: string;
  reverse?: boolean;
}) => {
  return (
    <>
      <Column>
        {props.reverse ? (
          props.render()
        ) : (
          <Label pointing="right">{props.label}</Label>
        )}
      </Column>
      <Column>
        {props.reverse ? (
          <Label pointing="left">{props.label}</Label>
        ) : (
          props.render()
        )}
      </Column>
    </>
  );
};

const StepFormDropdown = (props: {
  value: string;
  callback: (value: string) => void;
  options: any[];
  label: string;
  reverse?: boolean;
}) => {
  const { value, options, label, callback, ...rest } = props;
  return (
    <FormElementWrap
      render={() => (
        <Dropdown
          labeled
          selection
          value={value}
          options={options}
          // defaultValue={null}
          onChange={(event) => {
            callback(event.currentTarget.id);
          }}
        />
      )}
      label={label}
      {...rest}
    />
  );
};

const StepFormComponent =
  <P extends TextAreaProps | InputProps>(Wrapped: React.ComponentType<P>) =>
  (props: P) => {
    const { value, label, callback, ...rest } = props;
    return (
      <FormElementWrap
        render={() => (
          // @ts-ignore
          <Wrapped
            disabled={props.disabled === true}
            onChange={(_e: any, o: { value: string | number | undefined }) =>
              callback?.(o.value)
            }
            value={value}
            {...rest}
          />
        )}
        {...rest}
        label={label}
      />
    );
  };

const StepFormTextArea = StepFormComponent(TextArea);
const StepFormInput = StepFormComponent(Input);
