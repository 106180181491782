import algoliasearch from "algoliasearch";
import { AxiosError, AxiosResponse } from "axios";
import React, { Component, ComponentClass, FormEvent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ToastOptions } from "react-toastify";
import "semantic-ui-css/semantic.min.css";
import { Checkbox, CheckboxProps, Dropdown, Header, Icon, Label, LabelDetail } from "semantic-ui-react";
import { getSearchModes, requestSearchUsers } from "../api/api";
import * as config from "../config/config.json";
import { IUser } from "../constants";
import Search from "./Search";
import { UserCard, UserCardPlaceholder } from "./UserCard";

const centeredRowStyle = {
  marginBottom: "10px",
  display: "flex",
  flexDirection: "row" as "row",
  flexWrap: "nowrap" as "nowrap",
  justifyContent: "center",
  alignItems: "center"
};

const filterStyle = {
  ...centeredRowStyle,
  margin: "0 10px 10px",
};

interface IProps extends RouteComponentProps {
  setProfileToView?: (user: any) => void;
  filterProfileId?: string;
  makeToast: (text: string, options?: ToastOptions) => void;
};

interface IState {
  users: IUser[];
  isLoading: boolean;
  search: ISearchOptions;
  dropdownOptions: object[];
  searchModes: string[];
}

class UsersLandingComponent extends Component<IProps, IState> {
  ref: any;

  componentDidMount() {

    const search = this.getQueryParam("search");

    if (search) {
      this.search(search);
    }

    this.requestSearchModes();
  }

  /**
   * Optimistically add the email search mode.
   */
  state = {
    users: [],
    isLoading: false,
    search: {
      isDeleted: false,
      searchString: "",
      searchMode: "email",
    },
    dropdownOptions: [],
    searchModes: ["email"],
  };

  public constructor(props: any) {
    super(props);
    this.setIsLoading = this.setIsLoading.bind(this);
    this.search = this.search.bind(this)
    this.setSearchMode = this.setSearchMode.bind(this);
    this.searchUsers = this.searchUsers.bind(this);
    this.requestSearchModes = this.requestSearchModes.bind(this);
    this.parseDropdownOptions = this.parseDropdownOptions.bind(this);
  }

  public handleOptions(user: any) {
    console.log("clicked on user :", user.profile._id);
  }

  public async requestSearchModes() {
    const { makeToast } = this.props;
    try {
      const response = await getSearchModes();
      const searchModes: string[] = response.data.searchModes;
      const defaultSearchMode: string = searchModes[0];

      this.setState({
        searchModes: searchModes,
        search: { ...this.state.search, searchMode: defaultSearchMode }
      });
      this.parseDropdownOptions(response.data.searchModes);
    } catch (error) {
      if (error.isAxiosError) {
        const apiError = error as AxiosError<{ error: Error }>;
        if (
          apiError.response &&
          apiError.response.data &&
          apiError.response.data.error
        ) {
          return makeToast(apiError.response.data.error.message, { type: "error" });
        }
      } else {
        console.error(error);
        return makeToast(`Unknown error occured: ${error}`, { type: "error" });
      }
    }
  }

  public parseDropdownOptions(res: []) {
    const options = res.map((searchMode: string) => {
      return ({ text: searchMode, id: searchMode, value: searchMode })
    });
    this.setState({ dropdownOptions: options });
  }

  public render() {
    return (
      <div >
        <div style={centeredRowStyle}>
          <div style={filterStyle}>
            <Label horizontal pointing="right" size="large" >{"Deleted"}</Label>
            <Checkbox toggle onChange={this.isDeletedChange} />
          </div>
          <div style={filterStyle}>
            <Label horizontal size="large" pointing="right" style={{ zIndex: 1 }}>
              {"Search Mode"}
              <LabelDetail >
                <Dropdown
                  options={this.state.dropdownOptions}
                  defaultValue={this.state.search.searchMode}
                  onChange={async (event) => {
                    await this.setSearchMode(event.currentTarget.id);
                    this.search(this.state.search.searchString);
                  }}
                />
              </LabelDetail>
            </Label>
            <Search
              onSearch={this.search}
              isLoading={this.state.isLoading}
              defaultValue={this.getQueryParam("search")}
            />
          </div>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "space-around" }}>
          {this.buildCards()}
        </div>
      </div>
    );
  }

  private setSearchMode(mode: string) {
    this.setState({
      search: { ...this.state.search, searchMode: mode }
    });
  }

  private isDeletedChange = (event: FormEvent, data: CheckboxProps) => {
    this.setState({ search: { ...this.state.search, isDeleted: data.checked } }, () => this.searchUsers());
  }

  private search = (searchString: string) => {

    const { history, location } = this.props;
    history.push(
      {
        pathname: location.pathname,
        search: `?search=${searchString}`
      }
    );

    this.setState({ search: { ...this.state.search, searchString: searchString } }, () => this.searchUsers());
  }

  private setIsLoading(isLoading: boolean) {
    this.setState({ isLoading });
  }

  private buildCards() {
    const { isLoading, search, users } = this.state;
    const { filterProfileId } = this.props;

    if (isLoading) {
      return [...Array(6)].map(() => <UserCardPlaceholder />);
    }

    const userData = users.filter((u: IUser) => u && u._id !== filterProfileId);

    if (users.length === 0) {
      if (!search || (!search.searchString)) {
        return <Header as="h2" icon>
          <Icon name="rocket" />
          {"Users Search"}
          <Header.Subheader>{"Search for users to nuke"}</Header.Subheader>
        </Header>
      } else {
        return <Header as="h2" icon>
          <Icon name="blind" />
          {"No Results"}
          <Header.Subheader>{"No users found"}</Header.Subheader>
        </Header>
      }
    }

    return userData.map((user: any) => {

      if (filterProfileId && filterProfileId === user._id) {
        return null;
      }

      return (
        <UserCard
          key={user._id}
          user={user}
          setProfileToView={this.props.setProfileToView}
        />
      );
    });
  }

  private getQueryParam(key: string): string {
    const { location } = this.props;

    if (!location?.search) return "";

    const params = new URLSearchParams(location?.search);
    return params.get(key) || "";
  }

  private async searchUsers() {
    const search = this.state.search;
    const { makeToast } = this.props;

    if (!search || !search.searchString) return this.setState({ isLoading: false });
    this.setIsLoading(true);

    try {
      if (search.searchMode === "username" && !search.isDeleted) {

        const searchClient = algoliasearch(config.algolia.appId, config.algolia.apiKey);
        const index = searchClient.initIndex(config.algolia.profileIndex);
        const idArr: string[] = [];
        await index.search(search.searchString)
          .then(({ hits }) => {
            hits.map(p => idArr.push(p._id));
          })
          .catch((error) => {
            this.setState({ users: [] });
            makeToast(error.message, { type: "error" });
          })

        if (idArr && idArr.length >= 1) {
          const result: AxiosResponse<any> =
            await requestSearchUsers(this.state.search.isDeleted, search.searchString, idArr, search.searchMode);
          this.setState({ users: result.data.users });
        } else {
          this.setState({ users: [] });
        }

      } else {

        const result: AxiosResponse<any> | undefined =
          await requestSearchUsers(search.isDeleted, search.searchString, [], search.searchMode);
        this.setState({ users: result.data.users });

      }
    } catch (error) {
      this.setState({ users: [] })
      if (error.isAxiosError) {
        const apiError = error as AxiosError<{ error: Error }>;

        // No need to send notifications on every debounce with no users found
        if (apiError.response && apiError.response.status !== 404) {
          makeToast(apiError.response.data.error.message || "Unknown error occured", { type: "error" });
        }

      } else {
        console.error(error);
        makeToast(`Unknown error occured: ${error}`, { type: "error" });
      }
    }
    this.setState({ isLoading: false });
  }
}

interface ISearchOptions {
  searchString?: string;
  isDeleted?: boolean;
  searchMode?: string
}

export const UsersLanding = withRouter<IProps, ComponentClass<IProps>>(UsersLandingComponent);
