import uuid from "uuid/v4";
import { encode as btoa } from "base-64";

// converts a hex string to a base 64 string
const hexToBase64 = (hexstring: any) => {
  return btoa(hexstring.match(/\w{2}/g).map((a: any) => {
    return String.fromCharCode(parseInt(a, 16));
  }).join(""));
};

// generate a url safe uuid
// takes a uuid (36 characters) and converts it to a base64 string (21 characters)
// remove the = signs at the end of the string, there only used for padding
// then replaces the +/= symbols with ._- for more url friendly ids
const convert = () => {
  const id = uuid();
  const converted = hexToBase64(id);
  return converted
    .replace(/\+/g, "~")
    .replace(/\//g, "_")
    .replace(/=+$/, "")
    .replace(/=/g, "-");
};

export default convert;
