import { AxiosResponse } from "axios";
import * as config from "../config/config.json";
import { requestSearchUsers } from "../api/api";
import { SearchApiMode, UserSupportedSearchField } from "../constants/constants";
import { indexSearch } from "../api/api";

export const userSearch = (searchApiMode: SearchApiMode, searchField: string, ) => async (searchString: string, isDeleted: boolean) => {
  if (!searchString || !searchField) return;
  try {
    if (searchField === UserSupportedSearchField.USERNAME) {
      const users = await indexSearch(config.algolia.profileIndex, searchString, searchApiMode);
      if (!users?.length) return [];

      const idArray = users.map(u => u?._id);
      const result: AxiosResponse<any> = await requestSearchUsers(isDeleted, searchString, idArray, searchField);
      return result?.data?.users;

    } else {
      const result: AxiosResponse<any> = await requestSearchUsers(isDeleted, searchString, [], searchField);
      return result?.data?.users;
    }
  } catch (e) {
    console.log(`There was an error: ${e}`);
  }
};
