import React from 'react';
import "semantic-ui-css/semantic.min.css";
import { IClub } from 'tonittypes';
import { DetailsSegment } from "../../profilePage/DetailsSegment";

interface IProps {
  club: IClub;
}

export const ClubDetails = (props: IProps) => {
  if (!props || !props.club) return null;

  return <DetailsSegment
    title={"Club details"}
    accentColor="#113399"
    data={[
      { label: "Owner", value: props.club.owner.userName},
      { label: "ID", value: props.club._id},
      { label: "Owner ID", value: props.club.owner.userId || ""},
      { label: "Description", value: props.club.description || ""},
      { label: "Rules", value: props.club.rules || ""},
      { label: "Member count", value: props.club.memberCount || ""},
      { label: "Location", value: props.club.location?.humanized || "No location set" },
      { label: "Private", value: props.club.isPrivate === true },
      { label: "Archived", value: props.club.archived === true }
    ]}
  />
}