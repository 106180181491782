import React, { FC } from "react";
import TimeAgo from "react-timeago";

interface IFlexProps {
  style?: any;
}

export const colors = {
  // primary: "#d7596f",
  primary: "#385170",
  // primary2: "#b2798f",
  primary2: "#9fd3c7",
  // secondary: "#8c8cac",
  secondary: "#5f9387",
  tertiary: "#ccccdc",
  tertiary2: "#dfdfff",
  tertiary3: "#fcfcff",
};

export const Row: FC<IFlexProps> = props => <div style={{
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  ...(props.style || {}),
}}>
  {props.children}
</div>;

export const Column: FC<IFlexProps> = props => <div style={{
  display: "flex",
  flexDirection: "column",
  ...(props.style || {}),
}}>
  {props.children}
</div>;

export const Username: FC<{ onClick?: any, maxWidth?: number }> = (props) => <p
  onClick={props.onClick}
  style={{
    color: colors.primary,
    fontFamily: "Crete Round",
    cursor: "pointer",
    fontSize: "12pt",
    paddingLeft: "8pt",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: `${props.maxWidth || 128}pt`,
    whiteSpace: "nowrap",
  }}>
  {props.children}
</p>;

export const Time: FC<{ date: any }> = (props) => <div>
  <TimeAgo
    // @ts-ignore
    style={{
      color: colors.tertiary,
      fontSize: "8pt",
      paddingLeft: "8pt",
    }}
    date={props.date}
  />
</div>;

export const ExtraDeContent: FC<{}> = (props) => <span
  style={{
    color: colors.tertiary,
    fontSize: "8pt",
    paddingLeft: "8pt",
    paddingRight: "8pt",
  }}>{props.children}</span>;

export const Title: FC<{}> = (props) => <span
  style={{
    color: colors.primary,
    fontFamily: "Crete Round",
    fontSize: "32pt",
    lineHeight: "1.2",
    paddingLeft: "8pt",
    paddingRight: "8pt",
    marginTop: "24pt",
    marginBottom: "16pt",
  }}>{props.children}</span>;

export const Title2: FC<{}> = (props) => <span
  style={{
    color: colors.primary,
    fontFamily: "Crete Round",
    fontSize: "16pt",
    lineHeight: "1.2",
    paddingLeft: "8pt",
    paddingRight: "8pt",
    marginTop: "24pt",
    marginBottom: "16pt",
  }}>{props.children}</span>;

export const PlainContent: FC<{}> = (props) => <span
  style={{
    color: colors.secondary,
    fontSize: "11pt",
  }}>{props.children}</span>;

export const SpecialContent: FC<{}> = (props) => <span
  style={{
    color: colors.primary2,
    fontSize: "11pt",
  }}>{props.children}</span>;

export const Text: FC = ({ children }) => <p style={{
  paddingLeft: "8pt",
  paddingRight: "8pt",
  paddingTop: "8pt",
  paddingBottom: "8pt",
}}>
  {children}
</p>

export const Label: FC = ({ children }) => <p style={{
  // paddingLeft: "8pt",
  // paddingRight: "8pt",
  // paddingTop: "8pt",
  // paddingBottom: "8pt",
  fontFamily: "Crete Round",
  fontSize: "12pt",
  color: "#132743",
}}>
  {children}
</p>

export const RichContent: FC<{}> = ({ children }) => {
  if (typeof children !== "string") return null;

  const guess = new RegExp("([#|@][a-zA-Z0-9]+)", "g");
  const tokens = children?.split(guess);

  return <Text>{tokens.map((t, i) => {
    const Component = guess.test(t) ? SpecialContent : PlainContent;
    return <Component key={`${t}${i}`}>{t}</Component>;
  })}</Text>;
}

export const DeContent: FC<{}> = (props) => <p
  style={{
    color: colors.tertiary,
    fontSize: "9pt",
    paddingTop: "8pt",
    paddingLeft: "8pt",
    paddingRight: "8pt",
  }}>{props.children}</p>;
