import firebase from "firebase";
import React, { Component, useContext, useState } from "react";
import "semantic-ui-css/semantic.min.css";
import {
  Button,
  Confirm,
  Divider,
  Grid,
  GridColumn,
  Segment,
} from "semantic-ui-react";
import {
  markAllMessagesAsRead,
  resolveSuspension,
  suspendUser,
} from "../../api/api";
import { IUser } from "../../constants";
import { UserCountContext } from "../../contexts/suspendedUsers.context";
import { ButtonWithConfirm } from "../shared";

interface IProps {
  isDeleted: boolean;
  toggleProfileLinkModal: () => void;
  sendResetPasswordEmail: () => void;
  deleteUser: () => void;
  restoreUser: () => void;
  toggleChangeEmailModal: () => void;
  getPasswordResetLink: () => void;
  goToBlocks: () => void;
  watchUser: () => void;
  user: IUser;
  makeToast: (message: string) => void;
  clearPostlistCache: () => void;
  stopLaunchRails: () => void;
  isAutoSuspended?: boolean;
}

export const ProfileActions = (props: IProps) => {
  const {
    isDeleted,
    toggleProfileLinkModal,
    sendResetPasswordEmail,
    toggleChangeEmailModal,
    deleteUser,
    restoreUser,
    getPasswordResetLink,
    goToBlocks,
    watchUser,
    user,
    makeToast,
    clearPostlistCache,
    stopLaunchRails,
    isAutoSuspended,
  } = props;

  const { incSuspendedCount } = useContext(UserCountContext);

  if (!user) {
    return null;
  }

  if (!user.profile) {
    return (
      <Segment>
        <Grid columns={1} divided>
          <GridColumn>
            <Button
              icon="mail"
              content="Email Password Reset"
              onClick={sendResetPasswordEmail}
            />
          </GridColumn>
        </Grid>
      </Segment>
    );
  }

  return (
    <Segment>
      <Grid columns={4} divided>
        <GridColumn>
          <Button
            primary
            disabled={isDeleted || isAutoSuspended}
            icon="trash"
            content="Delete"
            onClick={deleteUser}
          />
          <Divider />
          <Button
            primary
            disabled={!isDeleted || isAutoSuspended}
            icon="undo"
            content="Restore"
            onClick={restoreUser}
          />
          <Divider />
          <Button
            primary
            disabled={!(isDeleted && isAutoSuspended)}
            icon="trash"
            content="Delete from Suspension"
            onClick={() => {
              resolveSuspension(user.profile._id?.toString(), false).then(
                () => {
                  makeToast("success");
                  incSuspendedCount(-1);
                }
              );
            }}
          />
          <Divider />
          <Button
            primary
            disabled={!(isDeleted && isAutoSuspended)}
            icon="undo"
            content="Restore from Suspension"
            onClick={() => {
              resolveSuspension(user.profile._id?.toString(), true).then(() => {
                makeToast("success");
                incSuspendedCount(-1);
              });
            }}
          />
        </GridColumn>
        <GridColumn>
          <Button
            primary
            icon="mail"
            content="Email Password Reset"
            onClick={sendResetPasswordEmail}
          />
          <Divider />
          <Button
            primary
            icon="linkify"
            content="Link to Another Profile"
            onClick={toggleProfileLinkModal}
          />
          <Divider />
          <Button
            primary
            icon="trash"
            content="Clear Post List Cache"
            onClick={clearPostlistCache}
          />
          <Divider />
          <Button primary icon="eye" content="Watch" onClick={watchUser} />
        </GridColumn>
        <GridColumn>
          <Button
            primary
            icon="exchange"
            content="Change Email"
            onClick={toggleChangeEmailModal}
          />
          <Divider />
          <Button
            primary
            icon="unlock"
            content="Get Password Reset Link"
            onClick={getPasswordResetLink}
          />
          <Divider />
          <ButtonWithConfirm
            primary
            disabled={user.profile.isAutoSuspended}
            icon="trash"
            content="Suspend"
            onConfirm={() => {
                suspendUser(user.profile._id?.toString()).then(() => {
                  makeToast("success");
                  incSuspendedCount(1);
              });
            }}
            promptMessage={
              "This will mark the user as suspended and delete their account"
            }
          />
        </GridColumn>
        <GridColumn>
          <ButtonWithConfirm
            primary
            icon="eye"
            content="Read All Notifications"
            onConfirm={() =>
              readAllUnreadNotification(user.profile._id, makeToast)
            }
            promptMessage={
              "This will mark up to 250 of this user's notifications as read. Are you sure?"
            }
          />
          <Divider />
          <Button
            primary
            icon="eye"
            content="View Blocks"
            onClick={goToBlocks}
          />
        </GridColumn>
        <GridColumn>
          <Button
            primary
            icon="stop"
            content="Stop Launch Rails"
            onClick={stopLaunchRails}
          />
        </GridColumn>
        <GridColumn>
          <Button
            primary
            icon="chat"
            content="Mark All Messages as Read"
            onClick={() => markAllMessagesAsRead(user.profile._id)}
          />
        </GridColumn>
      </Grid>
    </Segment>
  );
};

/**
 * TODO: this is a hack to help users with 1000s of unread notifications
 * who can't use their notifications screen anymore.
 *
 * This can be replaced with dispatching the new read all notifications action.
 */
const readAllUnreadNotification = async (
  uid: string,
  logger: (message: string, type: any) => void
) => {
  try {
    const notifications = await firebase
      .firestore()
      .collection("notifications")
      .where("recipientId", "==", uid)
      .where("isViewed", "==", false)
      .orderBy("createdAt", "desc")
      .limit(250)
      .get();

    if (notifications.empty) {
      logger("Couldn't find any notifications for this user", {
        type: "error",
      });
      return;
    }

    const notificationDocuments = notifications.docs;

    console.log(notificationDocuments);

    let batch = firebase.firestore().batch();

    await Promise.all(
      notificationDocuments.map(async (notifDoc, i) => {
        const notif = notifDoc.data();

        const ref = firebase
          .firestore()
          .collection("notifications")
          .doc(notif.id);
        batch.update(ref, { isViewed: true });

        if (i > 499) {
          await batch.commit();
          batch = firebase.firestore().batch();
        }
      })
    );

    await batch.commit();

    logger("Reading notifications complete", { type: "success" });
  } catch (error) {
    logger(error.message, { type: "error" });
  }
};
