import React, { useState, useCallback, useEffect } from 'react';
import "semantic-ui-css/semantic.min.css";
import { ToastOptions } from "react-toastify";
import { IClub, ReportTypes } from 'tonittypes';
import { DetailsSegment } from "../../profilePage/DetailsSegment";
import { requestClubReports } from '../../../api/api';

interface IProps {
  club: IClub;
  makeToast: (text: string, options?: ToastOptions) => void;
}
interface IReportObject {
  isDeleted: boolean;
  _id: string;
  reportedId: string;
  userId: string;
  type: ReportTypes;
  reason: string;
}

interface IData {
  label: string;
  value: string;
}

export const ClubReportDetails = ({ club, makeToast }: IProps) => {
  const [reports, setReports] = useState<IReportObject[]>([]);

  const requestReports = useCallback(async () => {
    if (!club?._id) return;
    try {
      const reports = await requestClubReports(club._id)
      return setReports(reports.data.reports)
    } catch (e) {
      makeToast(`${e}`, { type: "error" });
    };
  }, [makeToast, club]);

  const reportData = reports.reduce((acc: any, curr: IReportObject) => {
    return [...acc.filter((d: IData) => d.label !== curr.reason),
    {
      label: curr.reason,
      value: (acc.find((d: IData) => d.label === curr.reason)?.value || 0) + 1,
    }
    ]
  }, [])

  useEffect(() => {
    requestReports();
  }, [requestReports]);

  return <DetailsSegment
    title={"Reports"}
    accentColor="#FF007F"
    data={reportData} />;
};
