import React, { FC, useState, useEffect, ChangeEvent } from "react";
import { ToastOptions } from 'react-toastify';
import {
  Header,
  Divider,
  Table,
  Container,
  Form,
  Button,
  Modal,
  Checkbox,
  Segment,
  List,
  Dropdown,
  SemanticCOLORS,
} from "semantic-ui-react";
import { fetchReportedForReasons, updateReportedForReasons } from "../../api/api";
import { DomainObjects } from "tonittypes";

export interface IReasons {
  key: string;
  description: string;
  disabled: boolean;
  entityType: string;
}

interface IProps {
  makeToast: (text: string, options?: ToastOptions) => void;
}

export const ReportReasonsList: FC<IProps> = ({ makeToast }) => {
  const [{ keyInput, descriptionInput }, setInput] = useState({ keyInput: "", descriptionInput: "" });
  const [reasons, setReasons] = useState<{ [k: string]: IReasons }>({});
  const [toggle, setToggleModal] = useState(false);
  const [editingValue, setIsEditing] = useState(null)
  const [isDisabled, setIsDisabled] = useState(false);
  const [entityType, setEntityType] = useState("");

  const defaultType = {
    text: "Type",
    id: "type",
    value: "Type",
  };

  const Types = [
    // @ts-ignore
    DomainObjects.Profile,
    // @ts-ignore
    DomainObjects.Post,
    // @ts-ignore
    DomainObjects.Comment,
    // @ts-ignore
    DomainObjects.Club,
  ];

  const typeOptions = [
    defaultType, ...Types.map((t) => {
      return {
        text: t,
        id: t,
        value: t,
      }
    })
  ];

  const coloredTypes = (type: DomainObjects) => {
    const colors: SemanticCOLORS[] = [
      "pink",
      "purple",
      "violet",
      "blue",
      "teal",
      "green",
      "olive",
      "yellow",
      "orange",
      "red",
      "brown",
      "grey",
      "black",
    ]
    return colors[(Types.indexOf(type)) % colors.length] || "grey"
  }

  useEffect(() => {
    fetchReasons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!toggle) {
      setInput({ keyInput: "", descriptionInput: "" })
      setIsEditing(null);
      setIsDisabled(false);
    }
  }, [toggle])

  const fetchReasons = async () => {
    try {
      const fetchList = await fetchReportedForReasons();
      const reasonsObject = fetchList.data.reportReasons.reduce((obj: any, item: any) => {
        obj[item.key] = item;
        return obj;
      }, {});
      return setReasons(reasonsObject);
    } catch (e) {
      makeToast(`Oops: ${e}`, { type: "error" });
    }
  }

  const updateReasons = async () => {
    if (!entityType || entityType === "Type") {
      makeToast("Please select a type from the drop down menu 🦙", { type: "error" });
      return;
    }
    try {
      await updateReportedForReasons({ key: keyInput, description: descriptionInput, disabled: isDisabled, entityType });
      makeToast("You did it!", { type: "success" });
      makeToast("Changes are immediately available to the app and users");
    } catch (e) {
      makeToast(`${e}`, { type: "error" });
    }
    setInput({ keyInput: "", descriptionInput: "" });
    setToggleModal(false);
    return fetchReasons();
  };

  const editReason = (reason: any) => {
    setInput({ keyInput: reason.key, descriptionInput: reason.description });
    setEntityType(reason.domainType)
    setIsDisabled(reason.disabled);
    setIsEditing(reason);
    setToggleModal(true);
  };

  const handleInputChange = (data: any) => {
    return setInput(state => ({ ...state, [data.name]: data.value }));
  };

  const handleTypeChange = (data: any) => {
    return setEntityType(data.value);
  }

  const addNewReason = () => {
    if (Object.keys(reasons).includes(keyInput)) {
      makeToast("Could not add report reason, key already exists 🐸", { type: "error" });
      return;
    }
    if (!entityType || entityType === typeOptions[0].value) {
      makeToast("Please select a type from the drop down menu 🦙", { type: "error" });
      return;
    }
    return updateReasons();
  }

  const renderReason = (reason: IReasons) => {
    return (
      <Table.Row key={reason.key}>
        <Table.Cell>
          <Header size="tiny" disabled={reason.disabled}>{reason.entityType}</Header>
        </Table.Cell>
        <Table.Cell>
          <Header size="tiny" disabled={reason.disabled}>{reason.key}</Header>
        </Table.Cell>
        <Table.Cell disabled={reason.disabled}>{reason.description}</Table.Cell>
        <Table.Cell>
          <Button icon="pencil" color={"teal"} onClick={() => editReason(reason)} />
        </Table.Cell>
      </Table.Row>
    )
  }

  const ToggleableModal = (reasons: any, toggle: boolean) => {
    if (!reasons) return;

    return (
      <Modal open={toggle}>
        <Modal.Header content="Update reason preferences" />
        <div style={{ display: "flex", flex: "1", justifyContent: "center", margin: 10 }}>
          <Form onSubmit={updateReasons}>
            <Form.Group widths={"equal"} style={{ alignItems: "center" }}>
              <Form.Field name="keyInput" value={reasons.key}><strong>{reasons.key}</strong></Form.Field>
              <Form.Input name="descriptionInput" value={descriptionInput} onChange={(e: ChangeEvent, data: any) => {
                handleInputChange(data);
              }} />
              <Form.Field>
                <Checkbox toggle checked={isDisabled} onChange={() => setIsDisabled(!isDisabled)} label="disabled" />
              </Form.Field>
              <Form.Field>
                <Dropdown
                  compact
                  selection
                  options={typeOptions}
                  defaultValue={entityType}
                  onChange={(e, data) => handleTypeChange(data)}
                />
              </Form.Field>
              <Button type="submit" color={"pink"} disabled={!descriptionInput}>Save</Button>
              <Button color={"orange"} onClick={() => setToggleModal(false)}>Cancel</Button>
            </Form.Group>
          </Form>
        </div>
      </Modal>
    );
  };

  return <div>
    <Header>
      <Header>Report Reasons</Header>
      <Divider horizontal section />
      <p>Reportable offenses</p>
      <Segment><p>How to use:</p>
        <List ordered>
          <List.Item>
            <u>Type</u>: Type of the report will determine where, in the app, this reason shows up. Ex. "Post" type reasons will show up when you report a post, but will not show up as reasons for comments or profiles.
          </List.Item>
          <List.Item>
            <u>Unique Key</u>: It does not matter what the key is but it <i>must</i> be unique. Keys cannot be changed in the future
          </List.Item>
          <List.Item>
            <u>Description</u>: The description is what is shown to the user in app.  These can be duplicates for each type (ex. spam posts and spam profiles).  These can be updated at any time.
          </List.Item>
          <List.Item>
            <u>Disabled</u>: You cannot delete a report reason, but you can disable it (will appear greyed out).  This means it will not be an option in the app.
          </List.Item>
        </List>
      </Segment>
    </Header>

    <Divider horizontal section />

    <Container>
      {
        Object.keys(reasons)?.length
          ? Types.map((type: DomainObjects) => (
            < Table key={type} unstackable columns={4} compact color={coloredTypes(type)}>
              <Table.Header>
                <Table.Row key={type}>
                  <Table.HeaderCell singleLine>
                    <Header size="medium" disabled={false}>{"Type"}</Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell singleLine>
                    <Header size="medium" disabled={false}>{"Unique Key"}</Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell singleLine>
                    <Header size="medium" disabled={false}>{"Description"}</Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell singleLine>
                    <Header size="medium" disabled={false}>{"Edit"}</Header>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {Object.keys(reasons).map((r: string, i: number) => {
                  const currentReason = reasons[r];
                  // legacy profile report reasons have no type
                  if (!currentReason.entityType) {
                    renderReason({
                      ...currentReason,
                      // @ts-ignore
                      entityType: currentReason.entityType || DomainObjects.Profile,
                    });
                  }
                  if (currentReason.entityType !== type) {
                    return null;
                  }
                  return renderReason(currentReason);
                })}
              </Table.Body>

            </Table>

          ))
          : <Header>
            Add your first report reason! <span role="img" aria-label="bunny">🐰</span>
          </Header>
      }
    </Container >
    {ToggleableModal(editingValue, toggle)}

    < Divider horizontal section />

    {/* Add new reasons */}
    < h3 > Add new reportable offense</h3 >
    <Form onSubmit={addNewReason}>
      <Form.Group widths={"equal"}>
        <Form.Input
          label={"Key"}
          name="keyInput"
          value={keyInput}
          onChange={(e: ChangeEvent, data: any) => {
            handleInputChange(data);
          }} />
        <Form.Input
          label={"Description"}
          name="descriptionInput"
          value={descriptionInput}
          onChange={(e: ChangeEvent, data: any) => {
            handleInputChange(data);
          }} />
      </Form.Group>
      <Form.Field>
        <Dropdown
          compact
          selection
          options={typeOptions}
          defaultValue={"Type"}
          onChange={(e, data) => handleTypeChange(data)}
        />
      </Form.Field>
      <Form.Field>
        <Checkbox toggle onChange={() => setIsDisabled(!isDisabled)} label="Disabled" />
      </Form.Field>
      <Form.Button type={"submit"} color={'pink'} disabled={!keyInput || !descriptionInput}>
        {"Save"}
      </Form.Button>
    </Form>
  </div >;
};
