import React, { FC } from "react";
import { Button, Table, Message, Loader } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { makeToast } from "../../Toaster";
import { updateAffinities } from "../../../api/api";
import { useHistory } from "react-router-dom";

type EnteredAffinities = Record<string, number>;
export interface IRidingStyle {
  _id: string;
  style: string;
  description: string;
  enteredAffinities: EnteredAffinities;
}

interface IStylesProps {
  ridingStyles: IRidingStyle[];
}

export const RidingStyles: FC<IStylesProps> = ({ ridingStyles }) => {
  const { goBack } = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty, touchedFields, isSubmitting },
    reset,
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (formData: any) => {
    try {
      // we only need to update the dirty riding styles
      const dirtyRidingStyles = Object.entries(formData)
        .map(([key, value]) => ({
          id: key,
          entered_affinities: value as any,
        }))
        .filter((style) => touchedFields[style.id]);

      await updateAffinities({ ridingStyles: dirtyRidingStyles });

      makeToast("Style affinities updated", {
        type: "success",
        autoClose: 3000,
      });

      // reset the form to pristine state
      reset(formData);
    } catch (e) {
      console.log({ error: e.response });
      const errorMessage =
        e?.response?.data?.error ||
        "There was a problem saving, please try refreshing the page and trying again";
      // const errorMesage =
      makeToast(errorMessage, { type: "error" });
    }
  };

  if (!ridingStyles?.length) {
    return (
      <div style={{ padding: "50px 0", position: "relative" }}>
        <Loader active />
      </div>
    );
  }

  return (
    <form
      noValidate
      className="ui form"
      onSubmit={handleSubmit(onSubmit)}
      style={{ display: "block" }}
    >
      <Table compact>
        <Table.Body>
          {ridingStyles
            .sort((a, b) => a?.style.localeCompare(b?.style))
            .map((r) => (
              <Table.Row key={r._id}>
                <Table.Cell style={{ width: 200 }}>
                  <h4 style={{ fontWeight: "normal" }}>{r.style}</h4>
                </Table.Cell>
                {Object.entries(r?.enteredAffinities || {}).map(
                  ([key, value]) => {
                    // dot notation tells react hook form to group
                    const inputKey = `${r._id}.${key}`;
                    return (
                      <Table.Cell key={key} style={{ width: 130 }}>
                        <div
                          className={`field ${
                            errors[inputKey] && touchedFields[inputKey]
                              ? "error"
                              : ""
                          }`}
                          style={{ paddingRight: 5, paddingBottom: 5 }}
                        >
                          <label>{key}</label>
                          <input
                            {...register(inputKey as any, {
                              required: true,
                              min: -1,
                              max: 1,
                              valueAsNumber: true,
                            })}
                            style={{ width: "100%" }}
                            type="number"
                            placeholder="-1 to 1"
                            step="0.1"
                            min="-1"
                            max="1"
                            defaultValue={value ?? 0}
                          />
                        </div>
                      </Table.Cell>
                    );
                  }
                )}
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      <Message warning visible>
        <Message.Header>Frequent Update Warning</Message.Header>
        <p>
          Be mindful of changes made since it causes large updates to happen in
          the background.
        </p>
      </Message>
      <div style={{ textAlign: "right", marginTop: 20 }}>
        <Button
          type="button"
          style={{ marginRight: 10 }}
          onClick={() => goBack()}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          primary
          disabled={!isValid || !isDirty || isSubmitting}
        >
          {isSubmitting ? "Saving..." : "Update"}
        </Button>
      </div>
    </form>
  );
};
