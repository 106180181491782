import React, { Component } from "react";
import "semantic-ui-css/semantic.min.css";
import {
  Button,
  Grid,
  Header,
  Label,
  LabelDetail,
  Segment,
} from "semantic-ui-react";
import { camelToSpace, randomColor } from "../../utils";

export type DetailRowData = Array<{
  label: string;
  value: boolean | number | string | string[];
  link?: boolean;
}>;

interface IProps {
  title?: string;
  data?: DetailRowData;
  accentColor: string;
  subtitleLink?: string;
  loadMore?: () => void;
  renderInSegment?: () => JSX.Element;
}

const textStyles = {
  color: "#323",
  margin: "auto",
  paddingHorizontal: 20,
};

const labelTextStyles = {
  color: "#434",
  margin: "auto",
  paddingHorizontal: 20,
};

const booleanTrueTextStyle = {
  ...textStyles,
  color: "#339",
  fontFamily: "Menlo",
};

const booleanFalseTextStyle = {
  ...textStyles,
  color: "#4e0d0d",
  fontFamily: "Menlo",
};

const notFoundTextStyle = {
  ...textStyles,
  color: "#6666",
};

const numberTextStyle = {
  ...textStyles,
  fontFamily: "Menlo",
};

const dividedSegmentStyles = (stripeColor: string) => ({
  borderLeft: `4px solid ${stripeColor}`,
  backgroundColor: "#dddfdf",
});

export class DetailsSegment extends Component<IProps, any> {
  public constructor(props: any) {
    super(props);
  }

  public render() {
    const {
      title,
      data = [],
      accentColor: stripeColor,
      subtitleLink,
    } = this.props;

    return (
      <Segment style={dividedSegmentStyles(stripeColor)}>
        {title ? (
          <Label size="large" attached="top left">
            {`${title}`}
            {subtitleLink && (
              <LabelDetail
                size="tiny"
                as="a"
                onClick={() => alert(subtitleLink)}
                style={{ color: "#00fa" }}
              >
                {subtitleLink}
              </LabelDetail>
            )}
          </Label>
        ) : null}
        <Grid stackable divided="vertically">
          {data.map((d) => this.buildDetailsRow(d.label, d.value, d.link))}
          {this.props.children}
        </Grid>
        {this.props.renderInSegment && this.props.renderInSegment()}
        {this.props.loadMore && (
          <Button onClick={this.props.loadMore}>{"Load More"}</Button>
        )}
      </Segment>
    );
  }

  public buildDetailsRow(
    label: string,
    value: { toString: () => string },
    link?: boolean,
    onClick?: () => void
  ) {
    let style = textStyles;
    if (typeof value === "boolean" && value) style = booleanTrueTextStyle;
    if (typeof value === "boolean" && !value) style = booleanFalseTextStyle;
    if (typeof value === "number") style = numberTextStyle;

    const valueComponent =
      link === true ? (
        <Button
          as="a"
          onClick={onClick || (() => window.open(value.toString(), "_blank"))}
          style={style}
        >
          {`${value.toString().slice(0, 64)}...`}
        </Button>
      ) : (
        this.detailRowValueColumn(value, style)
      );

    // let valueComponent;

    // if (link === true) {
    //   valueComponent = <Button
    //     as="a"
    //     onClick={onClick || (() => window.open(value.toString(), "_blank"))}
    //     style={style}>
    //     {`${value.toString().slice(0, 64)}...`}</Button>;
    // } else if (Array.isArray(value)) {
    //   valueComponent = <Label.Group circular>
    //     {value.filter(f => f).map(v => <Label>{v.toISOString ? v.toISOString() : v.toString()}</Label>)}
    //   </Label.Group>;
    // } else if (value !== undefined && value !== "" && typeof value !== "object") {
    //   valueComponent = <Header as="h4" style={style}>{value.toString()}</Header>;
    // } else if (typeof value === "object" && value !== null) {
    //   valueComponent = <DetailsSegment
    //     key={valueComponent}
    //     accentColor={randomColor()}
    //     data={Object.entries(value)
    //       .map((d) => ({
    //         label: d[0],
    //         value: d[1] as any,
    //       }))}
    //   />;
    // } else {
    //   valueComponent = <Header as="h4" style={notFoundTextStyle}>{"unknown"}</Header>;
    // }

    return (
      <Grid.Row columns={16} key={label}>
        <Grid.Column verticalAlign="middle" width={5}>
          <Header as="h5" sub style={labelTextStyles}>
            {camelToSpace(label)}
          </Header>
        </Grid.Column>
        <Grid.Column width={11} textAlign="left">
          {valueComponent}
        </Grid.Column>
      </Grid.Row>
    );
  }

  private detailRowValueColumn(
    value: { toString: () => string } | string,
    style: object
  ) {
    if (Array.isArray(value)) {
      return (
        <Label.Group circular>
          {value
            .filter((f) => f)
            .map((v) => {
              return (
                <Label>{v.toISOString ? v.toISOString() : v.toString()}</Label>
              );
            })}
        </Label.Group>
      );
    }

    if (typeof value === "object" && value !== null) {
      return (
        <DetailsSegment
          // key={value}
          accentColor={randomColor()}
          data={Object.entries(value).map((d) => ({
            label: d[0],
            value: d[1] as any,
          }))}
        />
      );
    }

    if (value === undefined || value === "" || value === null) {
      return (
        <Header as="h4" style={notFoundTextStyle}>
          {"unknown"}
        </Header>
      );
    }

    return (
      <Header as="h4" style={style}>
        {value.toString()}
      </Header>
    );
  }
}
