import React from "react";
import { Segment, Card, Label } from "semantic-ui-react";
import { getRouteToDetail, ClientRoutes } from "../../constants/routes";
import { Link } from "react-router-dom";

interface IHashtagProps {
  hit: any;
}

export const Hashtag = (props: IHashtagProps) => {
  if (!props?.hit) {
    return <Segment>
      <Label>{`No Results`}</Label>
    </Segment>
  }

  const hashtag = props.hit;
  return <Card key={hashtag._id} style={{margin: 10}}>
    <Card.Content>
      <Segment>
        <Link to={getRouteToDetail(ClientRoutes.Feed, hashtag._id, { select: "hashtag", input: hashtag.hashtag })}>
          <Card.Header as="h2">{`#${hashtag.hashtag}`}</Card.Header>
        </Link>
        <Card.Description>{`Id: ${hashtag.objectID}`}</Card.Description>
        <Card.Description>{`Count: ${hashtag.count}`}</Card.Description>
      </Segment>
      <Segment>
        <Link to={getRouteToDetail(ClientRoutes.Feed, hashtag._id, { select: "hashtag", input: hashtag.hashtag })}>
          <Card.Header>{`#${hashtag.hashtag} feed`}</Card.Header>
        </Link>
      </Segment>
    </Card.Content>
  </Card >
}