import { useRef, useEffect } from "react";

const noop = () => null;

export const useInterval = (callback: any, delay: number, runOnLoad = false) => {
  const savedCallback = useRef(noop);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    if (runOnLoad) {
      savedCallback.current();
    }
  }, [runOnLoad]);

  // Set up the interval.
  useEffect(() => {
    if (delay !== null) {
      const id = setInterval(() => savedCallback.current(), delay);

      return () => clearInterval(id);
    }
    return noop;
  }, [delay]);
};
