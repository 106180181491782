import React from "react";
import staticProfilePic from "../../assets/generic-female-profile-picture.jpg" // Tell Webpack this JS file uses this image
import { Segment, Card, Image, Button, Label, Grid, Icon, Header } from "semantic-ui-react";
import { AccountTypes } from "tonittypes";
import { IUser } from "../../constants/index";
import { Link } from 'react-router-dom';
import { getRouteToDetail, ClientRoutes } from "../../constants/routes";
import TimeAgo from "react-timeago";

interface IRenderProps {
  hit: IUser;
}

export const UserCard = (props: IRenderProps) => {
  if (!props.hit || !props.hit.profile) { return <Segment><Label>No Results</Label></Segment> }

  const user = props.hit;
  const orderedBadges = AccountTypes.populate(user.profile.accountTypes)
    .sort((a, b) => b.priority - a.priority);

  return <Card key={user._id} style={{ margin: 10 }}>
    <Image
      fluid
      style={{ width: 290, height: 290 }}
      src={user?.profile?.avatarUrl || staticProfilePic}
      onError={(e: any) => e.target.src = staticProfilePic}
    />
    <Card.Content>
      <Segment>
        <Link to={getRouteToDetail(ClientRoutes.Users, user._id)}>
          <Card.Header as="h2">{user.profile.username}</Card.Header>
        </Link>
      </Segment>
      {orderedBadges.length ?
        <Segment>
          {
            orderedBadges.map(b => {
              return <Grid.Row key={b.title}>
                <Grid.Column>
                  <Icon
                    name="check circle"
                    key={b.priority}
                    size="small"
                    style={{
                      width: 15,
                      height: 15,
                      marginLeft: 5,
                      color: b.badge.color,
                    }} />
                </Grid.Column>
                <Grid.Column>
                  <Header as="h5">{b.title}</Header>
                </Grid.Column>
              </Grid.Row>
            })
          }
        </Segment>
        : null}
      <Card.Description>{user.email}</Card.Description>
      <Card.Meta>
        <span className='date'>{new Date(user.profile.createdAt).toISOString().split("T")[0]}</span>
        <TimeAgo date={user.profile.createdAt} />
      </Card.Meta>
    </Card.Content>
    <Card.Content extra>
      <Button>
        <Icon name='id badge' />
        {user.profile._id || "No id"}
      </Button>
    </Card.Content>
    <Card.Content extra>
      <Button>
        <Icon name='pin' />
        {user.profile?.location?.humanized || "Not set"}
      </Button>
    </Card.Content>
  </Card>
}

