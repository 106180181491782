import React, { useState, useCallback, FC, useEffect, useContext } from "react";
import { AccountTypeKeys, AccountTypes } from "tonittypes";
import { ToastOptions } from "react-toastify";
import { IUser } from "../../constants";
import { setAccountTypes } from "../../api/api";
import {
  Segment,
  Label,
  Grid,
  Header,
  Checkbox,
  Button,
} from "semantic-ui-react";
import { AdminContext } from "../../index";

interface IProps {
  user: IUser;
  makeToast: (text: string, options?: ToastOptions) => void;
  segmentColor: string;
}

export const AccountTypeDetails: FC<IProps> = ({
  user,
  makeToast,
  segmentColor,
}) => {
  const AdminProfile = useContext(AdminContext);
  const [initialTypes, setInitialTypes] = useState<any>([]);
  const [updatedTypes, setUpdatedTypes] = useState<any>([]);
  const [isDisabled, setDisabled] = useState(true);

  const Types = Object.values(AccountTypeKeys);

  const requestCurrentAccountTypes = useCallback(() => {
    try {
      setInitialTypes(user.profile.accountTypes);
      return setUpdatedTypes(user.profile.accountTypes);
    } catch (e) {
      return makeToast(`${e}`, { type: "error" });
    }
  }, [makeToast, user]);

  useEffect(() => {
    requestCurrentAccountTypes();
  }, [requestCurrentAccountTypes]);

  useEffect(() => {
    return setDisabled(AccountTypes.compare(initialTypes, updatedTypes));
  }, [initialTypes, updatedTypes]);

  const handleAccounTypeChange = (data: { checked?: boolean, value?: any }) => {
    if (data.checked) {
      const updatedArray = [...updatedTypes, data.value];
      setUpdatedTypes(updatedArray);
    }
    if (!data.checked) {
      // Let's make a special check for the case where you are changing your own admin status
      if (AdminProfile._id === user.profile._id) {
        alert("If you make yourself non-admin you will not be able to use this app anymore");
      }
      const newTypes = updatedTypes.filter(
        (v: AccountTypeKeys) => v !== data.value
      );
      setUpdatedTypes(newTypes);
    }
  };

  const submitTypes = async () => {
    try {
      await setAccountTypes(AdminProfile._id, user.profile._id, updatedTypes);
      makeToast(`Updated account types`, { type: "success" });
    } catch (e) {
      makeToast(`There was an error: ${e}`, { type: "error" });
    }
    return setDisabled(true);
  };

  const isChecked = (t: AccountTypeKeys) => {
    if (!updatedTypes?.length) return;
    return updatedTypes.some((i: AccountTypeKeys) => {
      return i === t;
    });
  };

  return (
    <Segment style={{ borderLeft: `4px solid ${segmentColor}` }}>
      <Label size="large" attached="top left">
        {`User account types`}
      </Label>
      <Grid stackable divided="vertically">
        {Types.map((t, i) => {
          return (
            <Grid.Row columns={16} key={i}>
              <Grid.Column verticalAlign="middle" width={6}>
                <Header as="h5" sub>
                  {t}
                </Header>
              </Grid.Column>
              <Grid.Column width={10}>
                <Checkbox
                  value={t}
                  checked={isChecked(t)}
                  toggle
                  onChange={(e, data) => handleAccounTypeChange(data)}
                />
              </Grid.Column>
            </Grid.Row>
          );
        })}
        <Grid.Row>
          <Grid.Column>
            <Button
              type="submit"
              disabled={isDisabled}
              onClick={() => submitTypes()}
              color={"pink"}
            >
              Update Types
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};
