import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useLocation, withRouter } from 'react-router-dom';
// @ts-ignore
import StackGrid from "react-stack-grid";
import { Button, Dropdown, Icon, Input, Label, LabelDetail, Loader, Segment } from "semantic-ui-react";
import { AdminContext } from '../..';
import { FeedKeys, feedMap, fetchFeed, IFeedMeta, namedFeeds, removeFavorite as apiRemoveFavorite, saveFavorite as apiSaveFavorite, savePost } from '../../api/api';
import { imageGroupConfig, ImageSource } from '../../constants/constants';
import Search from '../Search';
import PostItem from './posts/PostItem';
import { PlainContent, Row, Title } from './styles';

type Cursor = { before?: number; after?: number; limit?: number;feedKey?: string };

export const ApiFeedComponent = ({ fullscreen, makeToast }: any) => {
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location?.search), [location]);

  const AdminProfile = useContext(AdminContext);

  const history = useHistory();
  const [posts, setPosts] = useState<any>([]);
  const [cursor, setCursor] = useState<Cursor | Cursor[]>([]);
  const [hasMorePages, setHasMorePages] = useState(true);
  const [feedInput, setFeedInput] = useState(params.get("input") || namedFeeds[0]);
  const [feedSelect, setFeedSelect] = useState(params.get("select") as FeedKeys || FeedKeys.ROOT);
  const [isLoading, setIsLoading] = useState(false);
  const [isReloading, setIsReloading] = useState(true);
  const [feedMeta, setFeedMeta] = useState<IFeedMeta>({ title: namedFeeds[0] });
  const [removedFavorites, setRemovedFavorites] = useState<string[]>([]);

  const [postText, setPostText] = useState("");

  const grid = useRef<StackGrid>();

  useEffect(() => {
    // history.push(
    //   {
    //     pathname: `${location.pathname}`,
    //     search: `select=${feedSelect}&input=${feedInput}&cursor=${encodeURI(cursor.toString())}`
    //   }
    // );
  }, [history, feedSelect, feedInput, cursor, location.pathname])

  const dropdownOptions = useMemo(() =>
    Object.keys(feedMap).map(f => ({ text: f, value: f, id: f })),
    []);

  const requestNextPage = useCallback(() => {
    if (!isLoading && (hasMorePages || isReloading)) {
      setIsLoading(true);

      setIsReloading(false);

      const noPosts = posts.length === 0;

      const newCursor = Array.isArray(cursor)
        ? {
          cursor: cursor.map(c => ({
          after: noPosts ? Date.now() : c.after,
          //  before: noPosts ? Date.now() : undefined,
          limit: c.limit,
          feedKey: c.feedKey,
        }))}
        : {
          after: cursor.after,
          limit: cursor.limit,
        };
      fetchFeed(feedSelect)(feedInput, {...newCursor})
        .then(({ feed, meta }) => {
          console.log("META!", meta);
          setFeedMeta(meta);
          if (feed?.entities) {

            const newPosts = [
              ...posts,
              ...Object.values(feed.entities)
                // Bad time complexity, but protects against duplicating post sets
                .filter((p: any) => !posts.some((pp: any) => pp._id === p._id))
                .sort((a: any, b: any) => b?.publishedAt - a?.publishedAt),
            ];

            setPosts(newPosts);
          }

          if (feed?.cursors) setCursor(feed.cursors);
        })
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
          setIsReloading(false);
        });
    }
  }, [cursor, feedInput, feedSelect, hasMorePages, isReloading, posts, isLoading]);

  useEffect(() => {
    if (isReloading) {
      requestNextPage();
    }
  }, [isReloading, requestNextPage]);

  useEffect(() => {
    setHasMorePages(Array.isArray(cursor) ? cursor.some(c => c?.after) : !!cursor?.after);
  }, [cursor]);

  useEffect(() => {
    setPosts([]);
    setCursor({});
    setIsReloading(true);
  }, [feedInput, feedSelect]);

  const removeFavorite = async (postId: string) => {
    setRemovedFavorites((favs) => Array.from(new Set([...favs, postId])));
    await apiRemoveFavorite(postId);
  }
  
  const saveFavorite = async (postId: string) => {
    setRemovedFavorites((favs) => favs.filter(id => id !== postId));
    await apiSaveFavorite(postId);
    makeToast("Saved to your favorites feed");
  }

  const renderContext = (feed: FeedKeys, postId: string) => {    
    return (
      <Dropdown icon={<Icon name="ellipsis horizontal" color="grey" />} direction="left">
        <Dropdown.Menu>
            {
              feed === FeedKeys.FAVORITES // this is cool
                ? <Dropdown.Item text="Remove Favorite" onClick={() => removeFavorite(postId)} />
                : <Dropdown.Item text="Add Favorite" onClick={() => saveFavorite(postId)} />
            }
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  const favoritesFilter = (post: any) => feedSelect !== FeedKeys.FAVORITES || !removedFavorites.includes(post.id);

  return (
    <div style={{
      backgroundColor: "transparent",
    }}>

<Row>
          <Segment style={{ marginBottom: "16pt", zIndex: 1 }}>
            <Button onClick={() => {
              savePost({
                "type" : "posts",
                authorName: AdminProfile.username,
                authorIsAdmin: AdminProfile.isAdmin,
                accountTypes: AdminProfile.accountTypes,
                profilePhotoUrl: AdminProfile.profilePhotoUrl,
                data: {
                  "type" : "posts",
                  text: postText,
                },
              })
            }} />
            <Input onChange={(e, data) => setPostText(data.value)} />
          </Segment>
        </Row>

      <Row style={{
        width: "100%",
        marginBottom: "16pt",
        flexDirection: "column" as const,
        flexWrap: "nowrap" as const,
        justifyContent: "center",
        position: "relative",
        zIndex: 10,
      }}>
        <Row>
          <Segment style={{ marginBottom: "16pt", zIndex: 1 }}>
            <Title>
              {feedMeta?.title}
            </Title>
          </Segment>
        </Row>

        <Row>
          <Label horizontal size="large" pointing="right">
            {"go to feed"}
            <LabelDetail>
              <Dropdown
                style={{ zIndex: 10 }}
                options={dropdownOptions}
                defaultValue={feedSelect}
                floating
                onChange={(event) => {
                  setFeedSelect(event.currentTarget.id as FeedKeys);
                }}
              />
            </LabelDetail>
          </Label>

          <Search
            isLoading={isLoading}
            onSearch={setFeedInput}
            defaultValue={feedInput}
          />
<Button icon="refresh" onClick={() => {
  setPosts([]);
  setCursor([]);
  setIsReloading(true);
}} />
        </Row>
      </Row>

      {!fullscreen
        ? <InfiniteScroll
          scrollThreshold="200px"
          dataLength={posts.length} //This is important field to render the next data
          next={requestNextPage}
          hasMore={hasMorePages}
          endMessage={
            isLoading
              ? null
              : <Row style={{ justifyContent: "center" }}>
                <Segment>
                  <PlainContent>{posts?.length ? "no more posts" : "no posts"}</PlainContent>
                </Segment>
              </Row>
          }
          loader={
            <Loader active>{"Finding Posts..."}</Loader>
          }>
          {posts
            .filter(favoritesFilter)
            .map((post: any, index: number) => {
              return <PostItem
                imageConfig={imageGroupConfig.posts}
                maxWidth="512px"
                key={post._id}
                index={index}
                imageSource={ImageSource.feed}
                post={post}
                makeToast={makeToast}
                renderContext={() => renderContext(feedSelect, post.id)}
              />
            })}
        </InfiniteScroll>

        : <>
          <StackGrid
            gridRef={(grid: StackGrid) => grid.current = grid}
            monitorImagesLoaded
            columnWidth={500} >
            {posts
              .filter(favoritesFilter)
              .map((post: any) => {
                return <Segment basic key={post._id}>
                  <PostItem
                    imageConfig={imageGroupConfig.posts}
                    onImageLoad={() => grid.current && grid.current.updateLayout && grid.current.updateLayout()}
                    maxWidth="100%"
                    key={post._id}
                    imageSource={ImageSource.feed}
                    post={post}
                    makeToast={makeToast}
                    renderContext={() => renderContext(feedSelect, post.id)} 
                  />
                </Segment>
              })}
          </StackGrid>
          <Segment textAlign="center" basic>
            <Button icon="post" onClick={requestNextPage}>{"Load More"}</Button>
          </Segment>
        </>}
    </div>
  );
};

export const ApiFeed = withRouter<any, any>(ApiFeedComponent);
