import React, { useEffect, useState } from "react";
import { Button, Dropdown, Icon, Label, Segment, Tab } from "semantic-ui-react";
import {
  addLaunchRailsPlace,
  addLaunchRailsPlaceConfig,
  getAllGeoCircles,
  getLaunchRailsConfigs,
  removeLaunchRailsPlace,
  removeLaunchRailsPlaceConfig,
} from "../../api/api";
import ReactMapboxGl, { Marker, Cluster, Source, Layer } from "react-mapbox-gl";
import * as config from "../../config/config.json";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
import "rc-slider/assets/index.css";
import { useApiList } from "../../hooks/useApiList";
import {
  Column,
  DeContent,
  PlainContent,
  Row,
  SpecialContent,
  Title2,
} from "../feeds/styles";
import { IConfigDoc } from "../pages/newUsers/NewUsersLanding";
import { makeToast } from "../Toaster";

const clickInCalg = {
  plus_code: {
    compound_code: "2WXP+F3P Calgary, AB, Canada",
    global_code: "95372WXP+F3P",
  },
  results: [
    {
      address_components: [
        {
          long_name: "120",
          short_name: "120",
          types: ["street_number"],
        },
        {
          long_name: "5 Avenue Southwest",
          short_name: "5 Ave SW",
          types: ["route"],
        },
        {
          long_name: "Downtown",
          short_name: "Downtown",
          types: ["neighborhood", "political"],
        },
        {
          long_name: "Calgary",
          short_name: "Calgary",
          types: ["locality", "political"],
        },
        {
          long_name: "Alberta",
          short_name: "AB",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Canada",
          short_name: "CA",
          types: ["country", "political"],
        },
        {
          long_name: "T2P 5L3",
          short_name: "T2P 5L3",
          types: ["postal_code"],
        },
      ],
      formatted_address: "120 5 Ave SW, Calgary, AB T2P 5L3, Canada",
      geometry: {
        location: {
          lat: 51.0486705,
          lng: -114.0646576,
        },
        location_type: "ROOFTOP",
        viewport: {
          northeast: {
            lat: 51.0500194802915,
            lng: -114.0633086197085,
          },
          southwest: {
            lat: 51.0473215197085,
            lng: -114.0660065802915,
          },
        },
      },
      place_id: "ChIJu5AZHfxvcVMRhWRIbhDBEJc",
      plus_code: {
        compound_code: "2WXP+F4 Calgary, AB, Canada",
        global_code: "95372WXP+F4",
      },
      types: ["street_address"],
    },
    {
      address_components: [
        {
          long_name: "120",
          short_name: "120",
          types: ["subpremise"],
        },
        {
          long_name: "333",
          short_name: "333",
          types: ["street_number"],
        },
        {
          long_name: "5 Avenue Southwest",
          short_name: "5 Ave SW",
          types: ["route"],
        },
        {
          long_name: "Downtown",
          short_name: "Downtown",
          types: ["neighborhood", "political"],
        },
        {
          long_name: "Calgary",
          short_name: "Calgary",
          types: ["locality", "political"],
        },
        {
          long_name: "Alberta",
          short_name: "AB",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Canada",
          short_name: "CA",
          types: ["country", "political"],
        },
        {
          long_name: "T2P 5L3",
          short_name: "T2P 5L3",
          types: ["postal_code"],
        },
      ],
      formatted_address: "333 5 Ave SW #120, Calgary, AB T2P 5L3, Canada",
      geometry: {
        location: {
          lat: 51.048604,
          lng: -114.064662,
        },
        location_type: "ROOFTOP",
        viewport: {
          northeast: {
            lat: 51.0499529802915,
            lng: -114.0633130197085,
          },
          southwest: {
            lat: 51.0472550197085,
            lng: -114.0660109802915,
          },
        },
      },
      place_id: "ChIJu5AZHfxvcVMROixZdIxEBrs",
      plus_code: {
        compound_code: "2WXP+C4 Calgary, AB, Canada",
        global_code: "95372WXP+C4",
      },
      types: ["establishment", "point_of_interest"],
    },
    {
      address_components: [
        {
          long_name: "TransCanada Tower",
          short_name: "TransCanada Tower",
          types: ["premise"],
        },
        {
          long_name: "450",
          short_name: "450",
          types: ["street_number"],
        },
        {
          long_name: "1 Street Southwest",
          short_name: "1 St SW",
          types: ["route"],
        },
        {
          long_name: "Downtown",
          short_name: "Downtown",
          types: ["neighborhood", "political"],
        },
        {
          long_name: "Calgary",
          short_name: "Calgary",
          types: ["locality", "political"],
        },
        {
          long_name: "Alberta",
          short_name: "AB",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Canada",
          short_name: "CA",
          types: ["country", "political"],
        },
        {
          long_name: "T2P 3L8",
          short_name: "T2P 3L8",
          types: ["postal_code"],
        },
      ],
      formatted_address:
        "TransCanada Tower, 450 1 St SW, Calgary, AB T2P 3L8, Canada",
      geometry: {
        bounds: {
          northeast: {
            lat: 51.0492415,
            lng: -114.0642833,
          },
          southwest: {
            lat: 51.0486526,
            lng: -114.0650033,
          },
        },
        location: {
          lat: 51.048973,
          lng: -114.0646385,
        },
        location_type: "ROOFTOP",
        viewport: {
          northeast: {
            lat: 51.0502960302915,
            lng: -114.0632943197085,
          },
          southwest: {
            lat: 51.0475980697085,
            lng: -114.0659922802915,
          },
        },
      },
      place_id: "ChIJBUULAvxvcVMRER9iiAhe5yY",
      types: ["premise"],
    },
    {
      address_components: [
        {
          long_name: "EB 5 Av SW @ 1 St SW",
          short_name: "EB 5 Av SW @ 1 St SW",
          types: ["establishment", "point_of_interest", "transit_station"],
        },
        {
          long_name: "Downtown",
          short_name: "Downtown",
          types: ["neighborhood", "political"],
        },
        {
          long_name: "Calgary",
          short_name: "Calgary",
          types: ["locality", "political"],
        },
        {
          long_name: "Alberta",
          short_name: "AB",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Canada",
          short_name: "CA",
          types: ["country", "political"],
        },
        {
          long_name: "T2P 3Y6",
          short_name: "T2P 3Y6",
          types: ["postal_code"],
        },
      ],
      formatted_address: "EB 5 Av SW @ 1 St SW, Calgary, AB T2P 3Y6, Canada",
      geometry: {
        location: {
          lat: 51.048333,
          lng: -114.064585,
        },
        location_type: "GEOMETRIC_CENTER",
        viewport: {
          northeast: {
            lat: 51.0496819802915,
            lng: -114.0632360197085,
          },
          southwest: {
            lat: 51.0469840197085,
            lng: -114.0659339802915,
          },
        },
      },
      place_id: "ChIJ-wCbHvxvcVMRxMLoa3PnPLI",
      plus_code: {
        compound_code: "2WXP+85 Calgary, AB, Canada",
        global_code: "95372WXP+85",
      },
      types: ["establishment", "point_of_interest", "transit_station"],
    },
    {
      address_components: [
        {
          long_name: "474",
          short_name: "474",
          types: ["street_number"],
        },
        {
          long_name: "1 Street Southwest",
          short_name: "1 St SW",
          types: ["route"],
        },
        {
          long_name: "Downtown",
          short_name: "Downtown",
          types: ["neighborhood", "political"],
        },
        {
          long_name: "Calgary",
          short_name: "Calgary",
          types: ["locality", "political"],
        },
        {
          long_name: "Alberta",
          short_name: "AB",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Canada",
          short_name: "CA",
          types: ["country", "political"],
        },
        {
          long_name: "T2P 4K3",
          short_name: "T2P 4K3",
          types: ["postal_code"],
        },
      ],
      formatted_address: "474 1 St SW, Calgary, AB T2P 4K3, Canada",
      geometry: {
        location: {
          lat: 51.04868829999999,
          lng: -114.0652909,
        },
        location_type: "RANGE_INTERPOLATED",
        viewport: {
          northeast: {
            lat: 51.05003728029149,
            lng: -114.0639419197085,
          },
          southwest: {
            lat: 51.0473393197085,
            lng: -114.0666398802915,
          },
        },
      },
      place_id:
        "Eig0NzQgMSBTdCBTVywgQ2FsZ2FyeSwgQUIgVDJQIDRLMywgQ2FuYWRhIhsSGQoUChIJzylpBPxvcVMRGyw8Cu4xThsQ2gM",
      types: ["street_address"],
    },
    {
      address_components: [
        {
          long_name: "2WXP+F3",
          short_name: "2WXP+F3",
          types: ["plus_code"],
        },
        {
          long_name: "Calgary",
          short_name: "Calgary",
          types: ["locality", "political"],
        },
        {
          long_name: "Alberta",
          short_name: "AB",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Canada",
          short_name: "CA",
          types: ["country", "political"],
        },
      ],
      formatted_address: "2WXP+F3 Calgary, AB, Canada",
      geometry: {
        bounds: {
          northeast: {
            lat: 51.04875,
            lng: -114.06475,
          },
          southwest: {
            lat: 51.04862499999999,
            lng: -114.064875,
          },
        },
        location: {
          lat: 51.04870529999999,
          lng: -114.0647883,
        },
        location_type: "GEOMETRIC_CENTER",
        viewport: {
          northeast: {
            lat: 51.0500364802915,
            lng: -114.0634635197085,
          },
          southwest: {
            lat: 51.0473385197085,
            lng: -114.0661614802915,
          },
        },
      },
      place_id: "GhIJKFKr-TuGSUARdWrTfSWEXMA",
      plus_code: {
        compound_code: "2WXP+F3 Calgary, AB, Canada",
        global_code: "95372WXP+F3",
      },
      types: ["plus_code"],
    },
    {
      address_components: [
        {
          long_name: "100-198",
          short_name: "100-198",
          types: ["street_number"],
        },
        {
          long_name: "5 Avenue Southwest",
          short_name: "5 Ave SW",
          types: ["route"],
        },
        {
          long_name: "Downtown",
          short_name: "Downtown",
          types: ["neighborhood", "political"],
        },
        {
          long_name: "Calgary",
          short_name: "Calgary",
          types: ["locality", "political"],
        },
        {
          long_name: "Alberta",
          short_name: "AB",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Canada",
          short_name: "CA",
          types: ["country", "political"],
        },
        {
          long_name: "T2P",
          short_name: "T2P",
          types: ["postal_code", "postal_code_prefix"],
        },
      ],
      formatted_address: "100-198 5 Ave SW, Calgary, AB T2P, Canada",
      geometry: {
        bounds: {
          northeast: {
            lat: 51.04844019999999,
            lng: -114.0638826,
          },
          southwest: {
            lat: 51.0483987,
            lng: -114.065306,
          },
        },
        location: {
          lat: 51.04841949999999,
          lng: -114.0645943,
        },
        location_type: "GEOMETRIC_CENTER",
        viewport: {
          northeast: {
            lat: 51.04976843029149,
            lng: -114.0632453197085,
          },
          southwest: {
            lat: 51.0470704697085,
            lng: -114.0659432802915,
          },
        },
      },
      place_id: "ChIJ-xPXHfxvcVMRyvQXl9hN2jM",
      types: ["route"],
    },
    {
      address_components: [
        {
          long_name: "Downtown Commercial",
          short_name: "Downtown Commercial",
          types: ["neighborhood", "political"],
        },
        {
          long_name: "Calgary",
          short_name: "Calgary",
          types: ["locality", "political"],
        },
        {
          long_name: "Alberta",
          short_name: "AB",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Canada",
          short_name: "CA",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Downtown Commercial, Calgary, AB, Canada",
      geometry: {
        bounds: {
          northeast: {
            lat: 51.0508101,
            lng: -114.0554451,
          },
          southwest: {
            lat: 51.0438152,
            lng: -114.0839411,
          },
        },
        location: {
          lat: 51.0469379,
          lng: -114.0696002,
        },
        location_type: "APPROXIMATE",
        viewport: {
          northeast: {
            lat: 51.0508101,
            lng: -114.0554451,
          },
          southwest: {
            lat: 51.0438152,
            lng: -114.0839411,
          },
        },
      },
      place_id: "ChIJBW3SvfxvcVMRk3tntbxoWKw",
      types: ["neighborhood", "political"],
    },
    {
      address_components: [
        {
          long_name: "T2P",
          short_name: "T2P",
          types: ["postal_code", "postal_code_prefix"],
        },
        {
          long_name: "Calgary",
          short_name: "Calgary",
          types: ["locality", "political"],
        },
        {
          long_name: "Alberta",
          short_name: "AB",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Canada",
          short_name: "CA",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Calgary, AB T2P, Canada",
      geometry: {
        bounds: {
          northeast: {
            lat: 51.05734289999999,
            lng: -114.062643,
          },
          southwest: {
            lat: 51.0438209,
            lng: -114.0947078,
          },
        },
        location: {
          lat: 51.0508055,
          lng: -114.0709065,
        },
        location_type: "APPROXIMATE",
        viewport: {
          northeast: {
            lat: 51.05734289999999,
            lng: -114.062643,
          },
          southwest: {
            lat: 51.0438209,
            lng: -114.0947078,
          },
        },
      },
      place_id: "ChIJAfrpcuVvcVMRbONLGYhPG_k",
      types: ["postal_code", "postal_code_prefix"],
    },
    {
      address_components: [
        {
          long_name: "Downtown",
          short_name: "Downtown",
          types: ["neighborhood", "political"],
        },
        {
          long_name: "Calgary",
          short_name: "Calgary",
          types: ["locality", "political"],
        },
        {
          long_name: "Alberta",
          short_name: "AB",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Canada",
          short_name: "CA",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Downtown, Calgary, AB, Canada",
      geometry: {
        bounds: {
          northeast: {
            lat: 51.0544741,
            lng: -114.041154,
          },
          southwest: {
            lat: 51.04335400000001,
            lng: -114.094981,
          },
        },
        location: {
          lat: 51.0478108,
          lng: -114.0592618,
        },
        location_type: "APPROXIMATE",
        viewport: {
          northeast: {
            lat: 51.0544741,
            lng: -114.041154,
          },
          southwest: {
            lat: 51.04335400000001,
            lng: -114.094981,
          },
        },
      },
      place_id: "ChIJlwUghvtvcVMRHiTf5_EqxBA",
      types: ["neighborhood", "political"],
    },
    {
      address_components: [
        {
          long_name: "Calgary",
          short_name: "Calgary",
          types: ["locality", "political"],
        },
        {
          long_name: "Alberta",
          short_name: "AB",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Canada",
          short_name: "CA",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Calgary, AB, Canada",
      geometry: {
        bounds: {
          northeast: {
            lat: 51.2124247,
            lng: -113.8598962,
          },
          southwest: {
            lat: 50.8428219,
            lng: -114.3157738,
          },
        },
        location: {
          lat: 51.04473309999999,
          lng: -114.0718831,
        },
        location_type: "APPROXIMATE",
        viewport: {
          northeast: {
            lat: 51.2124247,
            lng: -113.8598962,
          },
          southwest: {
            lat: 50.8428219,
            lng: -114.3157738,
          },
        },
      },
      place_id: "ChIJ1T-EnwNwcVMROrZStrE7bSY",
      types: ["locality", "political"],
    },
    {
      address_components: [
        {
          long_name: "Alberta",
          short_name: "AB",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Canada",
          short_name: "CA",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Alberta, Canada",
      geometry: {
        bounds: {
          northeast: {
            lat: 60.00006209999999,
            lng: -109.9998551,
          },
          southwest: {
            lat: 48.9966671,
            lng: -120.0005219,
          },
        },
        location: {
          lat: 53.9332706,
          lng: -116.5765035,
        },
        location_type: "APPROXIMATE",
        viewport: {
          northeast: {
            lat: 60.00006209999999,
            lng: -109.9998551,
          },
          southwest: {
            lat: 48.9966671,
            lng: -120.0005219,
          },
        },
      },
      place_id: "ChIJtRkkqIKyCVMRno6bQJpHqbA",
      types: ["administrative_area_level_1", "political"],
    },
    {
      address_components: [
        {
          long_name: "Canada",
          short_name: "CA",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Canada",
      geometry: {
        bounds: {
          northeast: {
            lat: 83.6381,
            lng: -50.9766,
          },
          southwest: {
            lat: 41.6765559,
            lng: -141.00187,
          },
        },
        location: {
          lat: 56.130366,
          lng: -106.346771,
        },
        location_type: "APPROXIMATE",
        viewport: {
          northeast: {
            lat: 83.6381,
            lng: -50.9766,
          },
          southwest: {
            lat: 41.6765559,
            lng: -141.00187,
          },
        },
      },
      place_id: "ChIJ2WrMN9MDDUsRpY9Doiq3aJk",
      types: ["country", "political"],
    },
  ],
  status: "OK",
};

interface IClcik {
  plus_code: {
    compound_code: string;
    global_code: string;
  };
  results: {
    address_components: {
      long_name: string;
      short_name: string;
      types: string[];
    }[];
    formatted_address: string;
    geometry: {
      location: {
        lat: 56.130366;
        lng: -106.346771;
      };
      location_type: string;
      viewport: {
        northeast: {
          lat: 83.6381;
          lng: -50.9766;
        };
        southwest: {
          lat: 41.6765559;
          lng: -141.00187;
        };
      };
      bounds?: undefined;
    };
    place_id: string;
    types: string[];
    status: string;
  };
}

const reverseGeocodeToPlace = (result: typeof clickInCalg) => {
  const types = [
    "administrative_area_level_2",
    "administrative_area_level_1",
    "locality",
  ].reverse();

  for (let type of types) {
    const place = result.results.find((r) => r.types.includes(type));
    console.log("type:", type, result.results);
    if (place) return place;
  }
};

const reverseGeocode = (latitude?: number, longitude?: number) => {
  let params = {
    key: config.apiKey,
    latlng: `${latitude},${longitude}`,
  };

  let qs = `key=${encodeURIComponent(params.key)}&latlng=${encodeURIComponent(
    params.latlng
  )}`;

  return fetch(`https://maps.googleapis.com/maps/api/geocode/json?${qs}`)
    .then((res) => res.json())
    .then((json) => {
      if (json.status !== "OK") {
        throw new Error(`Geocode error: ${json.status}`);
      }
      return json;
    });
};

const metersStringFormatter = (meters: number) => {
  if (meters > 1000) {
    return `${(meters / 1000).toFixed(1)} km`;
  }

  return `${meters} m`;
};

// 2 digit alpha country codes for supported countries
// should probably mirror what countries we've released the app in
const SUPPORTED_COUNTRY_LIST = [
  "us",
  "ca",
  "mx",
  "co",
  "ag",
  "ar",
  "bs",
  "bz",
  "vg",
  "bm",
  "ky",
  "cl",
  "cr",
  "dm",
  "do",
  "ec",
  "sv",
  "gd",
  "gt",
  "hn",
  "jm",
  "ni",
  "pa",
  "py",
  "pe",
  "kn",
  "lc",
  "tt",
  "tc",
  "uy",
  "ve",
  "es",
  "bo",
];

export const MapPicker = (props: {
  configs: IConfigDoc[];
  refresh: () => void;
}) => {
  const [placesResponse, setPlacesResponse] = useState<any>();
  const [radiusMeters, setRadiusMeters] = useState<number>(10000);
  const [locationInProgress, setLocation] = useState<LngLat>();
  const [lipLabel, setLipLabel] = useState("");
  const [geocodeResult, setGeocodeResult] = useState<any>();

  // Get some address information for the label when the location updates
  useEffect(() => {
    if (locationInProgress?.[1] && locationInProgress?.[0] && !placesResponse) {
      reverseGeocode(locationInProgress?.[1], locationInProgress?.[0]).then(
        (res) => {
          console.log("reverse geocoded latlng:", res);

          const goodPlace = reverseGeocodeToPlace(res);

          // // Doesn't always come back with a formatted address
          // // Should be easy enough to make a label based on all the data that comes back...
          // const formatted = res?.results?.[0]?.address_components
          //     .filter((ac: any) => !ac.types?.some((type: string) => ["postal_code", "street_number"].includes(type)))
          //     .map((ac: any) => ac.short_name)
          //     .join(" ");
          console.log("goodPlace?.formatted_address:", goodPlace);
          if (goodPlace) {
            setLipLabel(goodPlace?.formatted_address!);
            setGeocodeResult(goodPlace);
          }
        }
      );
    }
  }, [locationInProgress, placesResponse]);

  useEffect(() => {
    if (placesResponse?.value?.place_id) {
      console.log("places response:", placesResponse);
      geocodeByPlaceId(placesResponse?.value?.place_id)
        .then((results) => {
          console.log("geocoded:", results);
          setGeocodeResult(results[0]);
          return getLatLng(results[0]);
        })
        .then(({ lat, lng }) => {
          setLocation([lng, lat]);
          setLipLabel(placesResponse.label);
        })
        .catch(console.error);
    }
  }, [placesResponse]);

  return (
    <>
      <Segment>
        <Label size="large" horizontal>
          {"Select place"}
        </Label>
        <GooglePlacesAutocomplete
          apiKey={config.apiKey}
          selectProps={{
            // value,
            onChange: setPlacesResponse,
          }}
          autocompletionRequest={{
            types: ["(regions)"],
            componentRestrictions: {
              country: SUPPORTED_COUNTRY_LIST,
            },
          }}
        />
      </Segment>

      {locationInProgress?.length ? (
        <>
          {/* <Segment>
            <Label size="large" horizontal>
              {"Radius"}
            </Label>
            <SliderWithTips
              onChange={onRadiusChange}
              min={1000}
              max={200000}
              step={100}
              tipFormatter={metersStringFormatter}
              startPoint={10000}
              defaultValue={10000}
              tipProps={{
                visible: true,
                placement: "bottom",
              }}
            />
          </Segment> */}

          <Button
            primary
            disabled={
              !(
                (placesResponse?.value?.place_id || geocodeResult?.place_id) &&
                lipLabel &&
                locationInProgress &&
                geocodeResult
              )
            }
            onClick={async () => {
              console.log(placesResponse, geocodeResult);
              await addLaunchRailsPlace({
                googlePlaceId:
                  placesResponse?.value?.place_id || geocodeResult?.place_id,
                radiusMeters,
                description: lipLabel,
                location: locationInProgress,
                geocode: geocodeResult,
              })
                .then(() => {
                  makeToast("✅", { type: "success", autoClose: 1000 });
                })
                .catch((error) => {
                  makeToast(error.toString(), { type: "error" });
                });
              setPlacesResponse(undefined);
              setLipLabel("");
              setLocation(undefined);
              props.refresh();
            }}
          >
            {"add place"}
          </Button>
        </>
      ) : null}

      <MapBox
        configs={props.configs}
        localCircle={{
          description: lipLabel,
          radiusMeters,
          location: { coordinates: locationInProgress, type: "Point" },
        }}
        onSelectLocation={(lngLat) => {
          console.log("select location:", lngLat);
          setPlacesResponse(undefined);
          setLipLabel(`${lngLat.lng.toFixed(3)} ${lngLat.lat.toFixed(3)}`);
          setLocation([lngLat.lng, lngLat.lat]);
        }}
      />
    </>
  );
};

interface ICircle {
  location?: { type: "Point"; coordinates?: LngLat };
  label?: string;
  radiusMeters?: number;
  description?: string;
  geocode: {
    address_components: {
      long_name: string;
      short_name: string;
      types: [Locations, string];
    }[];
    formatted_address: string;
    geometry: {
      bounds: {
        south: number;
        west: number;
        north: number;
        east: number;
      };
      location: { lat: number; lng: number };
      location_type: "APPROXIMATE";
      viewport: {
        south: number;
        west: number;
        north: number;
        east: number;
      };
    };
    place_id: string;
    types: [Locations, string];
  };
  googlePlaceId: string;
  placeConfig: any;
}

type LngLat = [number, number];

const accessToken = config.mapbox.accessToken;

const Map = ReactMapboxGl({
  accessToken,
});

const labelStyle = {
  fontSize: 16,
  overflow: "hidden",
  whiteSpace: "nowrap" as const,
};

interface IMapProps {
  localCircle?: Partial<ICircle>;
  onSelectLocation: (lngLat: { lng: number; lat: number }) => void;
  configs: IConfigDoc[];
}

const metersToPixelsAtMaxZoom = (meters?: number, latitude?: number) =>
  (meters ?? 20) / 0.075 / Math.cos(((latitude ?? 0) * Math.PI) / 180);

const interpolateZoom = (meters: number) => {
  // 1000 radius -> max zoom
  // 200,000 radius -> zoomed out
  const x = [1000, 200000];
  const y = [10, 4];
  return y[0] + (meters - x[0]) * ((y[1] - y[0]) / (x[1] - x[0]));
};

const MapBox = ({ localCircle, onSelectLocation, configs }: IMapProps) => {
  const { data: circles, refresh } = useApiList<ICircle>(getAllGeoCircles, {
    pageSize: 50,
    selfManaged: true,
  });

  useEffect(() => {
    console.log("api circles:", circles);
  }, [circles]);

  useEffect(() => {
    console.log("localCircle:", localCircle);
    if (!localCircle?.location?.coordinates) refresh();
  }, [localCircle, refresh]);

  const clusterMarker = (
    coordinates: number[],
    pointCount: number,
    getLeaves: (
      limit?: number,
      offset?: number
    ) => Array<React.ReactElement<any>>
  ) => {
    return (
      <CircleMarker
        label={`${pointCount}`}
        coordinates={coordinates}
        locationType={"country"}
      />
    );
  };

  // @ts-ignore
  return (
    <Tab
      panes={[
        {
          menuItem: "Map",
          render: () => (
            // @ts-ignore
            <Map
              // eslint-disable-next-line react/style-prop-object
              style="mapbox://styles/graemefunk/ckaya4h800hxw1jpfdmsagvy1"
              // center={localCircle?.location?.coordinates ?? [-119.4960106, 49.8879519]}
              center={
                localCircle?.location?.coordinates ??
                circles?.[0]?.location?.coordinates
              }
              onClick={(map, event) => {
                // @ts-ignore
                onSelectLocation(event?.lngLat);
              }}
              // Zoom levels
              // 0	The Earth
              // 3	A continent
              // 4	Large islands
              // 6	Large rivers
              // 10	Large roads
              // 15	Buildings
              zoom={[interpolateZoom(localCircle?.radiusMeters!) || 3]}
              containerStyle={{
                height: "85vh",
                width: "100%",
              }}
            >
              {localCircle?.location ? (
                <>
                  <Source
                    id="point"
                    geoJsonSource={{
                      type: "geojson",
                      data: {
                        type: "Point",
                        coordinates: localCircle.location,
                      },
                    }}
                  />
                  <Layer
                    id="point"
                    type="circle"
                    sourceId="point"
                    paint={{
                      "circle-radius": {
                        stops: [
                          [0, 0],
                          [
                            20,
                            metersToPixelsAtMaxZoom(
                              localCircle?.radiusMeters,
                              localCircle?.location?.coordinates?.[1]
                            ),
                          ],
                        ],
                        base: 2,
                      },
                      "circle-color": "#007cbf",
                      "circle-opacity": 0.5,
                    }}
                  />
                </>
              ) : null}

              <Cluster
                ClusterMarkerFactory={clusterMarker}
                radius={16}
                maxZoom={8}
              >
                {[...circles, localCircle]
                  .filter((circle) => circle?.location?.coordinates)
                  .map((circle) => ({
                    ...circle,
                    label: circle?.label || circle?.description,
                  }))
                  .filter((circle) => circle?.label)
                  .map((circle) => {
                    return (
                      <CircleMarker
                        label={circle?.label}
                        coordinates={circle!.location!.coordinates!}
                        locationType={circle.geocode?.types?.[0]}
                      />
                    );
                  })}
              </Cluster>
            </Map>
          ),
        },
        {
          menuItem: "List",
          render: () => (
            <CirclesList
              circles={circles}
              configs={configs}
              refresh={refresh}
            />
          ),
        },
      ]}
    />
  );
};

const hierarchy: Locations[] = [
  "country",
  "administrative_area_level_1",
  "administrative_area_level_2",
  "locality",
];

const circlesToTree = (
  circles: ICircle[],
  hierarchyLevel = 0,
  parentName = ""
) => {
  if (hierarchyLevel > hierarchy.length - 1) return { places: [] };

  const matchParent = (name?: string) =>
    name && (!parentName || name === parentName);

  const allAddressComponents = circles
    .filter((c) => c?.geocode?.address_components)
    .map((c) => c.geocode.address_components)
    .filter((c) => {
      if (hierarchyLevel === 0) return true;
      const parent = c.find(
        (cc) => cc.types[0] === hierarchy[hierarchyLevel - 1]
      );
      return matchParent(parent?.long_name);
    })
    .flat()
    .filter((c) => c.types[0] === hierarchy[hierarchyLevel]);

  const theseCircles = circles
    .filter((c) => c.geocode?.address_components?.[0])
    .filter((c) => {
      if (hierarchyLevel === 0) return true;
      const parent = c.geocode.address_components.find(
        (cc) => cc.types[0] === hierarchy[hierarchyLevel - 1]
      );
      return matchParent(parent?.long_name);
    })
    .map((c) => ({
      ...c.geocode.address_components[0], // A place IS its first address component
      placeId: c.googlePlaceId,
      placeConfig: c.placeConfig,
    }))
    .filter((c) => c.types?.[0] && c.types[0] === hierarchy[hierarchyLevel]);

  const combined = [...allAddressComponents, ...theseCircles];

  // Dedupe
  const addressComponents = combined.filter(
    (rr, i) =>
      !combined.some(
        (rrr, ii) =>
          ii > i &&
          rrr.long_name === rr.long_name &&
          rrr.types[0] === rr.types[0]
      )
  );

  //   const t = addressComponents.map((dd) => {
  //     const f = theseCircles.find(
  //       (cc) =>
  //         cc.geocode.address_components[0].types[0] === dd.types[0] &&
  //         cc.geocode.address_components[0].long_name === dd.long_name
  //     );
  //     return f || dd;
  //   });

  const a: any = {
    places: addressComponents.map((l) => ({
      ...l,
      children: circlesToTree(circles, hierarchyLevel + 1, l.long_name),
    })),
    type: hierarchy[hierarchyLevel],
    //   children: rec(hierarchyLevel + 1),
  };

  // console.log("all address compo:", addressComponents, a);
  return a;
};

const NO_PLACE_OPTION = "none";

const Tree = ({ tree, indent, configs, refresh }: any) => {
  return (
    <>
      {tree.places.map((node: any) => (
        <>
          <Row
            style={{
              // paddingLeft: `${indent}pt`,
              width: "100%",
              paddingRight: "8pt",
              paddingBottom: "8pt",
              paddingTop: "8pt",
              justifyContent: "flex-start",
            }}
          >
            <Column
              style={{
                paddingLeft: `${indent}pt`,
                width: "33%",
              }}
            >
              <Row>
                <Icon name="flag" />

                {node.placeId ? (
                  <PlainContent>{node.long_name}</PlainContent>
                ) : (
                  <SpecialContent>{node.long_name}</SpecialContent>
                )}
              </Row>
              <Row>
                <DeContent>{node.types?.[0]}</DeContent>
              </Row>
            </Column>
            {node.placeId && (
              <Column
                style={{
                  alignItems: "flex-start",
                }}
              >
                <Row>
                  <Dropdown
                    onChange={(_e, data) => {
                      if (!data.value) return;

                      if (data.value === NO_PLACE_OPTION) {
                        return removeLaunchRailsPlaceConfig({
                          launchRailsPlace: node.placeId,
                          launchRail: node.placeConfig?.name,
                        })
                          .then(() =>
                            makeToast("✅", {
                              type: "success",
                              autoClose: 1000,
                            })
                          )
                          .catch((e) => makeToast(e, { type: "error" }));
                      }

                      return addLaunchRailsPlaceConfig({
                        launchRailsPlace: node.placeId,
                        launchRail: data?.options?.find(
                          (o) => o?.value === data?.value
                        )?.key,
                      })
                        .then(() =>
                          makeToast("✅", { type: "success", autoClose: 1000 })
                        )
                        .catch((e) =>
                          makeToast(e.toString(), { type: "error" })
                        );
                    }}
                    options={[
                      ...configs.map((c: any) => ({
                        key: `${c.id}`,
                        value: c.name,
                        text: `${c.name}`,
                      })),
                      {
                        key: NO_PLACE_OPTION,
                        value: NO_PLACE_OPTION,
                        text: NO_PLACE_OPTION,
                      },
                    ]}
                    placeholder={node.placeConfig?.name || NO_PLACE_OPTION}
                  ></Dropdown>
                </Row>
              </Column>
            )}
            {node.placeId && (
              <Button
                basic
                style={{ marginLeft: "auto" }}
                onClick={() => {
                  console.log(node.placeId);
                  console.log(node);
                  removeLaunchRailsPlace({ googlePlaceId: node.placeId })
                    .then(() => {
                      makeToast("✅", { type: "success", autoClose: 1000 });
                      refresh();
                    })
                    .catch((e) => makeToast(e.toString(), { type: "error" }));
                }}
              >
                <Icon name="trash" />
              </Button>
            )}
          </Row>
          <Tree
            tree={node.children}
            indent={indent + 16}
            configs={configs}
            refresh={refresh}
          />
        </>
      ))}
    </>
  );
};

const CirclesList = ({
  circles,
  configs,
  refresh,
}: {
  circles: ICircle[];
  configs: IConfigDoc[];
  refresh: () => void;
}) => {
  const tree = circlesToTree(circles);

  console.log("tree:", tree);

  //   const map = circles.reduce((acc, cur) => {
  //     const key: string = cur?.geocode?.types?.[0];

  //     return { ...acc, [key]: [...(acc[key] || []), cur] };
  //   }, {} as { [k: string]: ICircle[] });

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <>
      <Row
        style={{
          width: "100%",
          paddingRight: "8pt",
          paddingBottom: "8pt",
          paddingTop: "8pt",
          justifyContent: "flex-start",
        }}
      >
        <Column style={{ width: "33%" }}>
          <Title2>{"Place"}</Title2>
        </Column>
        <Column>
          <Title2>{"Config"}</Title2>
        </Column>
      </Row>
      <Tree tree={tree} indent={0} configs={configs} refresh={refresh} />
    </>
  );
};

interface ICircleProps {
  coordinates: number[];
  label: string | undefined;
  locationType?: Locations;
}

type Locations =
  | "locality"
  | "administrative_area_level_2"
  | "administrative_area_level_1"
  | "country";

const CircleMarker = ({ coordinates, label, locationType }: ICircleProps) => {
  return (
    <Marker coordinates={coordinates} anchor="bottom">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          maxWidth: 256,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 32,
          margin: 0,
          padding: 8,
          ...locationTypeToView(locationType),
        }}
      >
        <p style={labelStyle}>{label}</p>
      </div>
    </Marker>
  );
};

const locationTypeToView = (locationType?: Locations) => {
  switch (locationType) {
    case "locality": {
      return { backgroundColor: "#9032ee55", padding: 6, marginTop: 0 };
    }
    case "administrative_area_level_2": {
      return { backgroundColor: "#00ff2355", padding: 12, marginTop: 12 };
    }
    case "administrative_area_level_1": {
      return { backgroundColor: "#66001f55", padding: 18, marginTop: 24 };
    }
    case "country": {
      return { backgroundColor: "#00748755", padding: 24, marginTop: 26 };
    }
    default: {
      return { backgroundColor: "#bbb" };
    }
  }
};
