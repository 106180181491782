import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import firebase from "firebase";
import {
  AccountTypeKeys,
  ActionTypes,
  IDocumentReference,
  ILaunchRailsAdd,
  ILaunchRailsConfigPlaceAdd,
  TActionMap,
} from "tonittypes";
import { algoliaClientSearch } from "../api/helpers/algoliaClient";
import { IReasons } from "../components/reports/Reports";
import config from "../config/config.json";
import { SearchApiMode } from "../constants/constants";
import { IUser } from "../constants/index.js";
import uuid from "../utils/uuid";

axios.interceptors.request.use(function (config) {
  return getOrRefreshToken(config);
});

export const toQueryString = (o?: object) => {
  if (!o) return "";

  return Object.entries(o)
    .map(([key, value]) => {
      return `${key}=${encodeURIComponent(value?.toString())}`;
    })
    .join("&");
};

export const fromQueryString = (s: string): any => {
  return s
    .replace(/^\?/, "")
    .split("&")
    .reduce((acc, cur) => {
      const [key, value] = cur.split("=");
      return { ...acc, [decodeURIComponent(key)]: decodeURIComponent(value) };
    }, {});
};

/**
 * getIdToken refreshes tokens automatically
 */
async function getOrRefreshToken(config: AxiosRequestConfig) {
  const user = firebase.auth().currentUser;

  if (user) {
    try {
      const token = await user.getIdToken();
      axios.defaults.headers.common["firebase-access-token"] = token;
      headerAdapter.setHeaders("firebase-access-token", token);
    } catch (e) {
      console.warn(e);
    }
  }
  return config;
}
class HeaderAdapter {
  private headers: Map<string, string> = new Map();

  public getHeader(key: string): string | undefined {
    return this.headers.get(key);
  }

  public getAllHeaders() {
    const headersArr: Array<[string, string]> = [];
    this.headers.forEach((key) => {
      const value = this.getHeader(key);
      if (value) headersArr.push([key, value]);
    });

    return headersArr;
  }

  public setHeaders(key: string, value: string) {
    this.headers.set(key, value);
  }
}
export const headerAdapter = new HeaderAdapter();

export async function requestPasswordResetEmail(userEmail: string) {
  return await axios({
    method: "put",
    url: `${config.apiUrl}/api/v1/users/requestPasswordReset`,
    data: { email: userEmail },
  });
}

export async function requestRestoreUser(user: IUser) {
  return await axios({
    method: "put",
    url: `${config.apiUrl}/api/v1/admin/accounts/${user._id}/restoration`,
    data: {
      profile: user.profile,
      _id: user._id,
      email: user.email,
      idp: user.idp,
    },
  });
}

export async function fetchTicketContacts(email: string) {
  return await axios({
    method: "get",
    url: `${config.apiUrl}/api/v1/support/tickets/contacts`,
    params: {
      email,
    },
  });
}

export async function getUserSessions(userId: string, params: object = {}) {
  return await axios({
    method: "get",
    url: `${config.apiUrl}/api/v1/support/users/${userId}/sessions`,
    params,
  });
}

export const getUserCount = async (opts?: object) => {
  return await axios({
    method: "get",
    url: `${config.apiUrl}/api/v1/support/users/count?${toQueryString(opts)}`,
  });
};

export const getSuspendedUserCount = async () => {
  return await axios({
    method: "get",
    url: `${config.apiUrl}/api/v1/support/users/suspendedcount`,
  });
};

export const getRidingStylesEnteredAffinities = async () => {
  return await axios({
    method: "get",
    url: `${config.apiUrl}/api/v1/support/style_pilot/riding_styles/entered_affinities`,
  });
};

export const getRidingStylesRelevanceComparison = async () => {
  return await axios({
    method: "get",
    url: `${config.apiUrl}/api/v1/support/style_pilot/riding_styles/relevance/comparison`,
  });
};

export const getRidingStyles = async () => {
  return await axios({
    method: "get",
    url: `${config.apiUrl}/api/v1/ridingstyles`,
  });
};

interface IAffinity {
  id: string;
  entered_affinities: Record<string, number>;
}

interface IUpdateAffinitiesPayload {
  ridingStyles: IAffinity[];
}
/**
 * Updates affinities for all riding styles
 * ****** Along with updating the affinities for all riding styles ******
 * ****** this will trigger a much larger update to normalize all related affinities ******
 */
export const updateAffinities = async (data: IUpdateAffinitiesPayload) => {
  return postAction({
    type: ActionTypes.SP_UPDATE_AFFINITIES,
    data,
  });
};

export const setAccountTypes = async (
  adminId: string,
  profileId: string,
  accountTypes: AccountTypeKeys[]
) => {
  const id = uuid();

  const updatedAccountTypeAction = {
    type: "SET_ACCOUNT_TYPES",
    data: { profileId, accountTypes },
    userId: adminId,
  };
  try {
    const res = await axios({
      method: "post",
      url: `${config.apiUrl}/api/v2/actions`,
      data: { action: updatedAccountTypeAction, id },
    });
    return res;
  } catch (e) {
    throw e;
  }
};

export async function fetchReportedForReasons() {
  return await axios({
    method: "get",
    url: `${config.apiUrl}/api/v1/admin/reportReasons`,
    params: {
      includeDisabled: true,
    },
  });
}

export async function updateReportedForReasons(data: IReasons) {
  return await axios({
    method: "put",
    url: `${config.apiUrl}/api/v1/admin/reportReasons`,
    data,
  });
}

export async function fetchUserReports(qs: string, cursors: any) {
  return await axios({
    method: "get",
    url: `${config.apiUrl}/api/v1/support/userreports/${qs}&${toQueryString(
      cursors
    )}`,
  });
}

export async function fetchSusupendedAccounts(cursors: any) {
  return await axios({
    method: "get",
    url: `${config.apiUrl}/api/v1/support/suspendedaccounts?${toQueryString(
      cursors
    )}`,
  });
}

export async function getVideoDownloadUrl(key: string) {
  return await axios({
    method: "get",
    url: `${config.apiUrl}/api/v1/admin/download/videos/${key}`,
  });
}

export async function getActionSeries(cursor?: number) {
  return await axios({
    method: "get",
    url: `${config.apiUrl}/api/v1/admin/actions?${
      cursor ? `cursor=${cursor}` : ""
    }`,
  });
}

export async function getAllSubscribers() {
  return await axios({
    method: "get",
    url: `${config.apiUrl}/api/v1/admin/subscribers`,
  });
}

export async function removeLaunchRailsConfig(name: string) {
  return postAction({
    type: ActionTypes.LAUNCH_RAILS_REMOVE,
    data: { name },
  });
}

export async function getAllGeoCircles() {
  return await axios({
    method: "get",
    url: `${config.apiUrl}/api/v1/support/launchRails/places`,
  });
}

// export async function getPlaceConfigs() {
//   return await axios({
//     method: "get",
//     url: `${config.apiUrl}/api/v1/support/launchrails/placeconfigs`,
//   });
// }

export async function requestProfileReports(profileId: string | undefined) {
  return await axios({
    method: "get",
    url: `${config.apiUrl}/api/v1/support/Profile/${profileId}/reports`,
  });
}

export async function requestClubReports(clubId: string | undefined) {
  return await axios({
    method: "get",
    url: `${config.apiUrl}/api/v1/support/Club/${clubId}/reports`,
  });
}

export async function transferClubOwnership(
  clubId: string | undefined,
  newOwnerId: string
) {
  return await axios({
    method: "POST",
    url: `${config.apiUrl}/api/v1/support/clubs/transfer`,
    data: {
      clubId,
      newOwnerId,
    },
  });
}

export async function requestDeleteUser(user: IUser) {
  return await axios({
    method: "DELETE",
    url: `${config.apiUrl}/api/v1/admin/accounts/${user._id}`,
    data: {
      profile: user.profile._id,
      _id: user._id,
      email: user.email,
      idp: user.idp,
    },
  });
}

export async function requestLinkProfiles(userA_Id: string, userB_Id: string) {
  return await axios({
    method: "POST",
    url: `${config.apiUrl}/api/v1/support/linkProfile`,
    data: {
      linkingUserId: userA_Id,
      discardingUserId: userB_Id,
    },
  });
}

export async function requestSearchUsers(
  isDeleted: boolean,
  searchStrings?: string | string[],
  ids?: string[],
  searchMode?: string,
  isSupport?: boolean
) {
  const query = `?isDeleted=${isDeleted}&searchStrings=${searchStrings}&ids=${ids}&searchMode=${searchMode}&isSupport=${isSupport}`;
  return await axios({
    method: "get",
    url: `${config.apiUrl}/api/v1/support/search${query}`,
  });
}

export async function getSearchModes() {
  return await axios({
    method: "get",
    url: `${config.apiUrl}/api/v1/support/searchModes`,
  });
}

export async function getEventsList(cursors: object) {
  return await axios({
    method: "get",
    url: `${config.apiUrl}/api/v2/events/?${toQueryString(cursors)}`,
  });
}

export async function getNewUsers(cursors: object) {
  return await axios({
    method: "get",
    url: `${config.apiUrl}/api/v1/support/users/new?${toQueryString(cursors)}`,
  });
}

export const getFeedSubscriptions =
  (userId: string) => async (cursors: object) => {
    return await axios({
      method: "get",
      url: `${
        config.apiUrl
      }/api/v1/support/users/${userId}/feed/subscriptions?${toQueryString(
        cursors
      )}`,
    });
  };

export async function feedSubUnsubscribe(data: {
  feedKey: string;
  ownerId: string;
}) {
  return postAction({
    type: ActionTypes.FEED_SUB_ADMIN_UNSUBSCRIBE,
    data,
  });
}

export async function feedSubSubscribe(data: {
  feedKey: string;
  ownerId: string;
}) {
  return postAction({
    type: ActionTypes.FEED_SUB_ADMIN_SUBSCRIBE,
    data,
  });
}

/**
 * Launch Rails
 */

export async function getLaunchRailsConfigs(cursors?: object) {
  return await axios({
    method: "get",
    url: `${config.apiUrl}/api/v1/support/launchRails/configs?${toQueryString(
      cursors
    )}`,
  });
}

export async function addLaunchRailsConfig(data: ILaunchRailsAdd) {
  return postAction({
    type: ActionTypes.LAUNCH_RAILS_ADD,
    data,
  });
}

export async function addLaunchRailsPlace({
  googlePlaceId,
  radiusMeters,
  description,
  location,
  geocode,
}: {
  googlePlaceId: string;
  radiusMeters: number;
  description: string;
  location?: [number, number];
  geocode: any;
}) {
  return postAction({
    type: ActionTypes.LAUNCH_RAILS_PLACE_ADD,
    data: {
      googlePlaceId,
      location: {
        type: "Point",
        coordinates: location,
      },
      radiusMeters,
      description,
      category: "concierge",
      geocode,
    },
  });
}

export async function removeLaunchRailsPlace(data: { googlePlaceId: string }) {
  return postAction({
    type: ActionTypes.LAUNCH_RAILS_PLACE_REMOVE,
    data,
  });
}

export async function addLaunchRailsPlaceConfig(
  data: ILaunchRailsConfigPlaceAdd
) {
  return postAction({
    type: ActionTypes.LAUNCH_RAILS_PLACE_CONFIG_ADD,
    data,
  });
}

export async function removeLaunchRailsPlaceConfig(data: {
  launchRailsPlace: string;
  launchRail: string;
}) {
  return postAction({
    type: ActionTypes.LAUNCH_RAILS_PLACE_CONFIG_REMOVE,
    data,
  });
}

export async function sendProxyMessage(data: any) {
  return postAction({
    type: ActionTypes.SEND_MESSAGE_PROXY,
    data,
  });
}

export async function displayAliases(userId: string) {
  return postAction<Array<{ from: number; alias: string }>>({
    type: ActionTypes.DISPLAY_ALIASES,
    data: { userId },
  });
}

export async function adminDeleteComment(
  userId: string,
  threadId: string,
  commentId: string
) {
  return postAction({
    type: ActionTypes.ACTION_PROXY,
    data: {
      id: uuid(),
      userId: userId,
      type: ActionTypes.DELETE_COMMENT,
      data: {
        threadId,
        postId: threadId,
        id: commentId,
      },
    },
  });
}

/**
 * Sends a MESSAGE_VIEWED action, just like if the user
 * clicked on the message and cleared the unread status.
 */
export async function viewMessage(userId: string, messageSenderId: string) {
  return postAction({
    type: ActionTypes.ACTION_PROXY,
    data: {
      id: uuid(),
      userId: userId,
      type: ActionTypes.MESSAGE_VIEWED,
      data: {
        userId: messageSenderId,
      },
    },
  });
}

export async function markAllMessagesAsRead(userId: string) {
  return postAction({
    type: ActionTypes.ACTION_PROXY,
    data: {
      id: uuid(),
      userId,
      type: ActionTypes.MESSAGE_VIEWED_ALL,
      data: {},
    },
  });
}

export async function displayNextLaunchRailsStep(userId: string) {
  return postAction({
    type: ActionTypes.LAUNCH_RAILS_DISPLAY_NEXT_STEP,
    data: { userId },
  });
}

export async function stopLaunchRails(userId: string) {
  return postAction({
    type: ActionTypes.LAUNCH_RAILS_STOP,
    data: { userId },
  });
}

export async function getWatchedUsers(cursors: object) {
  return await axios({
    method: "get",
    url: `${config.apiUrl}/api/v1/support/watches/users?${toQueryString(
      cursors
    )}`,
  });
}

export async function addWatchUser(userId: string) {
  return postAction({
    type: ActionTypes.SUPPORT_WATCH_ADD,
    data: {
      id: userId,
    },
  });
}

export async function getImpressions(postId: string) {
  return await axios({
    method: "get",
    url: `${config.apiUrl}/api/v1/support/posts/impressions/${postId}`,
  });
}

export async function removeWatchUser(userId: string) {
  return postAction({
    type: ActionTypes.SUPPORT_WATCH_REMOVE,
    data: {
      id: userId,
    },
  });
}

export async function removeFavorite(postId: string) {
  return postAction({
    type: ActionTypes.DELETE_FAVORITE,
    data: {
      postId,
    },
  });
}

export async function saveFavorite(postId: string) {
  return postAction({
    type: ActionTypes.SAVE_FAVORITE,
    data: {
      postId,
    },
  });
}

export async function requestChangeUserEmail(newEmail: string, userId: string) {
  return await axios({
    method: "post",
    url: `${config.apiUrl}/api/v1/support/changeEmail`,
    data: {
      email: newEmail,
      userId: userId,
    },
  });
}

export async function requestPasswordResetLink(userEmail: string) {
  return await axios({
    method: "post",
    url: `${config.apiUrl}/api/v1/support/user/resetPasswordLink`,
    data: { email: userEmail },
  });
}

export async function deleteCacheKey(key: string) {
  return await axios({
    method: "delete",
    url: `${config.apiUrl}/api/v1/support/cache/${key}`,
  });
}

interface IPaginationOpts {
  limit?: number;
  sortKey?: string;
  order?: string;
  page?: number;
}

/**
 * Pull in the posts in review
 */
export async function requestPostsInReview({
  page,
  limit,
  sortKey,
  order,
}: IPaginationOpts = {}) {
  return await axios.get(`${config.apiUrl}/api/v1/support/reviewables`, {
    params: {
      limit,
      sortKey,
      order,
      page,
    },
  });
}

/**
 * Push action to the server
 */
export async function postAction<T = any>(
  action: Omit<TActionMap, "userId" | "createdAt">
) {
  return (await axios({
    method: "post",
    url: `${config.apiUrl}/api/v2/actions`,
    data: { action, id: uuid() },
  })) as AxiosResponse<T>;
}

export async function getUser(userId: string) {
  return await axios({
    method: "get",
    url: `${config.apiUrl}/api/v1/users/${userId}`,
  });
}

export async function getProfile(userId: string) {
  return await axios({
    method: "get",
    url: `${config.apiUrl}/api/v1/profiles/${userId}`,
  });
}

interface IQualifiedEvent {
  event: any;
}

interface IQualifiedPost {
  post: any;
  comments: {
    data: any[];
    cursors: object;
  };
  likes: {
    data: any[];
    cursors: object;
  };
  postReports: {
    data: any[];
    cursors?: object;
  };
  commentReports: {
    data: any[];
    cursors?: object;
  };
}

export async function getQualifiedPost(
  postId: string
): Promise<AxiosResponse<IQualifiedPost>> {
  return await axios({
    method: "get",
    url: `${config.apiUrl}/api/v1/support/search/posts?search=${postId}`,
  });
}

export async function getQualifiedEvent(
  eventId: string
): Promise<AxiosResponse<IQualifiedEvent>> {
  return await axios(
    `${config.apiUrl}/api/v1/support/search/events?search=${eventId}`
  );
}

interface IResponse<T = any[]> {
  data: {
    data: T;
    cursors: {
      before?: string;
      after?: string;
    };
  };
}

interface IFetchDataOpts {
  limit?: number;
  after?: string;
  before?: string;
  hashtag?: string;
}

export enum FeedKeys {
  ROOT = "root",
  CLUB = "club",
  HASHTAG = "hashtag",
  ACITIVTIES = "activities",
  FAVORITES = "favorites",
  COMBINED = "combined",
  TOP = "top",
}

export const namedFeeds = ["global"];

export const feedMap = {
  [FeedKeys.ROOT]: (feedId: string) => `${config.apiUrl}/api/v2/feed/${feedId}`,
  [FeedKeys.CLUB]: (feedId: string) =>
    `${config.apiUrl}/api/v2/clubs/${feedId}/posts`,
  [FeedKeys.HASHTAG]: (feedId: string) =>
    `${config.apiUrl}/api/v2/feed/hashtags/${feedId}`,
  [FeedKeys.ACITIVTIES]: () =>
    `${config.apiUrl}/api/v1/support/posts/activities?platform=ios`,
  [FeedKeys.FAVORITES]: () => `${config.apiUrl}/api/v2/feed/favorites`,
  [FeedKeys.COMBINED]: () => `${config.apiUrl}/api/v2/feed/combined`,
  [FeedKeys.TOP]: () => `${config.apiUrl}/api/v2/feed/top`,
};

export interface IFeedMeta {
  title?: string;
}

const feedMetaDataAdaptor = (() => {
  let memo: { [k: string]: any } = {};

  return {
    [FeedKeys.CLUB]: async (clubId: string) => {
      if (memo[clubId] !== undefined) return memo[clubId];

      // Get club data from the directory
      const res = await firebase
        .database()
        .ref(`clubs/directory/${clubId}`)
        .once("value");

      const val = res.val();
      const meta = { title: val?.name };
      memo = { ...memo, [clubId]: val ? meta : null };

      if (!res.exists()) return {};

      return meta;
    },
    [FeedKeys.ROOT]: async (feedId: string) => {
      if (memo[feedId] !== undefined) return memo[feedId];

      if (namedFeeds.includes(feedId)) {
        return { title: feedId };
      }

      const { data } = await getProfile(feedId);
      const meta = { title: `${data?.username} following` };

      memo = { ...memo, [feedId]: data ? meta : null };
      if (!data) return {};

      return meta;
    },
    [FeedKeys.HASHTAG]: async (feedId: string) => {
      return { title: `#${feedId}` };
    },
    [FeedKeys.COMBINED]: async (feedId: string) => {
      return { title: "combined" };
    },
    [FeedKeys.TOP]: async (feedId: string) => {
      return { title: "top" };
    },
    [FeedKeys.ACITIVTIES]: async (feedId: string) => {
      return { title: `all activities` };
    },
    [FeedKeys.FAVORITES]: async () => {
      return { title: `favorites` };
    },
  };
})();

// fetch in all the posts for a given feed
export const fetchFeed =
  (feedKey: FeedKeys) => async (feedId: string, opts: any) => {
    const url = feedMap[feedKey](feedId);
    const [feed, meta] = await Promise.all([
      fetchPosts(url, opts),
      feedMetaDataAdaptor[feedKey](feedId),
    ]);
    return { feed, meta };
  };

// fetch in all the posts for a given feed
export const fetchPosts = async (url: string, opts: any) => {
  const response = await fetchFeedData(url, opts);
  return handlePaginatedResponse(response.data);
};

export const fetchFeedData = async (
  url: string,
  params: IFetchDataOpts = {}
): Promise<IResponse> => {
  return axios({
    url,
    params,
  });
};

export const fetchComments = async (
  postId: string,
  params: IFetchDataOpts = {}
): Promise<IResponse> => {
  return axios({
    url: `${config.apiUrl}/api/v2/posts/${postId}/comments`,
    params,
  });
};

export const fetchLikes = async (
  postId: string,
  params: IFetchDataOpts = {}
): Promise<IResponse> => {
  return axios({
    url: `${config.apiUrl}/api/v2/posts/${postId}/likes`,
    params,
  });
};

export const fetchActivity = async (activityId: string): Promise<IResponse> => {
  return axios({
    url: `${config.apiUrl}/api/v2/activities/${activityId}`,
  });
};

export const fetchCoords = async (activityId: string): Promise<IResponse> => {
  return axios({
    url: `${config.apiUrl}/api/v1/support/activities/${activityId}/coordinates`,
  });
};

export const fetchBlocks = async (
  params: any = {}
): Promise<IResponse<{ blocks: any[]; count: number }>> => {
  return axios({
    url: `${config.apiUrl}/api/v1/support/blocks/`,
    params,
  });
};

export const blockDevices = async (userId: string): Promise<IResponse<{}>> => {
  return axios({
    url: `${config.apiUrl}/api/v1/admin/accounts/${userId}/blockdevices`,
    method: "POST",
  });
};

export const resolveSuspension = (userId: string, isFalsePositive: boolean) => {
  return postAction({
    type: ActionTypes.SUSPENSION_RESOLVE,
    data: {
      userId,
      isFalsePositive,
    },
  });
};

export const deleteAllUserGeneratedContent = (userId: string) => {
  return postAction({
    type: ActionTypes.UGC_REMOVE_ALL,
    data: {
      userId,
    },
  });
};

export const suspendUser = (userId: string) => {
  return postAction({
    type: ActionTypes.SUSPENSION_CREATE,
    data: {
      userId,
    },
  });
};

export const savePost = (post: any) => {
  return postAction({
    type: ActionTypes.SAVE_POST,
    data: post,
  });
};

// handle a paginated response from the server
// pass back the keys, entitites and the cursors
export const handlePaginatedResponse = (
  response: any,
  keyExtractor?: typeof defaultKeyExtractor
) => {
  if (response?.data) {
    if (response.data.length) {
      const { keys, entities } = normalize(response.data, keyExtractor);
      return { keys, entities, cursors: response.cursors };
    }

    return { keys: [], cursors: response.cursors };
  }

  return null;
};

export const defaultKeyExtractor = (item: any) => item?.id;

// split up data into keys and entities
export const normalize = (
  toNormalize: any[],
  keyExtractor = defaultKeyExtractor
) => {
  const keys: any[] = [];

  const entities = (toNormalize || []).reduce((pre, entity) => {
    const key = entity && keyExtractor(entity);
    keys.push(key);

    if (!key) {
      return pre;
    }

    pre[key] = entity;
    return pre;
  }, {});

  return { keys, entities };
};

/**
 * Performs an algolia search
 * @param indexName name of the algolia index based on config
 * @param searchString the string to search for
 * @param searchApiMode the mode of search to perform, typically id or otherwise
 */
export const indexSearch = async (
  indexName: string,
  searchString: string,
  searchApiMode: string
) => {
  const index = algoliaClientSearch(indexName);
  const isIdSearch = searchApiMode === SearchApiMode.ID;
  return isIdSearch
    ? (await index.getObjects([searchString])).results
    : (await index.search(searchString)).hits;
};
