import { IImageTypeConfig, ISingleImageData } from "../../constants/constants";

/**
   *
   * @param config
   * @param updateData
   *
   * Finds the next source in the config whitelist (check constants to see the type)
   * and replaces the last source. If the end of the list is reached
   * default to the designated fallback image
   */
export const getNextUrl = (data: ISingleImageData, config: IImageTypeConfig, updateData: (url: string, location: string) => void) => {

  const nextIndex = config.whitelist.indexOf(data.location) + 1;

  if (nextIndex >= config.whitelist.length) {
    return updateData(config.fallback, "fallback");
  }

  const nextSrc = config.whitelist[nextIndex];
  const nextUrl = data.url.replace(data.location, nextSrc);
  console.log("Trying new url in:", nextSrc);
  updateData(nextUrl, nextSrc);
}
