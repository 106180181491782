import React, { FC, useState, useEffect, useCallback } from "react";
import { IUser } from '../../constants';
import { ToastOptions } from "react-toastify";
import { requestProfileReports } from "../../api/api";
import { ReportTypes } from 'tonittypes';
import { DetailsSegment, DetailRowData } from './DetailsSegment';

interface IProps {
  user: IUser
  makeToast: (text: string, options?: ToastOptions) => void;
};

interface IReportObject {
  isDeleted: boolean;
  _id: string;
  reportedId: string;
  userId: string;
  type: ReportTypes;
  reason: string;
}

interface IData {
  label: string;
  value: string | unknown;
}

export const ProfileReportDetails: FC<IProps> = ({ user, makeToast }) => {
  const [reports, setReports] = useState<IReportObject[]>([]);
  const [data, setData] = useState<DetailRowData>([]);

  const requestReports = useCallback(async () => {
    try {
      const reports = await requestProfileReports(user.profile._id)
      return setReports(reports.data.reports)
    } catch (e) {
      makeToast(`${e}`, { type: "error" });
    };
  }, [makeToast, user.profile._id]);

  useEffect(() => {
    const reportCounts = reports.reduce((acc: any, curr: IReportObject) => {
      return [...acc.filter((d: IData) => d.label !== curr.reason),
      {
        label: curr.reason,
        value: (acc.find((d: IData) => d.label === curr.reason)?.value || 0) + 1,
      }
      ]
    }, [])
    return setData(reportCounts);
  }, [reports]);

  useEffect(() => {
    requestReports();
  }, [requestReports]);

  return <DetailsSegment
    title={"Reports"}
    accentColor="#FF007F"
    data={data} />;
};
