import { withRouter } from "react-router-dom";
import { Tab } from "semantic-ui-react";
import { MapPicker } from "../../newUsers/mapPicker";
import { NewUsersList } from "./NewUsersList";
import { LaunchRailsConfig } from "./LaunchRails";
import { getLaunchRailsConfigs } from "../../../api/api";
import { useApiList } from "../../../hooks/useApiList";
import { ICheckpoint } from "tonittypes";

export interface IConfigDoc {
  id: string;
  checkpoints: ICheckpoint[];
  createdAt: number;
  updatedAt?: number;
  agentProfileId?: string;
  name: string;
}

export const Concierge = withRouter(({ location }) => {
  const { data, next, hasMore, refresh } = useApiList<IConfigDoc>(
    getLaunchRailsConfigs,
    { pageSize: 50 }
  );

  // TODO: would be nice if you could copy the url
  // and it would direct to the page you're currently on
  // const { push } = useHistory();

  // const prevAfter = usePrevious(cursors?.after);

  // useEffect(() => {
  //   prevAfter && push({
  //     pathname: location.pathname,
  //     search: `?after=${prevAfter}`
  //   });
  // }, [location.pathname, prevAfter, push]);

  const panes = [
    {
      menuItem: "New Users",
      render: () => (
        <Tab.Pane>
          <NewUsersList />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Places",
      render: () => (
        <Tab.Pane>
          <MapPicker configs={data} refresh={refresh} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Configurations",
      render: () => (
        <Tab.Pane>
          <LaunchRailsConfig
            refresh={refresh}
            configs={data}
            hasMore={hasMore}
            next={next}
          />
        </Tab.Pane>
      ),
    },
  ];

  return <Tab panes={panes} />;
});
