import React, { FC } from "react";
import { IUser } from "../../constants";
import { Segment, Label, Grid, Header } from "semantic-ui-react";
import * as config from "../../config/config.json";

interface IProps {
  user: IUser;
  segmentColor: string;
}

export const IAPDetails: FC<IProps> = ({ user, segmentColor }) => {
  return (
    <Segment style={{ borderLeft: `4px solid ${segmentColor}` }}>
      <Label size="large" attached="top left">
        {`In App Purchases`}
      </Label>
      <Grid stackable divided="vertically">
        <Grid.Row columns={16}>
          <Grid.Column verticalAlign="middle" width={16}>
            <Header as="h5">
              <a
                href={`${config.iaphub.dashboardBasePath}/user/${user.profile._id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit IAPHUB dashboard for transaction history
              </a>
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};
