import React, { useEffect, useState } from "react";
import { Table, Loader } from "semantic-ui-react";
import { getRidingStylesRelevanceComparison } from "../../../api/api";

export const RelevanceComparison = () => {
  const [comparison, setComparison] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const { data } = await getRidingStylesRelevanceComparison();
      setComparison(data);
    };

    fetch();
  }, []);

  if (!comparison?.length) {
    return (
      <div style={{ padding: "50px 0", position: "relative" }}>
        <Loader active />
      </div>
    );
  }

  return (
    <Table compact celled striped>
      <Table.Body>
        {comparison.map((r: Array<string | number>, i) => {
          return (
            <Table.Row key={i}>
              {(r || []).map((c: any, j) => {
                const output =
                  typeof c === "string" ? c : Math.round(c * 1000) / 1000;
                return (
                  <Table.Cell
                    textAlign={j === 0 ? "right" : "center"}
                    key={`${i}-${j}`}
                  >
                    {output}
                  </Table.Cell>
                );
              })}
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};
