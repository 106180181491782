import React, { useEffect, useState } from "react";
import "semantic-ui-css/semantic.min.css";
import { Grid, Segment } from "semantic-ui-react";
import { displayAliases, displayNextLaunchRailsStep } from "../../api/api";
import { IUser } from "../../constants";
import { DetailsSegment } from "./DetailsSegment";
import firebase from "firebase";

interface IProps {
  user: IUser | null;
}

const textStyles = {
  color: "#323",
  margin: "auto",
  paddingHorizontal: 20,
};

const dividedSegmentStyles = {
  borderLeft: "4px solid #139",
};

const useUnreadMessageCount = ({
  setCount,
  userId,
}: {
  userId?: string;
  setCount: (n?: number) => void;
}) => {
  useEffect(() => {
    const listener = firebase
      .firestore()
      .collection("user-chatrooms-meta")
      .doc()
      .collection("rooms")
      .orderBy("unreadCount", "desc") // pull in the rooms with unread messages first
      .limit(50)
      .onSnapshot((snapshot) => {
        if (!snapshot || snapshot.empty) {
          return setCount(0);
        }

        // add up the count
        const count = snapshot.docs.reduce((pre, curr) => {
          return pre + (curr.data()?.unreadCount || 0);
        }, 0);

        setCount(count);
      }, console.error);

    return listener;
  }, [setCount, userId]);
};

export const ProfileDetails = (props: IProps) => {
  const { user } = props;

  const [nextLaunchRailsStep, setNextLaunchRailsStep] = useState({});
  const [aliases, setAliases] = useState<any[]>([]);
  const [unreadMessageCount, setUnreadMessageCount] = useState<
    number | undefined
  >(undefined);

  useUnreadMessageCount({
    setCount: setUnreadMessageCount,
    userId: user?.profile._id,
  });

  useEffect(() => {
    if (user?.profile?._id) {
      displayNextLaunchRailsStep(user?.profile?._id).then((r) => {
        if (!r?.data?.data) return {};

        setNextLaunchRailsStep({
          actionType: r?.data?.data?.wrappedAction?.type,
          at: new Date(r?.data?.data?.handleAfter).toISOString(),
          payload: r?.data?.data?.wrappedAction?.data,
        });
      });
    }
  }, [user?.profile?._id]);

  useEffect(() => {
    if (user?.profile?._id) {
      displayAliases(user?.profile?._id).then((r) => {
        if (!r?.data) return {};

        setAliases(
          r.data.map(
            (o) => `"${o.alias}" from ${new Date(o.from).toLocaleString()}`
          )
        );
      });
    }
  }, [user?.profile?._id]);

  if (!user) {
    return null;
  }

  const profile = user.profile;

  if (!user.profile) {
    return (
      <Segment style={dividedSegmentStyles}>
        <Grid>
          <h2>Profile</h2>
          <Grid.Row columns={1} divided>
            <Grid.Column>
              <h3
                style={textStyles}
              >{`Profile doesn't exist or is in a different deleted state`}</h3>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }

  return (
    <DetailsSegment
      title={"Profile"}
      accentColor="#139"
      data={[
        { label: "id", value: profile._id },
        {
          label: "created at",
          value: new Date(profile.createdAt).toDateString(),
        },
        { label: "username", value: profile.username },
        { label: "bio", value: profile.bio },
        { label: "is admin?", value: profile.isAdmin === true },
        { label: "is beta?", value: profile.isBeta === true },
        { label: "is deleted?", value: profile.isDeleted === true },
        {
          label: "location",
          value: profile.location && profile.location.humanized,
        },
        { label: "following", value: profile.followingCount || 0 },
        { label: "followers", value: profile.followersCount || 0 },
        { label: "launch rails status", value: profile.launchRailsStatus },
        { label: "next launch rails step", value: nextLaunchRailsStep },
        { label: "aliases", value: aliases },
        { label: "unread message count", value: unreadMessageCount },
        { label: "is auto suspended", value: profile.isAutoSuspended },
        { label: "auto suspended at", value: profile.autoSuspendedAt },
      ]}
    />
  );
};
