import { useEffect } from "react";
import { ToastOptions } from "react-toastify";
import { Button, Grid } from "semantic-ui-react";
import { deleteAllUserGeneratedContent, fetchSusupendedAccounts } from "../../../api/api";
import { useApiList } from "../../../hooks/useApiList";
import { Label } from "../../feeds/styles";
import { ButtonWithConfirm } from "../../shared";
import { makeToast } from "../../Toaster";
import { UserCard } from "../../UserCard";

interface IProps {
  makeToast: (text: string, options?: ToastOptions) => void;
}

export const SuspendedAccountsPage = (props: IProps) => {
  // const { push } = useHistory();

  const { data, refresh, next, hasMore } = useApiList<any>(
    fetchSusupendedAccounts,
    {
      pageSize: 50,
      selfManaged: true,
    }
  );

  useEffect(refresh, [refresh]);

  // const handleViewProfileClick = async (id: string) => {
  //   push(getRouteToDetail(ClientRoutes.Users, id));
  // };

  console.log(data);

  return (
    <div>
      <Grid>
        {data.map((item: any) => (
          <UserCard
          user={{profile: item, _id: item._id, idp:"", email:""}}
          renderExtra={() => item.ugcRemovedAt
            ? <Label>{`UGC removed at ${new Date(item.ugcRemovedAt).toString()}`}</Label>
            : <ButtonWithConfirm 
            onConfirm={() => {
              deleteAllUserGeneratedContent(item._id).then(async result => {
                for (const r of (result.data || [])) {
                  makeToast(`${r[0]}: ${r[1]?.toString() || "success"}`, {type:r[1] ? "error" : "success"});
                  await new Promise((res) => setTimeout(res, 1000));
                }
                refresh();
              })
              .catch(e => makeToast(e, {type: "error"}));
            }}
            content="Delete All User Generated Content"
            promptMessage="This will remove all their user generated content, eg, posts, comments, chat messages, events, club memberships, etc. This action is IRREVERSIBLE."
            secondary>
              </ButtonWithConfirm>}
          />
            // <Grid.Row columns={5}>
            //   <Grid.Column width={1}>
            //     <Image
            //       style={{
            //         borderRadius: "21pt",
            //         width: "42pt",
            //         height: "42pt",
            //         minHeight: "42pt",
            //         minWidth: "42pt",
            //       }}
            //       src={item.avatarUrl}
            //     />
            //   </Grid.Column>
            //   <Grid.Column width={1}>
            //       <Button>
            //           {"view reports against"}
            //       </Button>
            //   </Grid.Column>
            //   <Grid.Column width={5}>
            //     <Username
            //       maxWidth={256}
            //       onClick={() => handleViewProfileClick(item._id)}
            //     >
            //       <UserBadge accountTypes={item.accountTypes} />
            //       {item.username}
            //     </Username>
            //   </Grid.Column>
            //   <Grid.Column width={2}>
            //       <PlainContent>{"auto suspended at"}</PlainContent>
            //     <Time date={item.autoSuspendedAt || "unkown"} />
            //   </Grid.Column>
            //   <Grid.Column width={2}>
            //       <PlainContent>{"created at"}</PlainContent>
            //     <Time date={item.createdAt || "unkown"} />
            //   </Grid.Column>
            //   <Grid.Column width={1}>
            //      <Button
            //      primary
            //      onClick={() => resolveSuspension(item._id?.toString(), true).then(refresh)}>
            //          {"Restore"}
            //          </Button>
            //   </Grid.Column>
            //   <Grid.Column width={1}>
            //      <Button 
            //      color="red"
            //      onClick={() => resolveSuspension(item._id?.toString(), false).then(refresh)}>
            //          {"Remove"}
            //          </Button>
            //   </Grid.Column>
            // </Grid.Row>
        ))}
      </Grid>
      <Button style={{marginTop: "36pt"}} onClick={next} disabled={!hasMore}>
        {"Show More"}
      </Button>
    </div>
  );
};
