import React, { Component } from 'react';
import "semantic-ui-css/semantic.min.css";
import { ISessionData } from 'tonittypes';
import { IUser } from '../../constants';
import { camelToSpace } from '../../utils';
import { DetailRowData, DetailsSegment } from './DetailsSegment';

export const AppVersionDetailsFromSessions = ({ sessions, loadMore }: { sessions: (ISessionData & { startedAt: string })[], loadMore?: () => void }) => {
  return <DetailsSegment
    loadMore={loadMore}
    title={"Sessions *NEW*"}
    accentColor="#11efff"
    data={sessions.map(o => ({
      label: `${o.startedAt} ${o.platformOs} ${o.applicationVersion}`,
      value: Object.entries(o).map(([label, value]) => ` ${camelToSpace(label)}: ${value}`),
    }))}
  />
};

interface IProps {
  user: IUser | null;
};

export class AppVersionDetails extends Component<IProps, any> {

  public render() {
    const { user } = this.props;

    if (!user || !user.profile || !user.profile.appVersionMeta) {
      return null;
    }

    const profile = user.profile;

    let versionData: DetailRowData = [];
    if (Array.isArray(profile.appVersionMeta) && Boolean(profile.appVersionMeta.length)) {

      const t = (f: Function) => (a: string, b: string) => f(b) - f(a);

      const sorted = profile.appVersionMeta.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
      sorted.forEach(meta => {
        versionData.push({
          label: `${meta.platform} ${meta.version || meta.appVersion}`,
          value: meta.patch.sort(t((s: string) => parseInt(s.replace(/\D/g, "")))),
        });
      });
    }

    return <DetailsSegment
      title={"App Versions"}
      accentColor="#7f1"
      data={versionData}
    />
  }
}
