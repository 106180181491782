import { AxiosError, AxiosResponse } from "axios";
import firebase from "firebase";
import throttle from "lodash/throttle";
import React, { FC, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ToastOptions } from "react-toastify";
import "semantic-ui-css/semantic.min.css";
import {
  Button,
  Dimmer,
  Header,
  Icon,
  Image as ImageComponent,
  Label,
  Loader,
  Modal,
  Segment,
} from "semantic-ui-react";
import { ISessionData } from "tonittypes";
import { AdminContext } from "../..";
import {
  addWatchUser,
  fetchTicketContacts,
  getUserSessions,
  requestChangeUserEmail,
  requestDeleteUser,
  requestPasswordResetEmail,
  requestPasswordResetLink,
  requestRestoreUser,
  deleteCacheKey,
  stopLaunchRails,
  blockDevices,
} from "../../api/api";
import { veryifyEmailPattern } from "../../api/helpers/verifyEmailPattern";
import { bgColors } from "../../App";
import { imageGroupConfig } from "../../constants/constants";
import { IUser } from "../../constants/index.js";
import { ClientRoutes } from "../../constants/routes";
import { useUser } from "../../hooks/useUser";
import { ChatRooms } from "../chats/Chats";
import { Column, Row, Time } from "../feeds/styles";
import { makeToast } from "../Toaster";
import { AccountTypeDetails } from "./AccountTypeDetails";
import {
  AppVersionDetails,
  AppVersionDetailsFromSessions,
} from "./AppVersionDetails";
import { ChangeEmailModal } from "./ChangeEmailModal";
import { CompareProfile } from "./CompareProfile";
import { DetailsSegment } from "./DetailsSegment";
import { FeedSubscriptions } from "./FeedSubscriptions";
import { IAPDetails } from "./IAPDetails";
import { ProfileActions } from "./ProfileActions";
import { ProfileDetails } from "./ProfileDetails";
import { ProfileReportDetails } from "./ProfileReportDetails";
import { ResetPasswordLinkModal } from "./ResetPasswordLinkModal";
import { SearchModal } from "./SearchModal";
import { UserDetails } from "./UserDetails";

interface IProps {
  makeToast: (text: string, options?: ToastOptions) => void;
}

const topBarStyles = {
  display: "flex",
  flex: "0 1 auto",
  flexDirection: "row",
  alignItems: "center",
  height: "auto",
  backgroundColor: "#FFFA",
};

const textStyles = {
  margin: 0,
  paddingLeft: 20,
};

const SESSION_PAGE = 8;

const useSessions = (userId: string) => {
  const [sessions, setSessions] = useState<
    (ISessionData & { startedAt: string })[]
  >([]);
  const [cursors, setCursors] = useState<any>({ limit: SESSION_PAGE });

  const hasMoreSessions =
    sessions.length && sessions.length % SESSION_PAGE === 0;

  const loadMoreSessions = () => {
    if (hasMoreSessions) {
      getUserSessions(userId, cursors).then((res) => {
        setCursors({
          limit: cursors.limit,
          ...(res.data?.cursors || {}),
        });

        const s = (res.data?.data || []).map((d: any) => ({
          ...d,
          startedAt: new Date(d?.startedAt)
            .toISOString()
            .replace(/[A-Z]/g, " "),
        }));

        setSessions([...sessions, ...s]);
      });
    }
  };

  useEffect(() => {
    loadMoreSessions();
  }, [userId]); // eslint-disable-line

  return { sessions, loadMoreSessions, hasMoreSessions };
};

export const UserPage: FC<IProps> = (props) => {
  const history = useHistory();
  const { id: userId = "" } = useParams<{ id: string }>();
  const { sessions, hasMoreSessions, loadMoreSessions } = useSessions(userId);
  const { makeToast } = props;

  const [profileToCompare, setProfileToCompare] = useState<IUser | null>(null);
  const [isProfileLinkOpen, setIsProfileLinkOpen] = useState<boolean>(false);
  const [ticketSystemId, setTicketSystemId] = useState<number | null>(null);
  const [isChangeEmailModalOpen, setIsChangeEmailModalOpen] =
    useState<boolean>(false);
  const [isResetPasswordLinkModalOpen, setIsResetPasswordLinkModalOpen] =
    useState<boolean>(false);
  const [resetPasswordLink, setResetPasswordLink] = useState<string>("");
  const [unreadNotifications, setUnreadNotifications] = useState<number>(0);
  const AdminProfile = useContext(AdminContext);

  const [user, setUser] = useState<IUser>();
  // TODO we grab this on every load but technically we might have already fetched this data
  // on the user screen, look to implement local store to cut down on network requests
  const { user: remoteUser, loading, error, refreshUser } = useUser(userId);
  let unsubscribeNotifications: any = null;

  const goBack = () => {
    history.goBack();
  };

  const getTicketSystemContact = async () => {
    if (!user) return;
    const result: AxiosResponse<any> = await fetchTicketContacts(user.email);

    if (
      result.data &&
      Array.isArray(result.data.contacts) &&
      result.data.contacts[0]
    ) {
      setTicketSystemId(result.data.contacts[0].id);
    } else {
      setTicketSystemId(null);
    }
  };

  const throttledTicketGet = throttle(() => getTicketSystemContact(), 1000);

  const getPasswordResetLink = async () => {
    if (user) {
      try {
        const result: AxiosResponse<any> = await requestPasswordResetLink(
          user.email
        );
        setResetPasswordLink(result.data.link);
        toggleResetPasswordLinkModalOpen();
      } catch (error) {
        if (error.isAxiosError) {
          const apiError = error as AxiosError<{ error: Error }>;
          if (apiError.response)
            return makeToast(apiError.response.data.error.message, {
              type: "error",
            });

          return makeToast("Unknown error occured", { type: "error" });
        } else {
          console.error(error);
          return makeToast(`Unknown error occured: ${error}`, {
            type: "error",
          });
        }
      }
    }
  };

  const goToBlocks = () => {
    history.push({
      pathname: ClientRoutes.Blocks,
      search: `?search=${user?.profile._id}`,
    });
  };

  const watchUser = () => {
    if (!user?._id) return;
    addWatchUser(user._id);
    makeToast(`Watching ${user?.profile?.username}`, { type: "success" });
  };

  const restoreUser = async () => {
    if (user) {
      try {
        await requestRestoreUser(user);
        refreshUser();
        makeToast(`User: "${user.profile.username}" has been restored`, {
          type: "success",
        });
      } catch (error) {
        if (error.isAxiosError) {
          const apiError = error as AxiosError<{ error: Error }>;
          if (apiError.response)
            return makeToast(apiError.response.data.error.message, {
              type: "error",
            });

          return makeToast("Unknown error occured", { type: "error" });
        } else {
          console.error(error);
          return makeToast(`Unknown error occured: ${error}`, {
            type: "error",
          });
        }
      }
    }
  };

  const deleteUser = async () => {
    if (user) {
      try {
        if (AdminProfile._id === user.profile._id) {
          alert(
            "If you delete your own profile you won't be able to use this app anymore"
          );
          // It's possible to allow someone to delete their account anyway,
          // But probably not too important
          return;
        }

        await requestDeleteUser(user);
        refreshUser();
        makeToast(`User: "${user.profile.username}", has been deleted`, {
          type: "success",
        });
      } catch (error) {
        if (error.isAxiosError) {
          const apiError = error as AxiosError<{ error: Error }>;
          if (apiError.response)
            return makeToast(apiError.response.data.error.message, {
              type: "error",
            });

          return makeToast("Unknown error occured", { type: "error" });
        } else {
          console.error(error);
          return makeToast(`Unknown error occured: ${error}`, {
            type: "error",
          });
        }
      }
    }
  };

  const sendResetPasswordEmail = async () => {
    if (user && user.email) {
      try {
        await requestPasswordResetEmail(user.email);
        makeToast(`Email sent to user: "${user.profile.username}"`, {
          type: "success",
        });
      } catch (error) {
        if (error.isAxiosError) {
          const apiError = error as AxiosError<{ error: Error }>;
          if (apiError.response)
            return makeToast(apiError.response.data.error.message, {
              type: "error",
            });

          return makeToast("Unknown error occured", { type: "error" });
        } else {
          console.error(error);
          return makeToast(`Unknown error occured: ${error}`, {
            type: "error",
          });
        }
      }
    }
  };

  const toggleProfileLinkModal = () => {
    setIsProfileLinkOpen(!isProfileLinkOpen);
  };

  const _stopLaunchRails = () => {
    if (user?.profile?._id) stopLaunchRails(user?.profile?._id);
  };

  const toggleChangeEmailModal = () => {
    setIsChangeEmailModalOpen(!isChangeEmailModalOpen);
  };

  const toggleResetPasswordLinkModalOpen = () => {
    setIsResetPasswordLinkModalOpen(!isResetPasswordLinkModalOpen);
  };

  const countUnreadNotification = async () => {
    unsubscribeNotifications = await firebase
      .firestore()
      .collection("notifications")
      .where("recipientId", "==", user?.profile?._id)
      .where("isViewed", "==", false)
      .orderBy("createdAt", "desc")
      .limit(99)
      .onSnapshot((snapshot) => {
        setUnreadNotifications(snapshot.size);
      });
  };

  const changeUserEmail = async (text: string) => {
    if (veryifyEmailPattern(text)) {
      if (user) {
        try {
          const response = await requestChangeUserEmail(text, user._id);
          const updatedUser: IUser = {
            ...user,
            email: response.data.user.email,
          };
          makeToast(`Email changed to: ${updatedUser.email}`, {
            type: "success",
          });
          setUser({ ...updatedUser });
        } catch (error) {
          if (error.isAxiosError) {
            const apiError = error as AxiosError<{ error: Error }>;
            if (apiError.response)
              return makeToast(apiError.response.data.error.message, {
                type: "error",
              });

            return makeToast("Unknown error occured", { type: "error" });
          } else {
            console.error(error);
            return makeToast(`Unknown error occured: ${error}`, {
              type: "error",
            });
          }
        }
      }
    } else {
      makeToast("The email you entered is not valid", { type: "error" });
    }
  };

  /**
   * Delete redis cache for a user's post list
   */
  const clearPostlistCache = async () => {
    if (user?.profile?._id) {
      const postListKey = `feed:profile:${user?.profile?._id}:posts:ids`;

      try {
        await deleteCacheKey(postListKey);
        makeToast(`Successfully deleted cache key ${postListKey}`);
      } catch (e) {
        makeToast(`Unknown error occured: ${e}`, { type: "error" });
      }
    }
  };

  useEffect(() => {
    return () => {
      if (unsubscribeNotifications) {
        unsubscribeNotifications();
      }
    };
  }, [unsubscribeNotifications]);

  useEffect(() => {
    if (!user?.profile?._id) return;

    countUnreadNotification();
    throttledTicketGet();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setUser(remoteUser);
  }, [remoteUser]);

  if (loading) {
    return (
      <Segment>
        <Dimmer active>
          <Loader>Loading</Loader>
        </Dimmer>
      </Segment>
    );
  }

  if (error || !user) {
    return (
      <Segment>
        <Dimmer active>
          <Header icon inverted>
            <Icon name="frown" inverted />
            {error || `Unable to show user with id: ${userId}`}
          </Header>
        </Dimmer>
      </Segment>
    );
  }

  if (profileToCompare) {
    return (
      <CompareProfile
        userA={user}
        userB={profileToCompare}
        setProfileToCompare={setProfileToCompare}
        onLinkSuccess={setUser}
        makeToast={makeToast}
      />
    );
  }

  return (
    <>
      <div style={{ width: "100%", height: "100%", position: "absolute" }}>
        <ImageComponent
          src={user.profile.bannerImage}
          style={{ top: 0, left: 0, width: "100%", maxHeight: "50em" }}
        />
      </div>
      <div style={{ width: "100%", height: "100%", position: "absolute" }}>
        <div
          style={{
            width: "100%",
            height: "50em",
            background: `linear-gradient(#FFF0, ${bgColors.c})`,
          }}
        ></div>
      </div>
      <Segment style={topBarStyles}>
        <Button icon="arrow left" onClick={goBack} />

        <ImageComponent
          style={{ marginLeft: 10, maxHeight: 100 }}
          src={
            user.profile.avatarUrl
              ? user.profile.avatarUrl
              : imageGroupConfig.avatar.fallback
          }
        />
        <h1 style={textStyles}>{user.profile.username}</h1>
        {/* World is not ready for user ratings yet...*/}
        {/* <Rating icon='heart' defaultRating={0} maxRating={8} clearable size="massive" /> */}
      </Segment>
      <UserDetails user={user} />
      <ProfileDetails user={user} />
      <AccountTypeDetails
        user={user}
        makeToast={makeToast}
        segmentColor="violet"
      />
      <ProfileReportDetails user={user} makeToast={makeToast} />
      <AppVersionDetailsFromSessions
        sessions={sessions}
        loadMore={hasMoreSessions ? loadMoreSessions : undefined}
      />
      <AppVersionDetails user={user} />
      <DetailsSegment
        title="Notifications"
        accentColor="yellow"
        data={[
          {
            label: "Unread",
            value: `${unreadNotifications}${
              unreadNotifications >= 99 ? "+" : ""
            }`,
          },
        ]}
      />
      <IAPDetails user={user} segmentColor="cyan" />

      <DeviceIds deviceIds={user.deviceIds || []} userId={user._id} />

      {/* <FeedSubscriptions userId={user.profile._id} /> */}

      <ProfileActions
        stopLaunchRails={_stopLaunchRails}
        isDeleted={user.profile.isDeleted}
        isAutoSuspended={user.profile.isAutoSuspended}
        toggleProfileLinkModal={toggleProfileLinkModal}
        sendResetPasswordEmail={sendResetPasswordEmail}
        deleteUser={deleteUser}
        restoreUser={restoreUser}
        toggleChangeEmailModal={toggleChangeEmailModal}
        getPasswordResetLink={getPasswordResetLink}
        goToBlocks={goToBlocks}
        user={user}
        makeToast={makeToast}
        watchUser={watchUser}
        clearPostlistCache={clearPostlistCache}
      />
      {ticketSystemId && (
        <Button
          primary
          icon="ticket"
          content="FreshDesk"
          disabled={!ticketSystemId}
          onClick={() =>
            window.open(
              `https://tonitsupport.freshdesk.com/a/contacts/${ticketSystemId}`,
              "_blank"
            )
          }
        />
      )}
      {isProfileLinkOpen && (
        <SearchModal
          filterProfileId={user._id}
          setProfileToView={setProfileToCompare}
          toggleIsModalOpen={toggleProfileLinkModal}
          isOpen={isProfileLinkOpen}
          makeToast={makeToast}
        />
      )}

      <ChangeEmailModal
        isChangeEmailModalOpen={isChangeEmailModalOpen}
        changeUserEmail={changeUserEmail}
        toggleChangeEmailModal={toggleChangeEmailModal}
      />

      <ResetPasswordLinkModal
        isOpen={isResetPasswordLinkModalOpen}
        toggleModal={toggleResetPasswordLinkModalOpen}
        link={resetPasswordLink}
        makeToast={makeToast}
      />

      <RevealChats
        userId={user.profile._id}
        isCurrentAdmin={AdminProfile._id === user.profile._id}
      />
    </>
  );
};

const DeviceIds = ({
  deviceIds,
  userId,
}: {
  deviceIds: string[];
  userId: string;
}) => {
  return (
    <Segment style={{ borderLeft: `4px solid pink` }}>
      <Label size="large" attached="top left">
        {`Devices`}
      </Label>

      <Button
        basic
        size="tiny"
        negative
        onClick={() => {
          blockDevices(userId)
            .then(() => makeToast("blocked all known devices for this user"))
            .catch((e) => makeToast(e.message, { type: "error" }));
        }}
      >
        {"block all devices"}
      </Button>

      {deviceIds.map((id) => {
        return (
          <Row style={{ paddingBottom: "8pt" }}>
            <Column>
              <Label>{id}</Label>
            </Column>
            <Column></Column>
          </Row>
        );
      })}
    </Segment>
  );
};

const RevealChats = ({
  userId,
  isCurrentAdmin,
}: {
  userId: string;
  isCurrentAdmin?: boolean;
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [revealOpen, setRevealOpen] = useState(false);

  return (
    <Segment>
      <Modal
        trigger={<Button>{revealOpen ? "" : "reveal chats"}</Button>}
        open={confirmOpen}
        onOpen={() => setConfirmOpen(true)}
        onClose={() => setConfirmOpen(false)}
      >
        <Header icon="eye" content="Reveal Chats" />
        <Modal.Content>
          <p>
            {
              "Please respect this user's privacy. Keep chat messages confedential and secure."
            }
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color="grey" onClick={() => setConfirmOpen(false)}>
            <Icon name="cancel" /> {"Cancel"}
          </Button>
          <Button
            color="orange"
            inverted
            onClick={() => {
              setRevealOpen(true);
              setConfirmOpen(false);
            }}
          >
            {"Reveal Chats"}
          </Button>
        </Modal.Actions>
      </Modal>

      {revealOpen ? (
        <ChatRooms
          userId={userId}
          onDismiss={() => setRevealOpen(false)}
          messagable={isCurrentAdmin}
        />
      ) : null}
    </Segment>
  );
};
