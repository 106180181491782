export enum ClientRoutes {
  ConciergeChat = "/conciergeChat",
  Home = "/",
  Feed = "/feed",
  Moderation = "/moderation",
  Shares = "/shares",
  Reports = "/reports",
  Clubs = "/clubs",
  Search = "/search",
  Hashtag = "/hashtag",
  Blocks = "/blocks",
  /**
   * must append with id for individual item `/:id`
   */
  Users = "/users",
  /**
   * must append with id for individual item `/:id`
   */
  Posts = "/posts",
  PostSearch = "/postsearch",
  Metrics = "/metrics",
  Events = "/events",
  EventSearch = "/eventsearch",
  Concierge = "/concierge",
  StylePilot = "/style-pilot",
  UserReports = "/userreports",
  SuspendedAccounts = "/suspendedaccounts",
}


export const getRouteToDetail = (route: ClientRoutes, id: string, params: object = {}) => `${route}/${id}?${Object.entries(params).map(([a, b]) => `${a}=${b}`).join("&")}`;
