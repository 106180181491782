import React, { FC, useEffect, useState } from 'react';
import { getSuspendedUserCount } from '../api/api';

export const UserCountContext = React.createContext<{
    suspendedCount: number;
    incSuspendedCount: (inc: number) => void;
}>({
    suspendedCount: 0,
    incSuspendedCount: () => {},
});

export const UserCountProvider: FC = ({ children }) => {
    const [suspendedCount, setSuspendedCount] = useState(0);

    useEffect(() => {
        getSuspendedUserCount().then(r => setSuspendedCount(r.data.count));
      }, []);

    const incSuspendedCount = (inc: number) => {
        setSuspendedCount(s => Math.max(0, s + inc));
    };
    
    return <UserCountContext.Provider value={{ suspendedCount, incSuspendedCount }}>
        {children}
    </UserCountContext.Provider>
  };
