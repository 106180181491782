import { CSVLink } from "react-csv";
import { Button, Loader, Segment } from "semantic-ui-react";
import { getNewUsers, fromQueryString, getWatchedUsers, addWatchUser, removeWatchUser } from "../../../api/api";
import { useApiList, useMutableApiList } from "../../../hooks/useApiList";
import { randomColor } from "../../../utils";
import { Row, Column, PlainContent, Title } from "../../feeds/styles";
import { DetailsSegment } from "../../profilePage/DetailsSegment";
import { makeToast } from "../../Toaster";
import { UserCard } from "../../UserCard";
import { IUser } from "../../../constants";
import InfiniteScroll from "react-infinite-scroll-component";
import { withRouter } from "react-router-dom";

const csvHeaders = [
    { label: "account creation", key: "createdAt" },
    { label: "profile id", key: "profile._id" },
    { label: "email", key: "email" },
    { label: "username", key: "profile.username" },
    { label: "first post", key: "firstPost" },
    { label: "location", key: "profile.location.humanized" },

    { label: "hasJoinedClub", key: "hasJoinedClub" },
    { label: "hasBike", key: "hasBike" },
    { label: "hasReservation", key: "hasReservation" },
    { label: "hasPost", key: "hasPost" },
    { label: "hasProfilePic", key: "hasProfilePic" },
    { label: "hasBio", key: "hasBio" },
    { label: "followedAUser", key: "followedAUser" },
    { label: "followedByAUser", key: "followedByAUser" },
];

const WithContextMenu = ({ render, onPress, label }: { render: () => JSX.Element, onPress: () => void, label: string }) => {
    return <div style={{ position: "relative" }}>
        <div style={{
            position: "absolute",
            top: 16,
            left: 16,
            zIndex: 10,
        }}>
            <Button onClick={onPress}>
                {/* <DeContent> */}
                {label}
                {/* </DeContent> */}
            </Button>
        </div>
        {render()}
    </div>;
};

export const NewUsersList = withRouter(({ location }) => {
    const newUsers = useApiList<IUser>(getNewUsers, { pageSize: 32, after: fromQueryString(location?.search)?.after });

    const { push, pull, ...watched } = useMutableApiList<IUser>(getWatchedUsers, { pageSize: 32, after: fromQueryString(location?.search)?.after });


    const toastWrap = (message: string, fn: (...args: any) => void) => {
        return (...args: any) => {
            makeToast(message);
            fn(...args);
        }
    };

    const remove = (id: string) => {
        pull(id);
        removeWatchUser(id);
    };

    const add = (user: IUser) => {
        push(user);
        addWatchUser(user._id);
    };

    /**
     * To display certain "steps" that a new user has taken.
     *
     * Some of these fields come from the api since they need
     * to be looked up.
     *
     * Others can be derived from profile data.
     */
    const conciergeSteps = (user: IUser) => {
        const fromApi = Object.entries(user)
            .filter(d => ["hasJoinedClub", "hasBike", "hasReservation"].some(i => i === d[0]))
            .reduce((a, c) => ({ ...a, [c[0]]: !!c[1] }), {});

        const fromProfile = {
            hasPost: !!user.firstPost,
            hasProfilePic: !!user.profile.avatarUrl,
            hasBio: !!user.profile.bio,
            followedAUser: user.profile.followingCount > 0,
            followedByAUser: user.profile.followersCount > 0,
        };

        return {
            ...fromApi,
            ...fromProfile,
        }
    };

    return <>
        <CSVLink
            filename="watched-users.csv"
            headers={csvHeaders}
            data={watched.data.map(u => ({ ...u, ...conciergeSteps(u) }))} >
            <Button>
                {"export watched users"}
            </Button>
        </CSVLink>
        <UsersList
            {...watched}
            renderUser={(user: IUser) => {
                return <Row key={user._id}>
                    <Column>
                        <WithContextMenu
                            label={"unwatch"}
                            onPress={() => toastWrap("Removed this user from the watch list", remove)(user._id)}
                            render={() => <UserCard
                                key={user._id}
                                user={user}
                            />}
                        />
                    </Column>
                    <Column>
                        <DetailsSegment
                            key={user._id}
                            accentColor={randomColor()}
                            data={Object.entries(conciergeSteps(user))
                                .map(d => ({
                                    label: d[0],
                                    value: d[1],
                                }))}
                        />
                    </Column>
                </Row>;
            }}
            title="Watched Users"
        />
        <UsersList
            {...newUsers}
            renderUser={(user: IUser) => {
                return <Column key={user._id}>
                    <WithContextMenu
                        label={"watch"}
                        onPress={() => toastWrap("Started watching this user", add)(user)}
                        render={() => <UserCard
                            key={user._id}
                            user={user}
                        />}
                    />
                </Column>;
            }}
            title="New Users"
        />
    </>;
});

const UsersList = (props: ReturnType<typeof useApiList> & {
    title: string,
    renderUser: (user: IUser) => JSX.Element,
    // Override the data type since we know what it should be
    // Not sure how to use a type parameter above
    data: IUser[],
}) => {
    return <>
        <Row>
            <Segment style={{ marginTop: "16pt" }}>
                <Title>
                    {props.title}
                </Title>
            </Segment>
        </Row>
        <Row>
            <InfiniteScroll
                dataLength={props.data.length}
                next={props.next}
                hasMore={props.hasMore}
                endMessage={
                    props.isLoading
                        ? null
                        : <Row style={{ justifyContent: "center" }}>
                            <Segment>
                                <PlainContent>{props.data?.length ? "no more users" : "no users"}</PlainContent>
                            </Segment>
                        </Row>
                }
                loader={
                    <Loader active>{"Finding Users..."}</Loader>
                }>
                {props.data.map(props.renderUser)}
            </InfiniteScroll>
        </Row>
    </>
}