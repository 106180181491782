import React from "react";
import "semantic-ui-css/semantic.min.css";
import { ToastContainer, toast, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toasterStyles = {
	position: "fixed",
	width: "25%",
	minWidth: "250px",
	top: "12.5%",
	zIndex: 5,
}

export const Toaster = () => <ToastContainer style={toasterStyles} />;

/**
 * Global helper function for calling toast
 * @param text toast text
 * @param options default options override
 */
export const makeToast = (text: string, options?: ToastOptions) => {
	toast(text, { ...options, position: "top-left", autoClose: options?.autoClose || 10000 });
}
