import firebase from "firebase";
import React, { FC, useCallback, useEffect, useState } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import { ToastOptions } from 'react-toastify';
import { Button, Divider, Dropdown, Header, Loader, Modal, Segment } from 'semantic-ui-react';
import { ActionTypes, IAction, IPostable, TActionMap } from 'tonittypes';
import * as api from "../../api/api";
import { modalType, sortType } from '../../constants/constants';
import ReviewableTabContent from './ReviewableTabContent';

interface IProps {
  makeToast: (text: string, options?: ToastOptions) => void;
}

// sort by options
const sortByOptions = [
  {
    id: sortType.NEWEST,
    text: sortType.NEWEST,
    value: sortType.NEWEST,
  },
  {
    id: sortType.OLDEST,
    text: sortType.OLDEST,
    value: sortType.OLDEST,
  },
  {
    id: sortType.REPORTS,
    text: sortType.REPORTS,
    value: sortType.REPORTS,
  },
];

// map the sort key to the reviewable field
const sortKeysToField = {
  [sortType.NEWEST]: { sortKey: "inReview.timestamp", order: "descending" },
  [sortType.OLDEST]: { sortKey: "inReview.timestamp", order: "ascending" },
  [sortType.REPORTS]: { sortKey: "reportCount", order: "descending" },
};

// return the query based on the sort key
const getQuerySort = (sortKey: sortType) => {
  if (!sortKey) {
    return {};
  }

  return sortKeysToField[sortKey] || {};
};

export const ReviewablesFeed: FC<IProps> = ({ makeToast }) => {
  const [reviewables, setReviewables] = useState<IPostable<any>[]>([]);
  const [openModal, setOpenModal] = useState<modalType | string | undefined>();
  const [actionablePost, setActionablePost] = useState<IPostable<any> | null>(null);
  const [sortBy, setSortBy] = useState<sortType>(sortType.NEWEST);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  // paging is 0 indexed
  const [page, setPage] = useState(0);

  // fetch in the batch of posts
  const fetchPosts = useCallback(async (offsetPage = 0) => {
    try {
      setIsLoading(true);

      // fetch in the posts
      const query = { ...getQuerySort(sortBy), limit: 5, page: offsetPage };
      const postsInReview = await api.requestPostsInReview(query);
      const { posts } = postsInReview.data;
      if (posts && posts.length) {
        setIsLoading(false);
        setHasMore(true);
        setPage((page) => page + 1);
        return posts;
      } else {
        setHasMore(false);
      }
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  }, [sortBy]);

  // fetch in all the posts in review on initial render
  useEffect(() => {
    const fetchInPosts = async () => {
      // reset the page number
      setPage(0);
      const posts = await fetchPosts(0);
      setReviewables(posts || []);
    };

    fetchInPosts();
  }, [sortBy, fetchPosts]);

  // pull in the next page of items
  const loadMoreItems = async () => {
    if (!isLoading) {
      const posts = await fetchPosts(page);
      if (posts) {
        // append the list of posts if theres any
        setReviewables([...reviewables, ...posts]);
      }
    }
  };

  const toggleModal = (type: modalType, post: IPostable<any>) => {
    setOpenModal(type);
    setActionablePost(post);
  }

  // close the modal
  const closeModal = () => setOpenModal("");

  // helper to update a post in the list immutably
  const updatePost = (post: IPostable<any>, update: any) => {
    // update the item in the array immutably
    const updatedReviewables = reviewables.map((item) => {
      if (item.id !== post.id) {
        return item;
      }

      return { ...post, ...update };
    });

    setReviewables(updatedReviewables);
  };

  // approve the post
  const approve = async (post: IPostable<any> | null) => {
    if (!post) {
      return;
    }

    // push up the action
    // @ts-ignore
    await postAction(post, ActionTypes.RESTORE_POST);

    // update the local post
    updatePost(post, { inReview: { ...post.inReview, status: false } });

    makeToast("Post has been restored", { type: "success" });

    closeModal();
  };

  // approve the post
  const remove = async (post: IPostable<any> | null) => {
    if (!post) {
      return;
    }

    // push up the action
    // @ts-ignore
    await postAction(post, ActionTypes.DELETE_REVIEWED_POST);

    // update the local post
    updatePost(post, { isDeleted: true, inReview: { ...post.inReview, status: false } });

    makeToast("Post has been deleted", { type: "success" });

    closeModal();
  };

  // send down an action to the server
  const postAction = async (post: IPostable<any>, actionType: ActionTypes) => {
    if (!post) {
      return;
    }

    const currentUser = firebase.auth().currentUser as firebase.User;

    // @ts-ignore
    const action: TActionMap = {
      createdAt: Date.now(),
      userId: currentUser.uid,
      type: actionType,
      data: { id: post.id },
    };

    try {
      await api.postAction(action);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <Segment>
        <Header>
          <Header>Content Moderation Page</Header>
          <Divider horizontal />
          <p>This feed contains posts that have been rejected by the content automoderation system or removed by an admin</p>
          <p>You can either restore a post, or permanently remove it.</p>
        </Header>
      </Segment>
      <Dropdown
        compact
        selection
        options={sortByOptions}
        defaultValue={sortBy}
        onChange={(event) => setSortBy(event.currentTarget.id as sortType)}
      />

      <InfiniteScroll
        style={{
          margin: "32px",
          maxWidth: "1024px",
        }}
        dataLength={reviewables.length} //This is important field to render the next data
        next={loadMoreItems}
        hasMore={hasMore}
        loader={
          isLoading && <Loader active>{"Finding Posts..."}</Loader>
        }>
        <ReviewableTabContent
          reviewablePosts={reviewables}
          toggleModal={toggleModal}
          makeToast={makeToast}
        />
      </InfiniteScroll>

      {/* APPROVE POST MODAL */}
      <Modal open={openModal === modalType.APPROVE}>
        <Modal.Header content={"Are you sure you want to restore this content?"} />
        <Modal.Content>
          <p>This will make the content viewable on the app again.</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            compact
            content="Restore"
            onClick={() => approve(actionablePost)}
          />
          <Button
            basic
            compact
            content="Cancel"
            onClick={closeModal}
          />
        </Modal.Actions>
      </Modal>

      {/* DECLINE POST MODAL */}
      <Modal open={openModal === modalType.DECLINE}>
        <Modal.Header content={"Are you sure you want to delete this content?"} />
        <Modal.Content>
          <p>This will remove the content permenantly.</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            compact
            content="Delete"
            onClick={() => remove(actionablePost)}
          />
          <Button
            basic
            compact
            content="Cancel"
            onClick={closeModal}
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
};
