import React from "react";
import { Icon, Label } from "semantic-ui-react";

export const NoMatch = () => {
  return <div>
    <Label>
      <Icon size="massive" name="announcement" />
      {"How did you even get here?"}
    </Label>
  </div>;
};
