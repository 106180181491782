import * as config from "../config/config.json";
import {indexSearch} from "../api/api";

export const createSearchApi = (indexName: string) => (searchApiMode: string) => async (searchString: string, isDeleted: boolean) => {
  if (!searchString || !searchApiMode) return [];
  try {
    const searchResults = await indexSearch(indexName, searchString, searchApiMode);
    return searchResults;
  } catch (e) {
    console.log(`Something went wrong, ${e}`);
  }
}

export const clubSearch = createSearchApi(config.algolia.clubIndex);
export const hashtagSearch = createSearchApi(config.algolia.hashtagIndex);
