import React from "react";
import staticClubPicture from "../../assets/generic-female-profile-picture.jpg"
import { Segment, Card, Image, Label } from "semantic-ui-react";
import { IClub } from "tonittypes";
import { getRouteToDetail, ClientRoutes } from "../../constants/routes";
import { Link } from "react-router-dom";

interface IClubCardProps {
  hit: IClub;
}

export const ClubCard = (props: IClubCardProps) => {
  if (!props?.hit) {
    return <Segment>
      <Label>{`No Results`}</Label>
    </Segment>
  }

  const club = props.hit;
  return <Card key={club._id} style={{ backgroundColor: club.archived ? "#d5d5d5" : "white", margin: 10 }}>
    <Image fluid style={{ width: 290, height: 290 }} src={club.clubBanner || staticClubPicture} />
    <Card.Content>
      <Segment>
        <Link to={getRouteToDetail(ClientRoutes.Clubs, club._id)}>
          <Card.Header as="h2" style={{ color: club.archived ? "#314E79" : "#4182C5" }}>{club.name}</Card.Header>
        </Link>
      </Segment>
      <Segment>
        <Card.Description>{`Owner: ${club.owner?.userName}`}</Card.Description>
        <Card.Description>{`Club ID: ${club._id}`}</Card.Description>
        <Card.Description>{`Members: ${club.memberCount}`}</Card.Description>
        <Card.Description>{`Private: ${club.isPrivate}`}</Card.Description>
        <Card.Description>{`Archived: ${club.archived}`}</Card.Description>
      </Segment>
      <Segment>
        <Link to={getRouteToDetail(ClientRoutes.Feed, club._id, {select: "club", input: club._id})}>
          <Card.Header>{`Club Feed`}</Card.Header>
        </Link>
      </Segment>
    </Card.Content>
  </Card >
}