import React, { useEffect, useState } from 'react';
import { Loader, Icon, Popup, Input, Label, Button } from 'semantic-ui-react';
import styled from 'styled-components';
import { getUserCount } from '../api/api';
import { useInterval } from '../hooks/useInterval';
import { Row } from './feeds/styles';
import { Hidden } from './shared';

export const UserCounter = () => {
  const [userCount, setUserCount] = useState<{ all: number; active: number }>({ all: 0, active: 0 });
  const [before, setBefore] = useState<string|null>();
  const [after, setAfter] = useState<string|null>();

  const getCount = (opts?: object) =>
    getUserCount(opts).then(res => setUserCount(res.data));

  useInterval(getCount, 100000, false);

  useEffect(() => {
    console.log(after, before);
    if (after && before) {
      getCount({after: new Date(after).getTime(), before: new Date(before).getTime()});
    } else if (!after && !before) {
      getCount();
    }
  }, [after, before]);

  return (
    <CountWrap>
      <Hidden 
      renderOpen={(_, toggle) => <>
      <Button
       icon="close"
      circular
      attached="left"
      size="tiny"
      onClick={() => {
        toggle();
        setAfter(null);
        setBefore(null);
      }}
      />
      <Input label="from" type="date" onChange={e => setAfter(e.target.value)} />
      <Input label="to" type="date" onChange={e => setBefore(e.target.value)} />
      </>}
      renderClose={(Button) => <Button>{"filter"}</Button>}
      label={"filter"}
      />
      
      <Icon name='user circle' size='large' />
      <Popup content={"Total accounts are user records that we store in the database which have not been deleted. Active users are those users who log in or open Tonit while logged in, during the last 3 months."} trigger={<Icon name="info" size="small" color="grey" />} />
      {userCount?.all !== undefined
        ? <>
          <Row>
            {`${userCount.all} total accounts created${before && after ? " in time span" : ""}`}
          </Row>
          <Row style={{ top: 2, position: "relative" }}>
            {`${userCount.active} active in 3 months`}
          </Row>
        </>
        : <Loader />}
    </CountWrap>
  );
};

const CountWrap = styled.div`
      position: relative;
      padding: 5px 10px;
      border-radius: 5px;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 50;
      `;
