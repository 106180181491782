import { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ToastOptions } from "react-toastify";
import { Icon, Label, Popup, Image, Grid, Button, Segment } from "semantic-ui-react";
import { DomainObjects } from "tonittypes";
import { fetchUserReports } from "../../api/api";
import { ClientRoutes, getRouteToDetail } from "../../constants/routes";
import { useApiList } from "../../hooks/useApiList";
import {
  PlainContent,
  Time,
  Username,
} from "../feeds/styles";
import { UserBadge } from "../shared";
import staticProfilePic from "../../assets/generic-female-profile-picture.jpg";

interface IProps {
  makeToast: (text: string, options?: ToastOptions) => void;
}

const rowStyle = {
  marginBottom: "32px",
  width: "100%",
  display: "flex",
  flexDirection: "row" as "row",
  // flexWrap: "nowrap" as "nowrap",
  justifyContent: "flex-start",
  alignItems: "center",
};

interface IReportDoc {
    _id: string;
    userId: any;
    reportedId: string;
    userMessage: string;
    reason: string;
    createdAt: number;
    entityType: DomainObjects;
    reportedUserId: string;
}

export const UserReportsHome: FC<IProps> = ({ makeToast }) => {
  const { push } = useHistory();
  const [reportedId, setReportedId] = useState("");
  const [reportedUserId, setReportedUserId] = useState("");
  const [sourceId, setSourceId] = useState("");

  const qs = `?reportedId=${reportedId}&sourceId=${sourceId}&reportedUserId=${reportedUserId}`;

  const apiFn = useCallback((cursors: any) => fetchUserReports(qs, cursors), [qs]);

  const { data, isLoading, refresh, next, hasMore } = useApiList<IReportDoc>(apiFn, {
    pageSize: 30,
    selfManaged: true,
  });

  useEffect(() => {
    refresh();
  }, [qs, refresh]);

  useEffect(() => {
    console.log("new data:", data);
  }, [data]);

  const handleEntityClick = async (entityType: DomainObjects, id: string) => {
    switch (entityType) {
      case DomainObjects.Profile: {
        push(getRouteToDetail(ClientRoutes.Users, id));
        break;
      }
      case DomainObjects.Post: {
        push(getRouteToDetail(ClientRoutes.PostSearch, id));
        break;
      }
    }
  };

  const handleViewProfileClick = async (id: string) => {
    push(getRouteToDetail(ClientRoutes.Users, id));
  };

  return (
    <div>
      <div style={rowStyle}>
      <Label
              horizontal
              size="large"
              pointing="right"
              style={{ zIndex: 1 }}
              content="Filters"
            />
        <Popup
          inverted
          content="Filter by the user that made the report"
          trigger={
            <Label
              horizontal
              size="large"
              pointing="right"
              style={{ zIndex: 1 }}
              content="Reporter profile id"
            />
          }
        />
        <input
          onChange={(e) => setSourceId(e.target.value)}
          placeholder="profile id..."
          value={sourceId}
          disabled={isLoading}
          style={{
            width: "25%",
            padding: "8px 32px",
            borderRadius: "16px",
            borderWidth: "1",
            borderColor: "#ccd",
            color: "#999",
            marginRight: "16px",
          }}
        />
        <Popup
          inverted
          content="Filter for the reported object (use profile/post/comment/etc id)"
          trigger={
            <Label
              horizontal
              size="large"
              pointing="right"
              style={{ zIndex: 1 }}
              content="Reported id"
            />
          }
        />
        <input
          onChange={(e) => setReportedId(e.target.value)}
          placeholder="object id..."
          value={reportedId}
          disabled={isLoading}
          style={{
            width: "25%",
            padding: "8px 32px",
            borderRadius: "16px",
            borderWidth: "1",
            borderColor: "#ccd",
            color: "#999",
            marginRight: "16px",
          }}
        />
        <Popup
          inverted
          content="Filter for the owner of the reported object (post/comment/etc owner)"
          trigger={
            <Label
              horizontal
              size="large"
              pointing="right"
              style={{ zIndex: 1 }}
              content="Reported user id"
            />
          }
        />
        <input
          onChange={(e) => setReportedUserId(e.target.value)}
          placeholder="profile id..."
          value={reportedUserId}
          disabled={isLoading}
          style={{
            width: "25%",
            padding: "8px 32px",
            borderRadius: "16px",
            borderWidth: "1",
            borderColor: "#ccd",
            color: "#999",
            marginRight: "16px",
          }}
        />
        {isLoading ? <Icon loading name="spinner" size="large" /> : null}
      </div>
      <Grid>
        {Object.values(data.reduce((acc, cur) => ({...acc, [cur._id]: cur}), {} as {[k:string]:IReportDoc})).map((item: IReportDoc) => (
          <>
              <Segment style={{ margin: "16pt" }}>
            <Grid.Row columns={5}>
              <Grid.Column width={1}>
                <Image
                  style={{
                    borderRadius: "21pt",
                    width: "42pt",
                    height: "42pt",
                    minHeight: "42pt",
                    minWidth: "42pt",
                  }}
                  src={item.userId?.avatarUrl || staticProfilePic}
                />
              </Grid.Column>
              <Grid.Column width={5}>
                <Username
                  maxWidth={256}
                  onClick={() => handleViewProfileClick(item.userId?._id)}
                >
                  <UserBadge accountTypes={item.userId?.accountTypes} />
                  {item.userId?.username}
                </Username>
              </Grid.Column>
              <Grid.Column width={3}>
                <PlainContent>{`reported the ${item.entityType}`}</PlainContent>
              </Grid.Column>
              <Grid.Column width={4}>
                <Username
                  maxWidth={256}
                  onClick={() =>
                    handleEntityClick(item.entityType, item.reportedId)
                  }
                >
                  {item.reportedId}
                </Username>
              </Grid.Column>

              {
                item.entityType !== DomainObjects.Profile
                ? <Grid.Column width={3}>
                <PlainContent>{`owned by`}</PlainContent>
                <Username
                  maxWidth={256}
                  onClick={() => handleViewProfileClick(item.reportedUserId)}
                >
                  {item.reportedUserId}
                </Username>
              </Grid.Column>
                : null
              }

              <Grid.Column width={2}>
                <Time date={item.createdAt || "unkown"} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column width={3}>
                <Label pointing="right">
                  {"Reason"}
                </Label>
                <PlainContent>
                  {`${item.reason || "none"}`}
                </PlainContent>
              </Grid.Column>
              <Grid.Column width={8}>
                <Label pointing="right">
                  {"Message"}
                </Label>
                <PlainContent>
                  {`${item.userMessage || "none"}`}
                </PlainContent>
              </Grid.Column>
            </Grid.Row>
            </Segment>
          </>
        ))}
      </Grid>
      <Button onClick={next} disabled={!hasMore}>
          {"Show More"}
      </Button>
    </div>
  );
};
