import React, { Component } from 'react';
import "semantic-ui-css/semantic.min.css";
import { IUser } from '../../constants';
import { DetailsSegment } from './DetailsSegment';

interface IProps {
  user: IUser | null;
};

export class UserDetails extends Component<IProps, any> {

  public render() {
    const { user } = this.props;

    if (!user) {
      return null;
    }

    return <DetailsSegment
      title={"User"}
      accentColor="#f54"
      data={[
        { label: "email", value: user.email },
        { label: "idp", value: user.idp },
        { label: "id", value: user._id },
      ]}
    />
  }
}
